import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { DEFAULT_LANGUAGE } from 'src/shared/constants/constants';
import { getFromLocalStorage } from 'src/shared/libs/helpers/helper.lib';
import { Language, Nullable } from 'src/shared/types/global-types';
import { enTranslations, ruTranslations } from './translations';

const SELECT_LANGUAGE: Nullable<Language> = getFromLocalStorage('lang', {} as Language);

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: enTranslations },
      ru: { translation: ruTranslations },
    },
    fallbackLng: SELECT_LANGUAGE?.type || DEFAULT_LANGUAGE.type,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
