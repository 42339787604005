import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLayoutEffect } from 'react';
import { AuthLayout } from 'src/layouts';
import { useAppDispatch } from 'src/app/store/store';
import { useChangePageTitle } from 'src/shared/libs/hooks/use-change-page-title';
import AbstractIconsSvg from 'src/shared/assets/images/auth/abstract.svg';
import LanguageSelection from 'src/entities/language-selection/language-selection';
import { setSlidesPage } from './model/slice';
import { selectSlidesPage } from './model/selectors';
import AuthForm from './components/auth-form/auth-form';
import AuthBody from '../components/auth-body/auth-body';
import AuthTitle from '../components/auth-title/auth-title';
import AuthFooter from '../components/auth-footer/auth-footer';
import AuthHelper from '../components/auth-helper/auth-helper';
import { informSlides, infromTitleSilde } from '../lib/helpers/constants';
import styles from './login.module.scss';

const pageTitle = 'system_login';

const LoginPage = () => {
  useChangePageTitle(pageTitle);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const slidePage = useSelector(selectSlidesPage);

  useLayoutEffect(() => {
    dispatch(setSlidesPage(0));
  }, []);

  return (
    <AuthLayout page="">
      <div className={styles.loginContainer}>
        <section className={styles.bodyWrapper}>

          <AuthBody
            activeSlide="first"
            titleUp="welcome"
            titleDown="personal_account"
            description="trade_on_platforms_and_get_cashback"
            informList={informSlides[slidePage]}
            informTitle={infromTitleSilde[slidePage]}
          />
        </section>

        <section className={styles.formWrapper}>
          <div className={styles.formContainer}>
            <LanguageSelection bgColor="#131313" />
            <AuthTitle visible={false} step="none">
              {t('signin')}
              <br />
              {t('personal_account_login')}
            </AuthTitle>
            <AuthForm />
            <AuthHelper title={t('no_account')} path="/auth/registration" linkTitle={t('signup')} />
          </div>

          <AuthFooter />
        </section>

        <img className={styles.abstract} src={AbstractIconsSvg} alt="abstract" />
      </div>
    </AuthLayout>
  );
};

export default LoginPage;
