import informSlide1 from 'src/pages/auth/login/constants';
import informSlide2 from 'src/pages/auth/registration/constants';
import informSlide3 from 'src/pages/auth/verification/constants';
import informSlide4 from 'src/pages/auth/personal-data/constants';

export const informSlides = [informSlide1, informSlide2, informSlide3, informSlide4];
export const infromTitleSilde = [
  'how_to_get_increased_cashback',
  'create_secure_password_tips',
  'protect_yourself_tips',
  'personal_data_reason',
];
