import classNames from 'classnames';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import usePasswordExecutionSteps from 'src/pages/auth/lib/hooks/use-password-execution-steps';
import styles from './inform-box.module.scss';

interface IInformBoxProps {
    title: string;
    items: string[];
    dynamicItems?: boolean
}
type ResultType = {
  [key: string]: boolean;
}

const InformBox = memo(({ items, title, dynamicItems }: IInformBoxProps) => {
  const { t } = useTranslation();
  const { result } = usePasswordExecutionSteps();
  const results: ResultType = result;

  const inform = items.map((text, index) => ({
    title: text,
    active: results[Object.keys(results)[index]],
  }));

  return (
    <div className={styles.informBox}>
      <h2>{title}</h2>

      <ul>
        {dynamicItems
          ? inform.map((item, index) => (
            <li className={classNames({ [styles.active]: item.active })} key={index}>{t(item.title)}</li>
          ))
          : items.map((item, index) => (
            <li key={index}>{t(item)}</li>
          ))}
      </ul>
    </div>
  );
});

export default InformBox;
