import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { join } from 'src/shared/libs/helpers/helper.lib';
import { EStatus, Nullable } from 'src/shared/types/global-types';
import Button from 'src/shared/ui/button/button/button';
import { LEVERAGE } from '../../constants';
import { selectLeverages } from '../../model/selectors';
import './change-leverage-all-instruments.scss';

interface IChangeLeverageAllInstrumentsProps {
  opened: boolean;
  selectedInstrumentIds: number[];
  excludedInstrumentIds: number[];
  selectAllInstruments: boolean;
  totalInstruments: Nullable<number>;
  selectedLeverageValue: number;
  setSelectedLeverageValue: (value: number) => void;
  setLeverage: () => void;
}

const ChangeLeverageAllInstruments: React.FC<IChangeLeverageAllInstrumentsProps> = ({
  opened,
  selectedInstrumentIds,
  excludedInstrumentIds,
  selectAllInstruments,
  totalInstruments,
  selectedLeverageValue,
  setSelectedLeverageValue,
  setLeverage,
}) => {
  const { t } = useTranslation();
  const { statusSetLeverage } = useSelector(selectLeverages);

  return opened ? (
    <div className="leverage-settings">
      <h4 className="title">{t('credit_leverage')}</h4>

      {!selectAllInstruments && (
        <p>{t('for_all_selected')} <br /> {t('_instruments')}: ({selectedInstrumentIds.length})</p>
      )}
      {selectAllInstruments && totalInstruments && (
        <p>{t('for_all_selected')} <br /> {t('_instruments')}: ({totalInstruments - excludedInstrumentIds.length})</p>
      )}
      <ul className="leverage-item-wrapper">
        {LEVERAGE.map((leverage) => (
          <li
            key={leverage}
            className={join('leverage-item', selectedLeverageValue === leverage && 'selected-item')}
            role="button"
            tabIndex={0}
            onClick={() => setSelectedLeverageValue(leverage)}
            onKeyDown={() => {}}
          >
            {leverage}
          </li>
        ))}
      </ul>

      <Button
        loading={statusSetLeverage === EStatus.loading}
        disabled={statusSetLeverage === EStatus.loading}
        onClick={setLeverage}
        background="green"
      >
        {t('apply_to_all_instruments')}
      </Button>
    </div>
  ) : null;
};

export default ChangeLeverageAllInstruments;
