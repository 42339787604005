import qs from 'qs';
import {
  forwardRef,
  memo,
  useEffect, useMemo, useState,
} from 'react';
import { useSelector, batch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { Asset } from 'src/app/store/slices/assets/types';
import { removeAssets } from 'src/app/store/slices/assets/slice';
import { store, useAppDispatch } from 'src/app/store/store';
import { fetchSubAccountsAssetsTransfers, unsubscribeSubAccountAsset } from 'src/app/store/slices/sub-accounts/thunks';
import { removeSubAccountsAssets, removeSubAccountsAssetsTransfers } from 'src/app/store/slices/sub-accounts/slice';
import { selectSubAccounts, selectFavoriteSubAccount, selectSubAccountsAssetsTransfers } from 'src/app/store/slices/sub-accounts/selectors';
import { SubAccountAsset } from 'src/app/store/slices/sub-accounts/types';

import { ReactComponent as ShieldIconSvg } from 'src/shared/assets/images/account/shield.svg';
import { ReactComponent as CoupIconSvg } from 'src/shared/assets/images/account/coup.svg';

import Select from 'src/shared/ui/select/select';
import Button from 'src/shared/ui/button/button/button';
import Divisor from 'src/shared/ui/divisor/divisor/divisor';
import useAlert from 'src/shared/libs/hooks/use-alert';
import InputForm from 'src/shared/ui/input/input-form/input-form';
import InputSelect from 'src/shared/ui/input/input-select/input-select';
import SelectSkeleton from 'src/shared/ui/skeleton/select-skeleton/select-skeleton';
import DefaultIconSvg from 'src/shared/assets/images/exchange/binance.svg';

import { ETradingType, EStatus, Nullable } from 'src/shared/types/global-types';
import {
  minusValues, toFixedDecimalPrecision,
  typeSubAccountBackNullable,
} from 'src/shared/libs/helpers/helper.lib';
import { PositionExchange } from 'src/pages/trading/model/types';
import { fetchAssetsFutures } from 'src/app/store/slices/assets/thunks';
import { selectAssetsFutures } from 'src/app/store/slices/assets/selectors';
import { HIDDEN_BALANCES, USDT_PRECISION } from 'src/shared/constants/constants';
import { BalanceType } from 'src/entities/balance-type';
import { selectHiddenBalance } from 'src/pages/settings/model/selectors';
import useExchange from 'src/shared/libs/hooks/use-exchange';
import useMobile from 'src/shared/libs/hooks/useMobile';
import { useTranslation } from 'react-i18next';
import {
  removeTransfersAsset,
  setAssetPrecision, setCurrentAsset, setCurrentBalanceFrom, setCurrentBalanceTo, setSubAccountBalances,
  setTransfersAsset,
} from './model/slice';
import { fetchTransfers } from './model/thunks';
import { pricePrecisionBack } from './libs/helpers';
import { selectCurrentTransferAsset, selectTransfers, selectTransfersAsset } from './model/selectors';
import { ERROR_MSG_LIST, EXCHANGE_TRANSFERS_LIMIT } from './constants';
import { AccountBalance, Transfers } from './model/types';
import styles from './transfers.module.scss';

const TransfersBlock = memo(forwardRef<HTMLDivElement>((props, ref) => {
  const dispatch = useAppDispatch();
  const isMobile = useMobile();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { setAlertMessage } = useAlert();

  const { getExchangeNameById, availableExchanges: exchanges } = useExchange();

  const subAccounts = useSelector(selectSubAccounts);
  const currentAsset = useSelector(selectCurrentTransferAsset);
  const assetsFutures = useSelector(selectAssetsFutures);
  const transferAsset = useSelector(selectTransfersAsset);
  const balanceVisible = useSelector(selectHiddenBalance);
  const subAccountsAssetsTransfers = useSelector(selectSubAccountsAssetsTransfers);
  const favoriteSubAccount = useSelector(selectFavoriteSubAccount);

  const {
    status: statusTransfers, currentBalanceFrom, currentBalanceTo, accountBalances, assetPrecision,
  } = useSelector(selectTransfers);

  const [quantity, setQuantity] = useState('');
  const [divisorSum, setDivisorSum] = useState('');

  const [subAccountSum, setSubAccoundSum] = useState('');
  const [currentExchangeId, setCurrentExchangeId] = useState(1);
  const [currentExchange, setCurrentExchange] = useState<Nullable<PositionExchange>>(null);

  const getAccountsBalance = useMemo(() => (assetId: number, symbol: string, exchangeId: number) => {
    const result: AccountBalance[] = [];
    let subAccountTypes = [1, 2]; // TradingType

    const subAccountsForExchange = subAccounts?.filter((subAccount) => subAccount.exchange_id === exchangeId);
    const listOfAssetIdsVeaSymbol = subAccountsAssetsTransfers?.filter((item) => item.asset.symbol === symbol).map((asset) => asset.asset.id);

    if (exchangeId === 2) subAccountTypes = [1]; // Only spot account if ByBit exchange;

    subAccountsForExchange?.forEach((subAccount) => {
      const exchange = exchanges?.find((exchange) => exchange.id === exchangeId);
      const filteredAssets = subAccountsAssetsTransfers?.filter((asset) => listOfAssetIdsVeaSymbol?.includes(asset.asset_id) && asset.sub_account_id === subAccount.id);

      subAccountTypes.forEach((assetType) => {
        const assetsOfType = filteredAssets?.filter((asset) => asset.asset_type === assetType);

        if (assetsOfType) {
          // available balance
          let quantity = '0';
          let pricePrecision = USDT_PRECISION;

          if (assetsOfType?.length > 0) {
            const asset = assetsOfType[0];

            if (asset.asset.symbol === 'USDT') {
              quantity = minusValues(asset.quantity, asset.locked);
              pricePrecision = USDT_PRECISION;
            } else {
              quantity = minusValues(asset.quantity, asset.locked);
              pricePrecision = pricePrecisionBack(subAccount.exchange_id);
            }
          }

          result.push({
            subAccountId: subAccount.id,
            subAccountName: subAccount.user_name,
            exchangeId: subAccount.exchange_id,
            exchangeImage: exchange?.image || '',
            quantity: quantity || '0',
            subAccountAssetId: assetId,
            assetType,
            precision: pricePrecision,
          });
        }
      });
    });

    result.sort((a, b) => {
      const aIsFavorite = a.subAccountName === favoriteSubAccount;
      const bIsFavorite = b.subAccountName === favoriteSubAccount;

      if (aIsFavorite && !bIsFavorite) {
        return -1;
      }
      if (!aIsFavorite && bIsFavorite) {
        return 1;
      }

      if (aIsFavorite && bIsFavorite) {
        return a.assetType - b.assetType;
      }

      return a.subAccountName.localeCompare(b.subAccountName);
    });

    return result;
  }, [subAccounts, exchanges, subAccountsAssetsTransfers, favoriteSubAccount]);

  const filteredAssetsByAccountAndType = useMemo(() => {
    if (!currentBalanceFrom || !currentBalanceTo || !subAccountsAssetsTransfers) return [];

    let filteredAssets;

    if (currentBalanceTo.assetType === ETradingType.futures) {
      filteredAssets = subAccountsAssetsTransfers.filter((asset) => asset.sub_account_id === currentBalanceFrom.subAccountId && asset.asset_type === currentBalanceFrom.assetType && assetsFutures?.items.some((futureAsset) => futureAsset.symbol === asset.asset.symbol));
    } else {
      filteredAssets = subAccountsAssetsTransfers.filter((asset) => asset.sub_account_id === currentBalanceFrom.subAccountId && asset.asset.asset_type === currentBalanceFrom.assetType);
    }

    return filteredAssets;
  }, [subAccountsAssetsTransfers, currentBalanceFrom, currentBalanceTo, assetsFutures]);

  const getTransferAssets = (currentBalanceFrom: Nullable<AccountBalance>, currentBalanceTo: Nullable<AccountBalance>) => {
    let filteredAssets: SubAccountAsset[] = [];

    if (currentBalanceFrom && subAccountsAssetsTransfers) {
      const exchangeName = getExchangeNameById(currentBalanceFrom.exchangeId);

      if (currentBalanceFrom.assetType === ETradingType.spot && currentBalanceTo?.assetType === ETradingType.futures) {
        filteredAssets = subAccountsAssetsTransfers.filter((asset) => asset.sub_account_id === currentBalanceFrom.subAccountId && asset.asset_type === currentBalanceFrom.assetType && assetsFutures?.items.some((futureAsset) => futureAsset.symbol === asset.asset.symbol));
      } else if (currentBalanceFrom.assetType === ETradingType.futures && currentBalanceTo?.assetType === ETradingType.spot) {
        filteredAssets = subAccountsAssetsTransfers.filter((asset) => asset.sub_account_id === currentBalanceFrom.subAccountId && asset.asset_type === ETradingType.futures);
      } else if (exchangeName === 'Bybit') {
        filteredAssets = subAccountsAssetsTransfers.filter((asset) => asset.asset.exchange_id === currentBalanceFrom.exchangeId);
      } else {
        filteredAssets = subAccountsAssetsTransfers.filter((asset) => asset.sub_account_id === currentBalanceFrom.subAccountId);
      }
    }
    return filteredAssets;
  };

  const turnOverBalances = () => {
    if (currentBalanceTo && currentBalanceFrom) {
      batch(() => {
        dispatch(setCurrentBalanceFrom(currentBalanceTo));
        dispatch(setCurrentBalanceTo(currentBalanceFrom));
        dispatch(setTransfersAsset(null));
      });

      const balanceFrom = store.getState().transfers.currentBalanceFrom;
      const toBalance = store.getState().transfers.currentBalanceTo;

      const filteredAssets = getTransferAssets(balanceFrom, toBalance);

      const fromAsset = filteredAssets.find((asset) => asset.sub_account_id === balanceFrom?.subAccountId);

      if (fromAsset) handleSetCurrentAsset(fromAsset.asset);
    }
  };

  const handleSetCurrentExchange = (exchange: PositionExchange) => {
    if (exchange.id === currentExchange?.id) return;
    const currentPage = location;

    if (location.pathname) {
      navigate(currentPage.pathname);
    }

    if (isMobile) {
      navigate(currentPage.pathname);
      dispatch(setTransfersAsset(null));
    }

    batch(() => {
      dispatch(setCurrentBalanceFrom(null));
      dispatch(setCurrentBalanceTo(null));
      dispatch(setCurrentAsset(null));
    });

    setQuantity('');
    setCurrentExchangeId(exchange.id);
    setCurrentExchange(exchange);
  };

  const handleSetCurrentBalanceFrom = (accountBalance: AccountBalance) => {
    dispatch(setCurrentBalanceFrom(accountBalance));
    setSubAccoundSum(accountBalance.quantity.toString());

    const fromBalance = accountBalance;
    const toBalance = accountBalances.find((balance) => balance.subAccountName === accountBalance.subAccountName && balance.assetType !== accountBalance.assetType);

    if (fromBalance && toBalance) {
      const [firstAsset] = getTransferAssets(fromBalance, toBalance);

      if (firstAsset) handleSetCurrentAsset(firstAsset.asset);
    }

    const balanceTo = accountBalances.find((balance) => balance.subAccountName === accountBalance.subAccountName && balance.assetType !== accountBalance.assetType);

    if (balanceTo)dispatch(setCurrentBalanceTo(balanceTo));
  };

  const handleSetCurrentBalanceTo = (accountBalance: AccountBalance) => {
    dispatch(setCurrentBalanceTo(accountBalance));

    const balanceFrom = accountBalances.find((balance) => balance.subAccountName === accountBalance.subAccountName && balance.assetType !== accountBalance.assetType);
    const checkSpotBalanceFrom = currentBalanceFrom?.assetType === ETradingType.spot;
    const checkFromBalance = accountBalance.subAccountId === currentBalanceFrom?.subAccountId && accountBalance.assetType === currentBalanceFrom?.assetType;

    const fromBalance = balanceFrom;
    const toBalance = accountBalance;

    if (checkFromBalance && balanceFrom) {
      dispatch(setCurrentBalanceFrom(balanceFrom));

      if (fromBalance && toBalance) {
        const [firstAsset] = getTransferAssets(fromBalance, toBalance);

        if (firstAsset) handleSetCurrentAsset(firstAsset.asset);
      }
    }

    if (checkSpotBalanceFrom && fromBalance && toBalance) {
      dispatch(setCurrentBalanceTo(toBalance));
    }
  };

  const handleSetCurrentAsset = (asset: Nullable<Asset>) => {
    dispatch(setCurrentAsset(asset));
    setQuantity('');
    dispatch(setAssetPrecision(asset?.price_precision || 4));
    dispatch(removeTransfersAsset());
  };

  const handleSetDivisorSum = (value: number) => {
    if (subAccountSum) {
      const percentage = (value / 100) * Number(subAccountSum);

      setDivisorSum(value.toFixed(1));
      setQuantity(toFixedDecimalPrecision(percentage.toString(), assetPrecision));
    }
  };

  const handleSetQuantity = (value: number) => {
    const regex = /^([0-9]+([.][0-9]*)?|[.][0-9]+)?$/;

    if (!regex.test(String(value))) return;

    const stringValue = String(value);

    if (subAccountSum) {
      const parsedValue = parseFloat(stringValue);
      const calculatedPercentage = (parsedValue / Number(subAccountSum)) * 100;

      setQuantity(stringValue);
      setDivisorSum(calculatedPercentage.toFixed(1));
    }
  };

  const handleTransfers = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!currentAsset || !currentBalanceFrom || !currentBalanceTo) return;
    if (Number(quantity) < 0.000000001 || statusTransfers === EStatus.loading) return;

    const transfers: Transfers = {
      asset_symbol: currentAsset.symbol,
      from_sub_account_id: currentBalanceFrom.subAccountId,
      to_sub_account_id: currentBalanceTo.subAccountId,
      from_transfer_trading_type: currentBalanceFrom.assetType === ETradingType.spot ? 'SPOT' : 'FUTURES',
      to_transfer_trading_type: currentBalanceTo.assetType === ETradingType.spot ? 'SPOT' : 'FUTURES',
      quantity,
    };

    const { payload } = await dispatch(fetchTransfers(transfers));

    if (payload === true) {
      setAlertMessage('success', 'success');
      setQuantity('');
    } else if (payload === false) {
      setAlertMessage('transfer_not_possible', 'error');
    } else if (typeof payload === 'string') {
      const message = ERROR_MSG_LIST[payload] || payload;
      setAlertMessage(message, 'error');
    } else {
      setAlertMessage('internal error', 'error');
    }
  };

  const getAuthorizationTransfer = () => {
    if (Number(quantity) < 0.000000001
    || statusTransfers === 'loading'
    || (currentBalanceFrom?.subAccountId === currentBalanceTo?.subAccountId && currentBalanceFrom?.assetType === currentBalanceTo?.assetType)
    || Number(currentBalanceFrom?.quantity) < Number(quantity)) {
      return true;
    }
    return false;
  };

  const handleSetQuantityAll = () => {
    setQuantity(toFixedDecimalPrecision(subAccountSum, assetPrecision));
  };

  useEffect(() => {
    unsubscribeSubAccountAsset();
    batch(() => {
      dispatch(removeSubAccountsAssetsTransfers());
      dispatch(removeAssets());
      dispatch(removeSubAccountsAssets());
    });
  }, []);

  useEffect(() => {
    if (currentBalanceFrom) {
      setSubAccoundSum(currentBalanceFrom.quantity.toString());
      setDivisorSum('0');
    }
  }, [currentBalanceFrom]);

  useEffect(() => {
    if (currentAsset) {
      dispatch(setAssetPrecision(currentAsset.price_precision));
    }
  }, [currentAsset]);

  useEffect(() => {
    if (!currentExchange) return;

    batch(() => {
      dispatch(fetchSubAccountsAssetsTransfers({ exchange_id: currentExchange.id }));
      dispatch(fetchAssetsFutures(currentExchange.id));
    });
  }, [currentExchange]);

  useEffect(() => {
    if (exchanges && subAccounts) {
      const findFavoriteSubAccount = subAccounts.find((subAccount) => subAccount.is_favorite);

      if (findFavoriteSubAccount) {
        const favoriteExchange = exchanges.find((exchange) => exchange.id === findFavoriteSubAccount.exchange_id);

        if (favoriteExchange) {
          setCurrentExchangeId(favoriteExchange.id);
          setCurrentExchange(favoriteExchange);
        }
      } else {
        const [firstExchange] = exchanges;

        if (firstExchange) {
          setCurrentExchangeId(firstExchange.id);
          setCurrentExchange(firstExchange);
        }
      }
    }
  }, [exchanges, subAccounts]);

  useEffect(() => {
    if (subAccounts) {
      dispatch(setCurrentAsset(null));
    }
  }, [subAccounts]);

  useEffect(() => {
    if (exchanges && subAccountsAssetsTransfers && currentExchangeId) {
      const [firstSubAccountAsset] = subAccountsAssetsTransfers;

      if (firstSubAccountAsset) {
        const accounts = getAccountsBalance(firstSubAccountAsset.asset_id, firstSubAccountAsset.asset.symbol, currentExchangeId);
        const [fromSubAccount, toSubAccount] = accounts;

        const [firstSubAccountAssets] = getTransferAssets(fromSubAccount, toSubAccount);

        if (firstSubAccountAssets && !currentAsset) {
          handleSetCurrentAsset(firstSubAccountAssets.asset);
        }

        if (!currentBalanceFrom && !currentBalanceTo && accounts.length > 0) {
          const [fromSubAccount, toSubAccount] = accounts;

          batch(() => {
            dispatch(setSubAccountBalances(accounts));
            dispatch(setCurrentBalanceFrom(fromSubAccount)); // spot balance
            dispatch(setCurrentBalanceTo(toSubAccount || fromSubAccount)); // futures balance (if there is no second account, then set the first one)
          });
        }
      }
    }
  }, [exchanges, currentExchangeId, subAccountsAssetsTransfers, subAccounts, assetsFutures, currentBalanceTo]);

  useEffect(() => {
    if (currentAsset && currentExchangeId && subAccountsAssetsTransfers) {
      const accounts = getAccountsBalance(currentAsset.id, currentAsset.symbol, currentExchangeId);

      dispatch(setSubAccountBalances(accounts));

      if (currentBalanceFrom) {
        const subAccountIndex = accountBalances.findIndex((element) => element.subAccountId === currentBalanceFrom.subAccountId && element.assetType === currentBalanceFrom.assetType);
        dispatch(setCurrentBalanceFrom(accounts[subAccountIndex]));
      }

      if (currentBalanceTo) {
        const subAccountIndex = accountBalances.findIndex((element) => element.subAccountId === currentBalanceTo.subAccountId && element.assetType === currentBalanceTo.assetType);
        dispatch(setCurrentBalanceTo(accounts[subAccountIndex]));
      }
    } else if (subAccounts && !transferAsset) {
      // If there is no single asset, then empty accounts are created.
      if (!subAccountsAssetsTransfers?.length) {
        const accounts = getAccountsBalance(0, '', currentExchangeId);

        if (!accounts.length) {
          dispatch(setCurrentBalanceFrom(null));
        }

        dispatch(setSubAccountBalances(accounts));

        const indexFrom = accounts.findIndex((element) => element.assetType === ETradingType.spot);
        dispatch(setCurrentBalanceFrom(accounts[indexFrom]));

        const indexTo = accounts.findIndex((element) => element.assetType === ETradingType.futures);
        dispatch(setCurrentBalanceTo(accounts[indexTo]));
      }
    }
  }, [currentAsset, currentExchangeId, subAccounts, exchanges, subAccountsAssetsTransfers, transferAsset]);

  useEffect(() => {
    if (transferAsset) return;
    if (currentBalanceFrom && currentBalanceFrom) return;

    const parsedParams = qs.parse(location.search, { ignoreQueryPrefix: true });

    const subAccountId = Number(parsedParams?.transfer_sub_account_id || parsedParams?.sub_account_id);
    const exchangeId = Number(parsedParams?.exchange_id);

    if (!subAccountId) return;

    let accounts: AccountBalance[];

    if (exchanges && exchangeId && subAccountId) {
      const currentExchange = exchanges.find((exchange) => exchange.id === exchangeId);

      if (currentExchange) setCurrentExchange(currentExchange);
      setCurrentExchangeId(exchangeId);

      if (subAccountsAssetsTransfers) {
        const findSubAccountAssets = subAccountsAssetsTransfers.filter((asset) => asset.sub_account_id === subAccountId);
        const [firstTransferSubAccountAsset] = findSubAccountAssets;

        if (transferAsset) {
          // handleSetCurrentAsset(transferAsset.asset);

          // accounts = getAccountsBalance(transferAsset.asset.id, transferAsset.asset.symbol, exchangeId);
          // dispatch(setSubAccountBalances(accounts));

          // const findTransferAccountFrom = accounts.find((account) => account.subAccountId === subAccountId && account.assetType === transferAsset.asset_type);
          // const findTransferAccountTo = accounts.find((account) => account.subAccountId === subAccountId && account.assetType !== transferAsset.asset_type);

          // if (findTransferAccountFrom && findTransferAccountTo) {
          //   batch(() => {
          //     dispatch(setCurrentBalanceFrom(findTransferAccountFrom));
          //     dispatch(setCurrentBalanceTo(findTransferAccountTo));
          //   });
          // }
        } else if (firstTransferSubAccountAsset) {
          accounts = getAccountsBalance(firstTransferSubAccountAsset.asset_id, firstTransferSubAccountAsset.asset.symbol, exchangeId);
          dispatch(setSubAccountBalances(accounts));

          const findTransferAccountFrom = accounts.find((account) => account.subAccountId === subAccountId
          && account.exchangeId === exchangeId
          && account.assetType === ETradingType.spot);

          const findTransferAccountTo = accounts.find((account) => (currentExchange?.name === 'Bybit' ? account.subAccountId !== subAccountId : account.subAccountId === subAccountId)
            && account.exchangeId === exchangeId
            && (currentExchange?.name === 'Bybit' ? true : account.assetType === ETradingType.futures));

          if (findTransferAccountFrom && findTransferAccountTo) {
            batch(() => {
              dispatch(setCurrentBalanceFrom(findTransferAccountFrom));
              dispatch(setCurrentBalanceTo(findTransferAccountTo));
            });

            const [currentSubAccountAsset] = getTransferAssets(findTransferAccountFrom, findTransferAccountTo);
            if (currentSubAccountAsset) handleSetCurrentAsset(currentSubAccountAsset.asset);
          }
        } else {
          accounts = getAccountsBalance(0, '', exchangeId);
          dispatch(setSubAccountBalances(accounts));

          const findTransferAccountFrom = accounts.find((account) => account.subAccountId === subAccountId
          && account.exchangeId === exchangeId
          && account.assetType === ETradingType.spot);

          const findTransferAccountTo = accounts.find((account) => (currentExchange?.name === 'Bybit' ? account.subAccountId !== subAccountId : account.subAccountId === subAccountId)
          && account.exchangeId === exchangeId
          && (currentExchange?.name === 'Bybit' ? true : account.assetType === ETradingType.futures));

          if (findTransferAccountFrom) {
            batch(() => {
              dispatch(setCurrentBalanceFrom(findTransferAccountFrom));
              dispatch(setCurrentBalanceTo(findTransferAccountTo));
            });
          }
        }
      }
    }
  }, [location, subAccountsAssetsTransfers, transferAsset, exchanges, currentBalanceFrom, currentBalanceTo]);

  useEffect(() => {
    const parsedParams = qs.parse(location.search, { ignoreQueryPrefix: true });
    const exchangeId = Number(parsedParams?.exchange_id);

    const currentExchange = exchanges?.find((exchange) => exchange.id === (exchangeId || transferAsset?.asset.exchange_id));

    if (currentExchange) {
      setCurrentExchange(currentExchange);
      setCurrentExchangeId(exchangeId);
    }

    if (transferAsset && subAccountsAssetsTransfers) {
      const findTransferAsset = subAccountsAssetsTransfers.find((asset) => asset.asset_id === transferAsset.asset_id && asset.sub_account_id === transferAsset.sub_account_id);
      const accounts = getAccountsBalance(transferAsset.asset_id, transferAsset.asset.symbol, exchangeId || transferAsset.asset.exchange_id);

      if (findTransferAsset) handleSetCurrentAsset(findTransferAsset.asset);

      if (accounts) {
        dispatch(setSubAccountBalances(accounts));

        const findTransferAccountFrom = accounts.find((account) => account.subAccountId === transferAsset.sub_account_id && account.assetType === transferAsset.asset_type);
        const findTransferAccountTo = accounts.find((account) => account.subAccountId === transferAsset.sub_account_id && account.assetType !== transferAsset.asset_type);

        if (findTransferAccountFrom && findTransferAccountTo) {
          batch(() => {
            dispatch(setCurrentBalanceFrom(findTransferAccountFrom));
            dispatch(setCurrentBalanceTo(findTransferAccountTo));
          });
        }
      }
    }
  }, [transferAsset, subAccountsAssetsTransfers, exchanges]);

  return (
    <section ref={ref} className={styles.transfers}>
      <h2>{t('transfers')}</h2>

      <InputForm title={t('transfer_type')}>
        {exchanges?.length ? (
          <Select
            isActiveSearch={false}
            isSelectValue={(
              <div className={styles.selectItem}>
                <img src={currentExchange?.image} alt={currentExchange?.image} />
                <span className="short-name">{currentExchange?.name}</span>
              </div>
            )}
          >
            {
              exchanges.map((exchange) => (
                <div
                  key={exchange.name}
                  className={styles.selectItem}
                  role="button"
                  tabIndex={0}
                  onClick={() => handleSetCurrentExchange(exchange)}
                  onKeyDown={() => handleSetCurrentExchange(exchange)}
                >
                  <img src={exchange.image} alt={exchange.image} />
                  <span className="short-name">{exchange.name}</span>
                </div>
              ))
            }
          </Select>
        ) : <SelectSkeleton /> }
      </InputForm>

      <InputForm title={t('from_account')}>
        {accountBalances.length > 0 && currentBalanceFrom ? (
          <Select
            isActiveSearch={false}
            isSelectValue={(
              <div className="sub-account-select-item">
                <img src={currentBalanceFrom.exchangeImage || DefaultIconSvg} alt="exchange" />
                <BalanceType type={typeSubAccountBackNullable(currentBalanceFrom.exchangeId) || currentBalanceFrom.assetType} />
                <span className="short-name">{currentBalanceFrom.subAccountName}</span>
                <span className="item-spot-wallet">{!balanceVisible ? toFixedDecimalPrecision(currentBalanceFrom.quantity, currentBalanceFrom.precision) : HIDDEN_BALANCES}</span>
              </div>
            )}
          >
            {
              accountBalances.map((account) => (
                <div
                  key={account.subAccountAssetId}
                  className="sub-account-select-item"
                  role="button"
                  tabIndex={0}
                  onClick={() => handleSetCurrentBalanceFrom(account)}
                  onKeyDown={() => handleSetCurrentBalanceFrom(account)}
                >
                  <img src={account.exchangeImage || DefaultIconSvg} alt="exchange" />
                  <BalanceType type={typeSubAccountBackNullable(account.exchangeId) || account.assetType} />
                  <span className="short-name">{account.subAccountName}</span>
                  <span className="item-spot-wallet">{!balanceVisible ? toFixedDecimalPrecision(account.quantity, account.precision) : HIDDEN_BALANCES}</span>
                </div>
              ))
            }
          </Select>
        ) : <SelectSkeleton /> }
      </InputForm>

      <div className={styles.coup}>
        <span />
        <button onClick={turnOverBalances}>
          <CoupIconSvg />
        </button>
        <span />
      </div>

      <InputForm title={t('to_account')}>
        {exchanges?.length ? (
          <Select
            isActiveSearch={false}
            disabledSelect
            isSelectValue={(
              <div className={styles.selectItem}>
                <img src={currentExchange?.image} alt={currentExchange?.image} />
                <span className="short-name">{currentExchange?.name}</span>
              </div>
            )}
          >
            {
              exchanges.map((exchange) => (
                <div
                  key={exchange.name}
                  className={styles.selectItem}
                >
                  <img src={exchange.image} alt={exchange.image} />
                  <span className="short-name">{exchange.name}</span>
                </div>
              ))
            }
          </Select>
        ) : <SelectSkeleton /> }
      </InputForm>

      <InputForm>
        {accountBalances.length > 0 ? (
          <Select
            isActiveSearch={false}
            disabledSelect={accountBalances.length < 2}
            isSelectValue={(accountBalances.length > 1 && currentBalanceTo
              ? (
                <div className="sub-account-select-item">
                  <img src={currentBalanceTo.exchangeImage || DefaultIconSvg} alt="exchange" />
                  <BalanceType type={typeSubAccountBackNullable(currentBalanceTo.exchangeId) || currentBalanceTo.assetType} />
                  <span className="short-name">{currentBalanceTo.subAccountName}</span>
                  <span className="item-spot-wallet">{!balanceVisible ? toFixedDecimalPrecision(currentBalanceTo.quantity, currentBalanceTo.precision) : HIDDEN_BALANCES}</span>
                </div>
              ) : <div className="sub-account-select-item lock">{t('no_available_accounts')}</div>
            )}
          >
            {
              accountBalances.map((account) => (
                <div
                  key={account.subAccountAssetId}
                  className="sub-account-select-item"
                  role="button"
                  tabIndex={0}
                  onClick={() => handleSetCurrentBalanceTo(account)}
                  onKeyDown={() => handleSetCurrentBalanceTo(account)}
                >
                  <img src={account.exchangeImage || DefaultIconSvg} alt="exchange" />
                  <BalanceType type={typeSubAccountBackNullable(account.exchangeId) || account.assetType} />
                  <span className="short-name">{account.subAccountName}</span>
                  <span className="item-spot-wallet">{!balanceVisible ? toFixedDecimalPrecision(account.quantity, account.precision) : HIDDEN_BALANCES}</span>
                </div>
              ))
            }
          </Select>
        ) : <SelectSkeleton /> }
      </InputForm>

      <form onSubmit={handleTransfers}>
        <div className={styles.inputSelect}>
          {filteredAssetsByAccountAndType.length > 0 && currentAsset ? (
            <InputSelect
              isActiveSearch={false}
              inputValue={quantity}
              inputType={balanceVisible ? 'password' : 'string'}
              placeholder={currentExchange ? `${t('minimum')} ${EXCHANGE_TRANSFERS_LIMIT[currentExchange.name]}` : `${t('minimum')} 0`}
              onChangeInput={handleSetQuantity}
              onClickAll={handleSetQuantityAll}
              isSelectValue={(
                <div
                  className={styles.selectInstrumentItem}
                  tabIndex={0}
                  role="button"
                >
                  <img src={currentAsset.image} alt="asset" />
                  <span className={styles.symbol}>{currentAsset.symbol}</span>
                </div>
              )}
            >
              {
                filteredAssetsByAccountAndType.map((item) => (
                  <div
                    key={item.asset_id}
                    className={styles.selectInstrumentItem}
                    tabIndex={0}
                    role="button"
                    onClick={() => handleSetCurrentAsset(item.asset)}
                    onKeyDown={() => handleSetCurrentAsset(item.asset)}
                  >
                    <img src={item.asset.image} alt="asset" />
                    <span className={styles.symbol}>{item.asset.symbol}</span>
                  </div>
                ))
              }
            </InputSelect>
          )
            : <SelectSkeleton />}
        </div>

        <Button
          disabled={getAuthorizationTransfer()}
          background={getAuthorizationTransfer() ? 'black' : 'green'}
          loading={statusTransfers === EStatus.loading}
          type="submit"
        >{t('transfer')}
        </Button>
      </form>

      <div className={styles.divisorWrapper}>
        {
          [25, 50, 75, 100].map((value) => (
            <Divisor
              key={value}
              value={value}
              active={divisorSum === value.toFixed(1)}
              onClick={() => handleSetDivisorSum(value)}
            />
          ))
        }
      </div>

      <div className={styles.transferInfo}>
        <ShieldIconSvg />
        <p>
          {t('transfer_no_confirmation')}
          <Link to="/sub-accounts">{t('account')}</Link>
        </p>
      </div>
    </section>
  );
}));

export default TransfersBlock;
