import moment from 'moment';
import classNames from 'classnames';

import { useEffect, useRef, useState } from 'react';
import { ReactComponent as ThreePointsIconSvg } from 'src/shared/assets/images/trading/three-points.svg';

import { useTranslation } from 'react-i18next';
import { fromRole, typeTranslate } from '../../constants';
import { Notification as NotificationType, NotificationTypes } from '../../model/types';

import styles from './notification.module.scss';

interface INotificationProps {
    notification: NotificationType
    selectedNotifications: NotificationType[]
    className?: string
    onDelete: (id: number) => void
    onSelected: (notification: NotificationType) => void
    onMarkAsReadById: (id: number) => void
    checkboxVisible: boolean
}

const Notification = ({
  className, notification, onSelected, selectedNotifications, onDelete, onMarkAsReadById, checkboxVisible,
}: INotificationProps) => {
  const { t } = useTranslation();
  const {
    created_at: createdAt, from_role: role, message, id, read, type_message: type,
  } = notification;

  const optionsBtnRef = useRef<HTMLButtonElement>(null);
  const optionsContainerRef = useRef<HTMLUListElement>(null);

  const utcDate = moment.utc(createdAt);
  const localDateStr = utcDate.local().format('YYYY-MM-DD HH:mm');

  const [showOptions, setShowOptions] = useState(false);

  const handleOnSelected = (notification: NotificationType) => {
    onSelected(notification);
    setShowOptions(false);
  };

  const handleOnDelete = (id: number) => {
    onDelete(id);
    setShowOptions(false);
  };

  const handleOnMarkAsReadById = (id: number) => {
    onMarkAsReadById(id);
    setShowOptions(false);
  };

  useEffect(() => {
    const showOptionsHandler = (e: MouseEvent) => {
      e.stopPropagation();

      if (optionsContainerRef.current?.contains(e.target as Node)) return;

      if (!optionsBtnRef.current?.contains(e.target as Node)) {
        setShowOptions(false);
      }
    };

    window.addEventListener('click', showOptionsHandler);

    return () => {
      window.removeEventListener('click', showOptionsHandler);
    };
  }, [optionsContainerRef, optionsBtnRef]);

  const key = Object.entries(fromRole).find(([key, _]) => key === role)?.[0] || 'Unknown';
  const keyLabel = Object.entries(typeTranslate).find(([key, _]) => key === type)?.[0] || 'Unknown';

  return (
    <div className={classNames(
      styles.message,
      className && className,
      { [styles.news]: type === NotificationTypes.NEWS && !read },
      { [styles.warning]: type === NotificationTypes.WARNING && !read },
      { [styles.important]: type === NotificationTypes.IMPORTANT && !read },
      { [styles.readAny]: !read && NotificationTypes.NEWS },
      { [styles.readNews]: !read && type === NotificationTypes.NEWS },
      { [styles.readWarning]: !read && type === NotificationTypes.WARNING },
      { [styles.readImportant]: !read && type === NotificationTypes.IMPORTANT },
    )}
    >

      <div className={styles.messageWrapper}>
        <button onClick={() => handleOnSelected(notification)} className={classNames(styles.checkboxWrapper, { [styles.visibleCheckbox]: checkboxVisible })}>
          <input
            className={styles.checkbox}
            type="checkbox"
            checked={selectedNotifications.some((item) => item.id === notification.id)}
            onChange={() => {}}
          />
        </button>
        <div className={styles.bodyWrapper}>
          <div style={{ display: role || type ? 'flex' : 'none' }} className={styles.messageTitle}>
            <p style={{ display: role ? 'flex' : 'none' }}>
              {role && t(key)}
            </p>
            {type && (
              <div
                style={{ display: type ? 'flex' : 'none' }}
                className={classNames(
                  styles.type,
                  { [styles.news]: type === NotificationTypes.NEWS },
                  { [styles.warning]: type === NotificationTypes.WARNING },
                  { [styles.important]: type === NotificationTypes.IMPORTANT },
                )}
              >{t(keyLabel)}
              </div>
            )}
          </div>

          <button ref={optionsBtnRef} onClick={() => setShowOptions((prev) => !prev)} className={styles.optionsBtn}>
            <ThreePointsIconSvg />
          </button>

          <ul ref={optionsContainerRef} className={classNames(styles.optionsList, { [styles.open]: showOptions })}>
            <li>{t('action')}:</li>
            <li
              role="button"
              tabIndex={0}
              onClick={() => handleOnSelected(notification)}
              onKeyDown={() => handleOnSelected(notification)}
            >{t('select')}
            </li>
            <li
              role="button"
              tabIndex={0}
              onClick={() => handleOnMarkAsReadById(id)}
              onKeyDown={() => handleOnMarkAsReadById(id)}
            >{t('mark as unread')}
            </li>
            <li
              role="button"
              tabIndex={0}
              onClick={() => handleOnDelete(id)}
              onKeyDown={() => handleOnDelete(id)}
            >
              {t('delete')}
            </li>
          </ul>

          <div className={styles.messageBody}>
            <p>{message[0].value}</p>
          </div>

          <p className={styles.messageTime}>{localDateStr}</p>
        </div>
      </div>
    </div>
  );
};

export default Notification;
