import { ReactComponent as LogOutSvg } from 'src/shared/assets/images/header/log-out.svg';
import Button from 'src/shared/ui/button/button/button';
import Hint from 'src/shared/ui/help/hint';
import { useTranslation } from 'react-i18next';
import styles from './exit-modal-content.module.scss';

interface IExitModalContentProps {
    logOut: () => void
    onClose: () => void
}

const ExitModalContent = ({ logOut, onClose }: IExitModalContentProps) => {
  const { t } = useTranslation();
  return (
    <div className={styles.exitWrapper}>
      <Hint mediumText text={t('inactive logout warning')} />
      <div className={styles.exitActions}>
        <Button onClick={logOut} background="red">{t('log out')} <LogOutSvg /></Button>
        <Button onClick={onClose} background="black">{t('cancel')}</Button>
      </div>
    </div>
  );
};

export default ExitModalContent;
