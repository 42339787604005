import classNames from 'classnames';
import { Drawer } from '@mui/material';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDisclosure } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'src/app/store/store';
import { useTheme } from 'src/shared/libs/hooks/use-theme';
import { multiplyValues } from 'src/shared/libs/helpers/helper.lib';
import { removeSubAccountsAssets } from 'src/app/store/slices/sub-accounts/slice';
import { AssetsBybitFilteredParams } from 'src/pages/account/model/types';
import { SubAccountAsset, SubAccountType } from 'src/app/store/slices/sub-accounts/types';
import { ASSETS_SORT_COLUMNS, ASSETS_TYPES, ASSETS_TYPES_BYBIT } from 'src/pages/account/constants';
import { selectStatusSubAccountsAssets, selectSubAccountsAssets } from 'src/app/store/slices/sub-accounts/selectors';
import {
  fetchSubAccountsAssets, SubAccountsAssetsParams, subscribeInstrument, unsubscribeInstrument,
} from 'src/app/store/slices/sub-accounts/thunks';
import { EStatus, Nullable } from 'src/shared/types/global-types';

import { ReactComponent as FilterIconSvg } from 'src/shared/assets/images/filter.svg';
import { ReactComponent as SearchIconSvg } from 'src/shared/assets/images/glass.svg';
import Empty from 'src/entities/empty/empty';
import Button from 'src/shared/ui/button/button/button';
import Select from 'src/shared/ui/select/select';
import InputText from 'src/shared/ui/input/input-text/input-text';
import InputForm from 'src/shared/ui/input/input-form/input-form';
import ButtonClose from 'src/shared/ui/button/button-close/button-close';
import SelectSkeleton from 'src/shared/ui/skeleton/select-skeleton/select-skeleton';
import AssetTableBybitItemAdaptive from './components/asset-table-bybit-item-adaptive';
import styles from './assets-table-bybit-adaptive.module.scss';

interface IAssetsTableBybitAdaptiveProps {
  subAccount: Nullable<SubAccountType>
}

const AssetsTableBybitAdaptive = ({ subAccount } : IAssetsTableBybitAdaptiveProps) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const dispatch = useAppDispatch();

  const subAccountAssets = useSelector(selectSubAccountsAssets);
  const statusSubAccountAssets = useSelector(selectStatusSubAccountsAssets);

  const [searchValue, setSearchValue] = useState('');
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [sortField, setSortField] = useState(ASSETS_SORT_COLUMNS[0]);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [filterField, setFilterField] = useState(ASSETS_TYPES[0]);
  const [assetsDataFilteredParams, setAssetsDataFilteredParams] = useState<Nullable<AssetsBybitFilteredParams>>(null);
  const [subAccountAssetsData, setSubAccountAssetsData] = useState<Nullable<SubAccountAsset[]>>(null);
  const [prevSubAccountAssetsLength, setPrevSubAccountAssetsLength] = useState(0);

  const [openedTableFilter, { open: openTableFilter, close: closeTableFilter }] = useDisclosure(false);

  const handleSetAssetsDataFilteredParams = () => {
    if (!sortField || !filterField) return;

    const params: AssetsBybitFilteredParams = {
      sortedColumnsBy: sortField.column,
      filteredColunmnsBy: filterField.column,
    };

    setAssetsDataFilteredParams(params);
    closeTableFilter();
  };

  const handleFetchSubAccountsAssets = async () => {
    if (!subAccount) return;

    const params: SubAccountsAssetsParams = {
      sub_account_id: subAccount.id,
      exchange_id: subAccount.exchange_id,
    };

    const response = await dispatch(fetchSubAccountsAssets(params));
    const payload = unwrapResult(response);

    if (payload) {
      if (!subAccountAssetsData?.length) {
        setSubAccountAssetsData(payload);
      }
    }
  };

  const handleResetFilters = () => {
    setSortField(ASSETS_SORT_COLUMNS[0]);
    setFilterField(ASSETS_TYPES[0]);
  };

  useEffect(() => {
    handleFetchSubAccountsAssets();
  }, [subAccount]);

  useEffect(() => {
    if (subAccountAssets && assetsDataFilteredParams && subAccount) {
      const filteredAssetsDataByParams = subAccountAssets.filter((item) => {
        if (assetsDataFilteredParams.filteredColunmnsBy === true) {
          return item.collateralizable === true;
        }

        if (assetsDataFilteredParams.filteredColunmnsBy === false) {
          return item.collateralizable === false;
        }

        if (assetsDataFilteredParams.filteredColunmnsBy === null) {
          return true;
        }

        return false;
      });

      const sortedAssetsDataByParams = filteredAssetsDataByParams.sort((a, b) => {
        if (assetsDataFilteredParams.sortedColumnsBy === 'quantity') {
          const availableA = a.instrument ? multiplyValues(a.instrument.price.bid, a.quantity) : a.quantity;
          const availableB = b.instrument ? multiplyValues(b.instrument.price.bid, b.quantity) : b.quantity;

          if (availableA < availableB) {
            return sortOrder === 'asc' ? 1 : -1;
          }
          if (availableA > availableB) {
            return sortOrder === 'asc' ? -1 : 1;
          }
          return 0;
        }

        if (assetsDataFilteredParams.sortedColumnsBy === 'symbol') {
          const assetA = a.asset.symbol;
          const assetB = b.asset.symbol;

          if (assetA < assetB) {
            return sortOrder === 'asc' ? -1 : 1;
          }
          if (assetA > assetB) {
            return sortOrder === 'asc' ? 1 : -1;
          }
          return 0;
        }

        return 0;
      });

      setSubAccountAssetsData(sortedAssetsDataByParams);
      setAssetsDataFilteredParams(null);
    }
  }, [subAccountAssets, assetsDataFilteredParams, subAccount]);

  useEffect(() => {
    if (subAccountAssets && searchValue) {
      const filteredAssetsBySearchValue = subAccountAssets.filter((asset) => asset.asset.symbol.toLowerCase().includes(searchValue.toLowerCase()) || asset.asset.name.toLowerCase().includes(searchValue.toLowerCase()));
      setSubAccountAssetsData(filteredAssetsBySearchValue);
    } else if (showSearchInput) {
      setSubAccountAssetsData(subAccountAssets);
    }
  }, [searchValue]);

  useEffect(() => {
    if (subAccountAssets) {
      // Extract instrument IDs from assets
      const instrumentIds: number[] = subAccountAssets
        .map((asset) => asset.instrument?.id)
        .filter((id) => id !== null && id !== undefined) as number[];

      // Check if there are instruments to subscribe to and if there have been changes in assets since the last call
      if (instrumentIds.length > 0 && subAccountAssets.length !== prevSubAccountAssetsLength) {
        // Subscribe to instrument changes
        subscribeInstrument(instrumentIds);

        // Update the previous length of assets
        setPrevSubAccountAssetsLength(subAccountAssets.length);
      }
    }
  }, [subAccountAssets, prevSubAccountAssetsLength]);

  useEffect(() => () => {
    dispatch(removeSubAccountsAssets());
    unsubscribeInstrument();
  }, []);

  return (
    <section className={styles.assetsTableAdaptive}>
      <header>
        <h3>{t('assets')} <span className={classNames(styles.assetsCount, { [styles.show]: showSearchInput })}>({subAccountAssetsData?.length || 0})</span></h3>
        <div className={styles.actions}>
          <SearchIconSvg onClick={() => setShowSearchInput((prev) => !prev)} className={styles.actionBtn} />
          <FilterIconSvg onClick={openTableFilter} className={styles.actionBtn} />
        </div>
      </header>

      <InputText
        className={classNames(styles.search, { [styles.show]: showSearchInput })}
        value={searchValue}
        placeholder="Tether (USDT)"
        onChange={setSearchValue}
      />

      <div className={styles.table}>
        {statusSubAccountAssets === EStatus.success && subAccountAssetsData && (
          subAccountAssetsData.map((asset) => (
            <AssetTableBybitItemAdaptive key={asset.asset.id} asset={asset} subAccount={subAccount} />
          ))
        )}

        {statusSubAccountAssets === EStatus.rejected || statusSubAccountAssets === EStatus.success && (!subAccountAssetsData || !subAccountAssetsData.length) && (
          <div className={styles.emptyTable}>
            <Empty>{t('assets_list_empty')}</Empty>
          </div>
        )}
        {statusSubAccountAssets === EStatus.loading && (
          [1, 2].map((item) => (
            <SelectSkeleton key={item} radius="16px" height={243} />
          ))
        )}
      </div>

      <Drawer className={styles.drawer} onClose={() => closeTableFilter()} anchor="bottom" open={openedTableFilter}>
        <div className={styles.filterWrapper}>
          <div className={styles.header}>
            <h3>{t('filters')}</h3>
            <ButtonClose className={styles.closeBtn} onClick={() => closeTableFilter()} />
          </div>

          <div className={styles.content}>
            <InputForm title={t('platform')}>
              <Select
                className={styles.sortItemsContainer}
                isActiveSearch={false}
                isSelectValue={(
                  <button className={styles.selectItem}>{t(filterField?.title)}</button>
                )}
              >
                { ASSETS_TYPES_BYBIT.map((sortItem: Record<string, any>) => (
                  <button onClick={() => setFilterField(sortItem)} className={styles.selectItem} key={sortItem.column}>{t(sortItem.title)}</button>
                ))}
              </Select>
            </InputForm>

            <InputForm title={t('sort')}>
              <Select
                className={styles.sortItemsContainer}
                isActiveSearch={false}
                isSelectValue={(
                  <button className={styles.selectItem}>{t(sortField?.title)}</button>
                )}
              >
                { ASSETS_SORT_COLUMNS.map((sortItem: Record<string, any>) => (
                  <button onClick={() => setSortField(sortItem)} className={styles.selectItem} key={sortItem.column}>{t(sortItem.title)}</button>
                ))}
              </Select>
            </InputForm>
          </div>

          <Button onClick={handleSetAssetsDataFilteredParams} background="green">{t('apply')}</Button>
          <Button onClick={handleResetFilters} background={theme === 'light' ? 'black' : 'white'}>{t('reset filters')}</Button>
        </div>
      </Drawer>
    </section>
  );
};

export default AssetsTableBybitAdaptive;
