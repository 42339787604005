import { ColumnOrderItem } from 'src/shared/types/global-types';

export const assetsTableColumnByBit: ColumnOrderItem[] = [
  { key: 'asset', label: 'instrument' },
  { key: 'quantity', label: 'quantity_count' },
  { key: 'available', label: 'available' },
  { key: 'pnl', label: 'pnl_day_percent' },
  { key: 'collateralizable', label: 'сollateral' },
  { key: 'output', label: 'withdrawal' },
];

export const assetsTableColumnBinance: ColumnOrderItem[] = [
  { key: 'asset', label: 'instrument' },
  { key: 'quantity', label: 'quantity_count' },
  { key: 'available', label: 'available' },
  { key: 'pnl', label: 'pnl_day_percent' },
  { key: 'output', label: 'withdrawal' },
];

export const dustTransformsAssetsColumn: ColumnOrderItem[] = [
  { key: 'checkbox', label: 'checkbox' },
  { key: 'asset', label: 'asset' },
  { key: 'quantity', label: 'quantity_count' },
  { key: 'priceInUSDT', label: 'usdt_value' },
  { key: 'priceInBNB', label: 'bnb_value' },
];

export const dustTransfromsHistoryColumn: ColumnOrderItem[] = [
  { key: 'created_at', label: 'date and time' },
  { key: 'commission', label: 'commission' },
  { key: 'income_quantity', label: 'quantity_count' },
  { key: 'to_asset', label: 'asset' },
];

export const CONVERSION_ASSETS = ['BNB', 'USDT'] as const;

export const ASSETS_TYPES: Record<string, any> = [
  {
    column: 0,
    title: 'all',
  },
  {
    column: 2,
    title: 'futures',
  },
  {
    column: 1,
    title: 'spot',
  },
];

export const ASSETS_TYPES_BYBIT: Record<string, any> = [
  {
    column: null,
    title: 'all',
  },
  {
    column: true,
    title: 'margin_assets',
  },
  {
    column: false,
    title: 'free_assets',
  },
];

export const ASSETS_SORT_COLUMNS: Record<string, any> = [
  {
    column: 'symbol',
    title: 'sort_by_name',
  },
  {
    column: 'quantity',
    title: 'sort_by_amount_usdt',
  },
];
