import { ReactComponent as CopyIconSvg } from 'src/shared/assets/images/copy.svg';
import { ReactComponent as CheckIconSvg } from 'src/shared/assets/images/check.svg';

import './copy-icon.scss';
import { ActionIcon, CopyButton, Tooltip } from '@mantine/core';
import { useTranslation } from 'react-i18next';

interface ICopyIconProps {
  onClick?: () => void
  value?: string | null | undefined
}
const CopyIcon = ({ value, onClick }: ICopyIconProps) => {
  const { t } = useTranslation();
  return (
    <CopyButton value={value || ''} timeout={2000}>
      {({ copied, copy }) => (
        <Tooltip withArrow label={copied ? t('done') : t('copy')} position="bottom">
          <ActionIcon className="copy-icon-disable" disabled={value === null || value === undefined} color={copied ? 'teal' : 'gray'} variant="subtle" onClick={copy}>
            {copied ? (
              <CheckIconSvg />
            ) : (
              <CopyIconSvg />
            )}
          </ActionIcon>
        </Tooltip>
      )}
    </CopyButton>
  );
};

export default CopyIcon;
