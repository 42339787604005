import { CalclulationLeverage } from '../model/types';

interface PayloadError {
  [key: string]: {
    msg: string;
    type: 'success' | 'warning' | 'error';
  };
}

export const MARGIN_MODE = [{
  name: 'cross_margin',
  value: 1,
},
{
  name: 'isolated',
  value: 2,
},
] as const;

export const CALCULATION_LEVERAGE: CalclulationLeverage[] = [
  {
    name: 'from_deposit',
    type: 'FROM_DEPOSIT',
  },
  {
    name: 'from_working_volume',
    type: 'FROM_WORKING_VOLUME',
  },
];

export const CALCULATION_LEVERAGE_ERROR_MSG: PayloadError = {
  true: {
    msg: 'calculation_in_progress',
    type: 'success',
  },
  false: {
    msg: 'internal error',
    type: 'error',
  },
  TOO_MANY_REQUESTS: {
    msg: 'too many attempts 15',
    type: 'warning',
  },
  TASK_IN_PROGRESS: {
    msg: 'leverage_calculation_in_process',
    type: 'warning',
  },
  MAX_VALUE_FOR_CHOOSEN_INSTRUMENTS_EXCEEDED: {
    msg: 'max_leverage_limit_reached',
    type: 'error',
  },
  SUB_ACCOUNT_NOT_FOUND: {
    msg: 'error_no_sub_account',
    type: 'error',
  },
  default: {
    msg: 'internal error',
    type: 'error',
  },
};

export const LEVERAGE = [2, 5, 10, 20, 25, 50];
