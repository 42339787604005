import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EStatus } from 'src/shared/types/global-types';
import { IPositionSummarySharedSlice } from './types';
import { fetchPositionSummaryShared } from './thunk';

const initialState: IPositionSummarySharedSlice = {
  status: EStatus.loading,
  candlesStatus: EStatus.loading,
  error: null,
  positionSummaryShared: null,
};

export const positionSummarySharedSlice = createSlice({
  name: 'positionSummaryShared',
  initialState,
  reducers: {
    setCandlesStatus: (state, action: PayloadAction<EStatus>) => {
      state.candlesStatus = action.payload;
    },
    clearPositionSummarySharedSlice: (state) => {
      state.error = null;
      state.positionSummaryShared = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPositionSummaryShared.pending, (state) => {
        state.status = EStatus.loading;
        state.error = null;
      })
      .addCase(fetchPositionSummaryShared.fulfilled, (state, action) => {
        state.positionSummaryShared = action.payload;
        state.status = EStatus.success;
        state.error = null;
      })
      .addCase(fetchPositionSummaryShared.rejected, (state, action) => {
        state.status = EStatus.rejected;
        state.positionSummaryShared = null;
        state.error = action.payload as string;
      });
  },
});

export const { setCandlesStatus, clearPositionSummarySharedSlice } = positionSummarySharedSlice.actions;
export const positionSummarySharedReducer = positionSummarySharedSlice.reducer;
