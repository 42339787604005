import Button from 'src/shared/ui/button/button/button';
import Hint from 'src/shared/ui/help/hint';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import styles from './close-positions-content.module.scss';

interface IClosePositionsContentProps {
    onClose: () => void
    onSubmit: () => void
}

const ClosePositionsContent = ({ onClose, onSubmit }: IClosePositionsContentProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.content}>
      <div className={styles.body}>
        <p>{t('confirm close all positions and cancel all orders')}</p>
        <Hint text={t('all positions will be closed at market price')} />
      </div>
      <div className={styles.actions}>
        <Button onClick={onSubmit} background="green">{t('yes close all')}</Button>
        <Button onClick={onClose} background="gray">{t('cancel')}</Button>
      </div>
    </div>
  );
};

export default ClosePositionsContent;
