import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { saveToLocalStorage } from 'src/shared/libs/helpers/helper.lib';
import { ReactComponent as GooglePlayIconSvg } from 'src/shared/assets/images/socials/google-play.svg';
import { ReactComponent as AppStoreIconSvg } from 'src/shared/assets/images/socials/app-store.svg';
import { GA_LINK_APP_STORE, GA_LINK_GOOGLE_PLAY } from 'src/shared/constants/app-links';
import Button from 'src/shared/ui/button/button/button';
import styles from './connection-ga-step-zero.module.scss';

interface IConnectionGaStepZero {
  closeModal: () => void
  nextStep?: () => void
}
export const ConnectionGaStepZero = ({ closeModal, nextStep }:IConnectionGaStepZero) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const startConnectedGA = async () => {
    if (nextStep) {
      nextStep();
    }
    navigate('/profile?tab=1');
  };

  const handleSkipCennection = () => {
    closeModal();
    saveToLocalStorage('showGA', false);
  };

  return (
    <div className={styles.gaModalWrapper}>
      <p className={styles.info}>{t('download_and_install_app')} <br /> Google Authenticator</p>

      <div className={styles.downloadLinksWrapper}>
        <a className={styles.downloadLink} href={GA_LINK_GOOGLE_PLAY}>
          <GooglePlayIconSvg />
          <span>{t('download')} <br /> {t('from').toLowerCase()} Google Play</span>
        </a>
        <a className={styles.downloadLink} href={GA_LINK_APP_STORE}>
          <AppStoreIconSvg />
          <span>{t('download')} <br /> {t('from').toLowerCase()} App Store</span>
        </a>
      </div>

      <div className={styles.gaModalActions}>
        <Button onClick={handleSkipCennection} background="black">{t('no_more_notifications')}</Button>
        <Button onClick={startConnectedGA} background="green">{t('next')}</Button>
      </div>
    </div>
  );
};
