import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { EStatus, Nullable } from 'src/shared/types/global-types';
import InputForm from 'src/shared/ui/input/input-form/input-form';
import Select from 'src/shared/ui/select/select';
import Button from 'src/shared/ui/button/button/button';
import { Instrument } from '../../model/types';
import { Calculator } from '../calculator';
import { MARGIN_MODE } from '../../constants';
import { selectLeverages } from '../../model/selectors';
import './change-leverage-instrument.scss';

interface IChnageLeverageInstrumentProps {
  opened: boolean;
  close: () => void;
  saveSettings: () => void;
  instrument: Nullable<Instrument>;
  marginModeOfSelectedInstrument?: any;
  setChoosenBracket: (value: number[]) => void;
  setMarginMode: (value: number) => void;
}

const ChnageLeverageInstrument: React.FC<IChnageLeverageInstrumentProps> = ({
  opened,
  close,
  saveSettings,
  instrument,
  setChoosenBracket,
  marginModeOfSelectedInstrument,
  setMarginMode,
}) => {
  const { t } = useTranslation();
  const { statusParams } = useSelector(selectLeverages);
  const instrumentEmpty = {} as Instrument;

  return opened ? (
    <div className="instrument-settings">
      <h4 className="title">{t('credit_leverage')}</h4>

      <Calculator instrument={instrument || instrumentEmpty} setChoosenBracket={setChoosenBracket} />

      <InputForm title={t('margin_mode')}>
        <Select isActiveSearch={false} isSelectValue={<span className="marge-item">{t(marginModeOfSelectedInstrument?.name)}</span>}>
          {MARGIN_MODE.map((item) => (
            <span
              className="marge-item"
              key={item.value}
              role="button"
              tabIndex={0}
              onClick={() => setMarginMode(item.value)}
              onKeyDown={() => {}}
            >
              {t(item.name)}
            </span>
          ))}
        </Select>
      </InputForm>

      <div className="instrument-actions">
        <Button
          disabled={statusParams === EStatus.loading}
          loading={statusParams === EStatus.loading}
          onClick={saveSettings}
          background="green"
        >
          {t('save')}
        </Button>
        <Button
          disabled={statusParams === EStatus.loading}
          onClick={close}
          background="black"
        >
          {t('close')}
        </Button>
      </div>
    </div>
  ) : null;
};

export default ChnageLeverageInstrument;
