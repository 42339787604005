import qs from 'qs';
import { AxiosError } from 'axios';
import { useSelector, batch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { useAppDispatch } from 'src/app/store/store';
import { setVerificationId } from 'src/app/store/slices/verifications/slice';

import { Token } from 'src/shared/types/global-types';
import { setAuth, setIsWelcomePage } from 'src/pages/auth/login/model/slice';
import { SMS_TYPES } from 'src/shared/constants/constants';
import { UserRegistration } from 'src/pages/auth/login/model/types';
import { selectRegistration } from 'src/pages/auth/registration/model/selectors';
import { saveTokensLocalStorage } from 'src/shared/libs/helpers/save-tokens';

import Hint from 'src/shared/ui/help/hint';
import Button from 'src/shared/ui/button/button/button';
import useAlert from 'src/shared/libs/hooks/use-alert';
import InputText from 'src/pages/auth/components/inputs-auth/input-auth';
import InputPhone from 'src/pages/auth/components/inputs/input-phone';
import InputAction from 'src/pages/auth/components/inputs-auth/input-action';
import discordIcon from 'src/shared/assets/images/socials/discordGray.svg';
import InputActionSelect from 'src/pages/auth/components/inputs-auth/input-select';
import AuthService, { SendCodeData } from 'src/services/auth.service';

import { deleteCookie, getCookie, setCookie } from 'src/shared/libs/helpers/helper.lib';
import { ERROR_MSG_LIST } from '../../constants';
import styles from './personal-data-form.module.scss';

type PersonalData = {
  first_name: string,
  last_name: string,
  father_name?: string,
  discord_name: string,
  phone_number: string,
  phone_verification_code: number,
  send_code?: string,
  partner?: string,

}

const schema = yup.object().shape({
  first_name: yup.string().required('required_field_first_name').matches(/^[a-zA-Z]+$/, 'field_should_contain_only_latin_letters'),
  last_name: yup.string().required('required_field_last_name').matches(/^[a-zA-Z]+$/, 'field_should_contain_only_latin_letters'),
  father_name: yup.string().matches(/^[a-zA-Z]*$/, {
    message: 'field_should_contain_only_latin_letters',
    excludeEmptyString: true, // Exclude check for empty string
  }),
  discord_name: yup.string().required('field required').matches(/^[^\u0400-\u04FF]*$/, 'field_should_contain_only_latin_letters'),
  phone_number: yup.string().required('field required'),
  phone_verification_code: yup.number().required('field requiredы'),
  send_code: yup.string(),
  partner: yup.string(),
});

const PersonalDataForm = () => {
  const dispatch = useAppDispatch();

  const { t, i18n } = useTranslation();
  const { setAlertMessage } = useAlert();

  const { email, password, regionCode } = useSelector(selectRegistration);

  const [timer, setTimer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isCodeView, setIsCodeView] = useState(false);
  const [selectSendSmsType, setSelectSmsType] = useState('whatsapp');
  const [disabledPartnerInput, setDisabledPartnerInput] = useState(false);

  const form = useForm<PersonalData>({
    defaultValues: {
      first_name: '',
      last_name: '',
      father_name: '',
      discord_name: '',
      phone_number: '',
      phone_verification_code: undefined,
      send_code: undefined,
      partner: '',
    },
    resolver: yupResolver(schema),
  });

  const {
    control, handleSubmit, formState, setValue,
  } = form;
  const { errors } = formState;

  const verificationPhone = async (phoneNumber: string): Promise<boolean> => {
    let result = false;

    const sendCode: SendCodeData = {
      phone_number: phoneNumber,
      email,
    };

    try {
      const response = selectSendSmsType === 'whatsapp' ? await AuthService.sendCodeWhatsApp(sendCode) : await AuthService.sendCodePhone(sendCode);
      const { data } = response;

      if (data === undefined) throw response;
      if (data) setAlertMessage('code_sent', 'success');

      form.clearErrors('phone_number');

      result = true;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) {
        const { detail } = error.response.data;

        if (detail === 'PHONE_EXISTS') {
          form.setError('phone_number', {
            type: 'manual',
            message: t('phone_exists'),
          });
        }

        const messageType = ERROR_MSG_LIST[detail] || 'internal error';
        setAlertMessage(messageType, 'error');
      } else {
        setAlertMessage('internal error', 'error');
      }
      result = false;
    }

    return result;
  };

  const userRegistrationRequest = async (resistrationData: PersonalData) => {
    setLoading(true);

    const newUser: UserRegistration = {
      first_name: resistrationData.first_name,
      last_name: resistrationData.last_name,
      discord_name: resistrationData.discord_name,
      father_name: resistrationData.father_name,
      language: i18n.language,
      email,
      password,
      phone_number: regionCode + resistrationData.phone_number,
    };

    if (resistrationData.partner) {
      newUser.partner = resistrationData.partner;
    }

    if (selectSendSmsType === 'phone') {
      newUser.phone_verification_code = resistrationData.phone_verification_code;
    } else {
      newUser.whatsapp_verification_code = resistrationData.phone_verification_code;
    }

    try {
      const response = await AuthService.registration(newUser);

      const { data } = response;

      if (data === undefined) throw response;

      const verificationId = data.verification_id;
      const token: Token = {
        access_token: data.access_token,
      };

      batch(() => {
        saveTokensLocalStorage(token);
        deleteCookie('partner');

        batch(() => {
          dispatch(setIsWelcomePage(true));
          dispatch(setAuth(true));

          if (verificationId) dispatch(setVerificationId(verificationId));
        });
      });
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) {
        const { detail } = error.response.data;
        const errorMessage = Array.isArray(detail) ? detail[0].loc[1] : detail;

        if (detail === 'INVALID_VERIFICATION_CODE') {
          form.setError('phone_verification_code', {
            type: 'manual',
            message: t('incorrect_code'),
          });
        }

        const messageType = ERROR_MSG_LIST[errorMessage] || (errorMessage ? JSON.stringify(errorMessage) : 'internal error');
        setAlertMessage(messageType, 'error');
      } else {
        setAlertMessage('internal error', 'error');
      }
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = (data: PersonalData) => {
    userRegistrationRequest(data);
  };

  useEffect(() => {
    if (errors.phone_verification_code && errors.phone_verification_code.message === 'field required' && !isCodeView) {
      form.setError('send_code', {
        type: 'manual',
        message: t('first_send_code'),
      });
    }
  }, [errors, isCodeView]);

  useEffect(() => {
    try {
      const partnerIdFromCookie = getCookie('partner');
      const queryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
      const queryPartnerId = queryParams.partner;

      if (queryPartnerId && typeof queryPartnerId === 'string') {
        if (queryPartnerId !== partnerIdFromCookie) {
          setCookie('partner', queryPartnerId);
        }
        setValue('partner', queryPartnerId);
        setDisabledPartnerInput(true);
      } else if (partnerIdFromCookie) {
        setValue('partner', partnerIdFromCookie);
        setDisabledPartnerInput(true);
      }
    } catch (error) {
      console.debug('Error in useEffect when handling partner', error);
    }
  }, []);

  return (
    <form className={styles.personalDataForm} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.formGroup}>
        <Controller
          name="last_name"
          control={control}
          render={({ field: { ref, ...fieldProps } }) => (
            <InputText
              ref={ref}
              error={!!errors.last_name}
              placeholder={t('enter_data')}
              label={t('last_name')}
              {...fieldProps}
            />
          )}
        />

        <Controller
          name="first_name"
          control={control}
          render={({ field: { ref, ...fieldProps } }) => (
            <InputText
              ref={ref}
              error={!!errors.first_name}
              placeholder={t('enter_data')}
              label={t('first_name')}
              {...fieldProps}
            />
          )}
        />
      </div>
      <span className={styles.inputInfo}>{t('fill_name_in_latin_letters')}</span>
      {errors.last_name && errors.last_name.message && <Hint error text={t(errors.last_name.message)} />}
      {errors.first_name && errors.first_name.message && <Hint error text={t(errors.first_name.message)} />}

      <Controller
        name="father_name"
        control={control}
        render={({ field: { ref, ...fieldProps } }) => (
          <InputText
            ref={ref}
            placeholder={t('optional_field')}
            label={t('middle_name')}
            {...fieldProps}
          />
        )}
      />
      <span className={styles.inputInfo}>{t('no_middle_name_leave_blank')}</span>
      {errors.father_name && errors.father_name.message && <Hint error text={t(errors.father_name.message)} />}

      <Controller
        name="discord_name"
        control={control}
        render={({ field: { ref, ...fieldProps } }) => (
          <InputText
            ref={ref}
            error={!!errors.discord_name}
            placeholder={t('discord_login')}
            label="Discord"
            icon={discordIcon}
            {...fieldProps}
          />
        )}
      />
      {errors.discord_name && errors.discord_name.message && <Hint error text={t(errors.discord_name.message)} />}

      <Controller
        name="phone_number"
        control={control}
        render={({
          field: {
            ref, value, onChange, ...fieldProps
          },
        }) => (
          <InputPhone
            ref={ref}
            isDisabled={isCodeView}
            error={!!errors.phone_number}
            placeholder={t('your_number')}
            label={t('phone')}
            value={value || ''}
            onChange={onChange}
            {...fieldProps}
          />
        )}
      />

      {errors.phone_number && errors.phone_number.message && <Hint error text={t(errors.phone_number.message)} />}

      { isCodeView && (
        <Controller
          name="phone_verification_code"
          control={control}
          render={({ field: { ref, ...fieldProps } }) => (
            <InputAction
              actionActive
              onClick={() => setIsCodeView(false)}
              actionTitle={t('change_number')}
              ref={ref}
              error={!!errors.phone_verification_code}
              placeholder={t('enter_code')}
              label={t('confirmation_code')}
              {...fieldProps}
            />
          )}
        />
      )}
      { isCodeView && errors.phone_verification_code && errors.phone_verification_code.message && <Hint error text={t(errors.phone_verification_code.message)} />}

      <InputActionSelect
        actionTitle={t('send code')}
        timer={timer}
        startTimer={setTimer}
        changeInput={setIsCodeView}
        verifyPhone={verificationPhone}
        sendSmsType={SMS_TYPES}
        setSelectSmsType={setSelectSmsType}
      />
      {errors.send_code && errors.send_code.message && <Hint error text={t(errors.send_code.message)} />}

      <Controller
        name="partner"
        control={control}
        render={({ field: { ref, ...fieldProps } }) => (
          <InputText
            ref={ref}
            error={!!errors.partner}
            placeholder={t('partner_id_optional')}
            label="Partner ID"
            disabled={disabledPartnerInput}
            {...fieldProps}
          />
        )}
      />
      {errors.partner && errors.partner.message && <Hint error text={t(errors.partner.message)} />}

      <Button type="submit" loading={loading} disabled={loading} background="green">{t('go_to_dashboard')}</Button>

      <Hint
        hintOpen
        position="bottom-start"
        text={t('code_not_arrived')}
        hintText={(
          <div>
            {t('check_phone_connection')}   <br />
            {t('verify_phone_number')}+{regionCode}{form.getValues().phone_number} <br /> <br />
            {t('whatsapp_sms_delay')} <br />
            {t('contact_support_after_15_min')}<a href="mailto:support@league.broker">support@league.broker</a>

          </div>
        )}
      />
    </form>
  );
};

export default PersonalDataForm;
