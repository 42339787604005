import {
  useRef, useState, useEffect,
} from 'react';
import { join } from 'src/shared/libs/helpers/helper.lib';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowSvg } from 'src/shared/assets/images/arrow.svg';
import InputText from 'src/shared/ui/input/input-text/input-text';

import './input-select.scss';

export interface ISelectProps {
  children: React.ReactNode[];
  isActiveSearch: boolean;
  searchValue?: string;
  isSelectValue?: any
  isSearch?: (value: string) => void;
  onChangeInput: (event: any) => void;
  placeholder: string;
  inputValue: string;
  onClick?: () => void
  onClickAll?: () => void
  inputType?: string
}

const InputSelect = (props: ISelectProps) => {
  const {
    children,
    isActiveSearch,
    searchValue,
    isSearch,
    onClick,
    isSelectValue,
    inputValue,
    onChangeInput,
    placeholder,
    onClickAll,
    inputType,
  } = props;
  const { t } = useTranslation();
  const selectContainerRef = useRef<HTMLDivElement>(null);
  const [selectedValue, setSelectedValue] = useState(children[0]);
  const [isActiveSelection, setIsActiveSelection] = useState(false);
  const [search, setSearch] = useState('');

  const setSelectedValueLocal = (value: typeof children[0]) => {
    setIsActiveSelection(false);
    setSelectedValue(value);
  };

  useEffect(() => {
    const checkClickOutsideHandler = (e: MouseEvent) => {
      e.stopPropagation();

      if (!selectContainerRef.current?.contains(e.target as Node)
        && isActiveSelection) {
        setIsActiveSelection(false);
      }
    };
    window.addEventListener('click', checkClickOutsideHandler);

    return () => {
      window.removeEventListener('click', checkClickOutsideHandler);
    };
  }, [isActiveSelection]);

  return (
    <div
      ref={selectContainerRef}
      className={join('select', isActiveSelection && 'select_active')}
      role="button"
      tabIndex={-1}
      onKeyDown={onClick}
      onClick={onClick}
    >
      <div className="selected-item">
        <input type={inputType || 'text'} value={inputValue} onChange={(event) => onChangeInput(event.target.value)} placeholder={placeholder} />
        <span role="button" tabIndex={0} onKeyDown={onClickAll} onClick={onClickAll} className="all-action">{t('all')}</span>
        <div
          className="item-content-select"
          role="button"
          tabIndex={0}
          onKeyDown={() => setIsActiveSelection(!isActiveSelection)}
          onClick={() => setIsActiveSelection(!isActiveSelection)}
        >
          {isSelectValue || selectedValue}
        </div>
        <ArrowSvg onClick={() => setIsActiveSelection(!isActiveSelection)} />
      </div>

      <div className="search-container">
        {
          isActiveSearch
            && (
              <div className="search-form">
                <InputText
                  value={searchValue || search}
                  placeholder={t('currency_name')}
                  onChange={isSearch || setSearch}
                />
              </div>
            )
        }

        <div className="search-items">
          { children.map((e, index) => (
            <div
              key={index}
              className="item-content"
              role="button"
              tabIndex={0}
              onKeyDown={() => setSelectedValueLocal(e)}
              onClick={() => setSelectedValueLocal(e)}
            >{e}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default InputSelect;
