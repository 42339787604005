import { useState } from 'react';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';

import Hint from 'src/shared/ui/help/hint';
import Button from 'src/shared/ui/button/button/button';
import useAlert from 'src/shared/libs/hooks/use-alert';
import AuthService, { RegistrationStepTwo } from 'src/services/auth.service';
import InputAction from '../../components/inputs-auth/input-action';
import { setCodeEmail } from '../../registration/model/slice';
import { selectRegistration } from '../../registration/model/selectors';

import styles from './verification-form.module.scss';

const schema = yup.object().shape({
  email_verification_code: yup
    .string()
    .required('field required'),
});

type CodeEmail = {
  email_verification_code: string
}

const VerificationForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { setAlertMessage } = useAlert();
  const { email } = useSelector(selectRegistration);

  const [loading, setLoading] = useState(false);
  const form = useForm<CodeEmail>({
    defaultValues: {
      email_verification_code: '',
    },
    resolver: yupResolver(schema),
  });

  const { control, handleSubmit, formState } = form;
  const { errors } = formState;

  const saveData = (codeEmail: number) => {
    dispatch(setCodeEmail(codeEmail));
  };

  const handleSendCodeEmail = async () => {
    const result = await AuthService.sendCodeEmail(email);

    if (result.data) {
      setAlertMessage('request_successfully_sent', 'success');
    } else if (result instanceof AxiosError && result.response) {
      if (result.response.data.detail === 'TOO_MANY_REQUESTS') {
        setAlertMessage('too many attempts 2', 'error');
      } else {
        setAlertMessage('failed_to_send_code_to_email', 'error');
      }
    } else {
      setAlertMessage('internal error', 'error');
    }
  };

  const handleRegistrationStepTwo = async (codeEmail: number) => {
    setLoading(true);

    const stepTwo: RegistrationStepTwo = {
      email: email || '',
      email_verification_code: codeEmail,
    };

    const result = await AuthService.registrationStepTwo(stepTwo);

    if (result instanceof AxiosError && result.response) {
      if (result.response.data.detail === 'EMAIL_EXISTS') {
        setAlertMessage('email_taken', 'error');
        setLoading(false);
      } else if (result.response.data.detail === 'INVALID_VERIFICATION_CODE') {
        setLoading(false);
        form.setError('email_verification_code', {
          type: 'manual',
          message: t('incorrect_code'),
        });
      } else {
        setAlertMessage('internal error', 'error');
        form.setError('email_verification_code', {
          type: 'manual',
          message: '',
        });
        setLoading(false);
      }
    } else {
      navigate('/auth/personal-data');
    }
  };

  const onSubmit = (data: CodeEmail) => {
    handleRegistrationStepTwo(Number(data.email_verification_code));
    saveData(Number(data.email_verification_code));
  };

  return (
    <form className={styles.verificationForm} onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="email_verification_code"
        control={control}
        render={({ field: { ref, ...fieldProps } }) => (
          <InputAction
            ref={ref}
            error={!!errors.email_verification_code}
            placeholder={t('enter_code')}
            label={t('confirmation_code')}
            actionTitle={t('send code')}
            type="number"
            actionActive
            onClick={handleSendCodeEmail}
            {...fieldProps}
          />
        )}
      />
      {errors.email_verification_code && errors.email_verification_code.message && <Hint error text={t(errors.email_verification_code.message)} />}

      <Button disabled={loading} loading={loading} type="submit" background="green">{t('сontinue')}</Button>
      <Hint
        hintOpen
        position="bottom-start"
        text={t('code_not_arrived')}
        hintText={(
          <div>
            {t('check_spam_folder')}
            <br />
            {t('check_email_box')} {email}
            <br />
            <br />
            {t('check_support')} <a href="mailto:support@league.broker">support@league.broker</a>
          </div>
        )}
      />
    </form>
  );
};

export default VerificationForm;
