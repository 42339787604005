import { ETradingType } from 'src/shared/types/global-types';
import { memo } from 'react';
import styles from './balance-type.module.scss';

interface IBalanceTypeProps {
type: string | number
}

const BalanceType = ({ type }: IBalanceTypeProps) => {
  let typeStyle;
  let charType;

  if (type === ETradingType.spot || type === 'SPOT') {
    typeStyle = styles.spot;
    charType = 'S';
  } else if (type === ETradingType.futures || type === 'FUTURES') {
    typeStyle = styles.futures;
    charType = 'F';
  } else if (type === ETradingType.unified || type === 'UNIFIED') {
    typeStyle = styles.unified;
    charType = 'U';
  }

  return (
    <div className={typeStyle}>{charType}</div>
  );
};

export default memo(BalanceType);
