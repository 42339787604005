import { WithdrawalStatus } from 'src/widgets/transfer-history/model/types';

const statusTranslations: Record<WithdrawalStatus, string> = {
  PROCESSED: 'processed',
  TRANSFERRED: 'transferred',
  CREATED: 'created',
  REJECTED: 'rejected',
};

export const translateStatus = (status: WithdrawalStatus): string => {
  const unknownStatus = 'unknown';

  if (typeof status !== 'string') return unknownStatus;

  return statusTranslations[status] || unknownStatus;
};
