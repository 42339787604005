import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'src/app/store/store';
import { authChangeLeverage } from 'src/pages/settings/model/slice';
import { selectTradeSettings } from 'src/pages/settings/model/selectors';
import { PartialTradingSettings } from 'src/pages/settings/model/types';
import { fetchChangeTradeSettings } from 'src/pages/settings/model/thunks';

import Toogler from 'src/shared/ui/toogler/toogler';
import useAlert from 'src/shared/libs/hooks/use-alert';
import InputForm from 'src/shared/ui/input/input-form/input-form';
import './auto-changes-leverages.scss';

const AutoChangesLeverages = () => {
  const dispatch = useAppDispatch();
  const { setAlertMessage } = useAlert();
  const { t } = useTranslation();
  const { settings } = useSelector(selectTradeSettings);

  const handleAutoChangeLeverage = async () => {
    if (!settings) return;

    try {
      const params: PartialTradingSettings = {
        auto_change_leverage: !settings.auto_change_leverage,
      };
      const { payload } = await dispatch(fetchChangeTradeSettings(params));

      if (payload === true) {
        dispatch(authChangeLeverage(!settings.auto_change_leverage));
      } else if (payload === 'TOO_MANY_REQUESTS') {
        setAlertMessage('too many attempts 15', 'warning');
      } else if (typeof payload === 'string') {
        setAlertMessage(payload, 'warning');
      } else {
        setAlertMessage('internal error', 'error');
      }
    } catch (error) {
      console.debug(error);
      const errorMessage = typeof error === 'string' ? error : JSON.stringify(error);
      setAlertMessage(errorMessage, 'error');
    }
  };

  return (
    <div className="auto-changes-leverages">
      <InputForm
        comment={t('leverage_auto_reset_info')}
      >
        <Toogler
          isActive={!!settings?.auto_change_leverage}
          onChange={handleAutoChangeLeverage}
          labelContent={t('auto_leverage_adjustment')}
        />
      </InputForm>
    </div>
  );
};

export default AutoChangesLeverages;
