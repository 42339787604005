import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useChangePageTitle } from 'src/shared/libs/hooks/use-change-page-title';
import { ReactComponent as HamsterIconSvg } from 'src/shared/assets/images/hamster-404.svg';

import Button from 'src/shared/ui/button/button/button';
import './error-page.scss';

const pageTitle = 'page_not_found';

const Code404Page = () => {
  useChangePageTitle(pageTitle);

  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="error-page">
      <div className="error-page-wrapper">
        <HamsterIconSvg />
        <div className="wrapper-info">
          <h1>{t('page_not_found')}</h1>
          <Button onClick={() => navigate('/trading')} background="orange">{t('to_home')}</Button>
        </div>
      </div>
    </div>

  );
};

export default Code404Page;
