import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectHiddenBalance } from 'src/pages/settings/model/selectors';
import { HIDDEN_BALANCES } from 'src/shared/constants/constants';
import Toogler from 'src/shared/ui/toogler/toogler';
import UsdtIcon from 'src/shared/assets/images/usdt.svg';
import styles from './bonus-my-bonuses.module.scss';

const BonusMyBonuses = () => {
  const { t } = useTranslation();
  const [autoConvert, setAutoConvert] = useState(true);
  const balanceVisible = useSelector(selectHiddenBalance);

  return (
    <section className={styles.container}>
      <div className={styles.title}>{t('my bonuses')}</div>
      <div className={styles.balance}>
        {t('current bonus balance')}
        <span>{!balanceVisible ? `${parseFloat('0').toLocaleString().replace(',', '.')} USDT` : HIDDEN_BALANCES}</span>
      </div>
      <div className={styles.currencies}>
        <div className={styles.currenciesTitle}>{t('coins in balance')}:</div>
        <div className={styles.currenciesAsset}>
          <img src={UsdtIcon} alt="asset" />
          USDT
          <span>{!balanceVisible ? parseFloat('0').toLocaleString().replace(',', '.') : HIDDEN_BALANCES}</span>
        </div>
      </div>
      <Toogler isBlocked isActive={!autoConvert} onChange={setAutoConvert} labelContent={t('auto convert')} />
      <p className={styles.info}>{t('conversion within a day after trading day salary')}</p>
    </section>
  );
};
export default BonusMyBonuses;
