import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Modal } from '@mantine/core';
import {
  useCallback, useState,
} from 'react';
import { EStatus, Nullable } from 'src/shared/types/global-types';
import { Exchange } from 'src/app/store/slices/exchanges/types';
import { selectSubAccounts } from 'src/app/store/slices/sub-accounts/selectors';
import Select from 'src/shared/ui/select/select';
import Button from 'src/shared/ui/button/button/button';
import InputText from 'src/shared/ui/input/input-text/input-text';
import InputForm from 'src/shared/ui/input/input-form/input-form';
import Hint from '../../help/hint';
import SelectSkeleton from '../../skeleton/select-skeleton/select-skeleton';
import styles from './modal-add-subaacount.module.scss';

const maxLengthSubAccountName = 16;

interface IModalAddSubAccountProps {
    opened: boolean;
    closeModal: () => void;
    addSubAccount: () => void;
    selectExchange: (exchange: Exchange) => void;
    currentExchange: Nullable<Exchange>;
    inputOnChange: (value: string) => void;
    inputValue: string;
    exchanges: Nullable<Exchange[]>;
    createStatus: EStatus;
    errorMessage: {state: boolean, message: string}
}

const ModalAddSubAccount = (props: IModalAddSubAccountProps) => {
  const [checked, setChecked] = useState(false);
  const { t } = useTranslation();

  const {
    opened, closeModal, exchanges, addSubAccount, inputValue, inputOnChange, selectExchange, createStatus, errorMessage,
    currentExchange,
  } = props;

  const subAccounts = useSelector(selectSubAccounts);

  const checkSubAccountNameExist = (name: string) => {
    if (currentExchange && subAccounts) {
      const subAccountsName = subAccounts.filter((subAccount) => subAccount.exchange_id === currentExchange.id).map((subAccount) => subAccount.user_name);

      return subAccountsName.includes(name.trim());
    }
    return false;
  };

  const disabledSubmit = useCallback(() => {
    if (inputValue === '') {
      return true;
    } if (createStatus === EStatus.loading) {
      return true;
    } if (inputValue.length > 16) {
      return true;
    } if (!checked) {
      return true;
    } if (checkSubAccountNameExist(inputValue)) {
      return true;
    }
    return false;
  }, [inputValue, createStatus, currentExchange, checked]);

  const loading = createStatus === EStatus.loading;
  const disabled = disabledSubmit();
  const bgColor = disabledSubmit() ? 'black' : 'green';

  return (
    (
      <Modal
        radius={16}
        size="sm"
        opened={opened}
        onClose={closeModal}
        title={t('open_new_account')}
        className="modal-custom-overflow"
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
      >
        <div className={styles.addSubAccount}>
          <InputForm title={t('exchange')}>
            {exchanges ? (
              <Select isActiveSearch={false}>
                {
                  exchanges.map((exchange, index) => (
                    <div
                      key={exchange.name}
                      className={styles.selectItem}
                      role="button"
                      tabIndex={index}
                      onClick={() => selectExchange(exchange)}
                      onKeyDown={() => selectExchange(exchange)}
                    >
                      <img src={exchange.image} alt={exchange.image} />
                      <span className="short-name">{exchange.name}</span>
                    </div>
                  ))
                }
              </Select>
            ) : <SelectSkeleton /> }
          </InputForm>

          <InputForm title={t('account_name')}>
            <InputText
              value={inputValue}
              placeholder="Binance"
              onChange={inputOnChange}
              className={classNames(styles.inputBorderColor, { [styles.isEmpty]: !inputValue.length || errorMessage.state })}
            />
            <span className={classNames(styles.valueLength, { [styles.maxLength]: inputValue.length > maxLengthSubAccountName })}>{t('max_length_16')}</span>
          </InputForm>

          {checkSubAccountNameExist(inputValue) && <Hint error text={t('name_taken')} />}

          <div className={styles.modalPolicy}>
            <input
              className={styles.checkbox}
              type="checkbox"
              checked={checked}
              onChange={() => setChecked((prev) => !prev)}
            />
            <p>
              {t('account_deletion_not_possible')}
              {t('account_creation_limit')}
            </p>
          </div>

          <div className={styles.modalActions}>
            <Button loading={loading} disabled={disabled} onClick={addSubAccount} max-width="175px" background={bgColor}>{t('create_account')}</Button>
            <Button onClick={closeModal} maxWidth="100px" background="gray">{t('cancel')}</Button>
          </div>
        </div>
      </Modal>
    )
  );
};

export default ModalAddSubAccount;
