import { Loader } from '@mantine/core';
import { Divider } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'src/shared/libs/hooks/use-theme';
import { selectKyc } from 'src/app/store/slices/kyc/selectors';
import { selectUser } from 'src/app/store/slices/user/selectors';
import { useAppDispatch } from 'src/app/store/store';
import { fetchVerifyKycUrl } from 'src/app/store/slices/kyc/thunks';
import { EKycStatus, EThemeMode } from 'src/app/store/slices/user/types';
import { EStatus } from 'src/shared/types/global-types';

import Button from 'src/shared/ui/button/button/button';
import useAlert from 'src/shared/libs/hooks/use-alert';

import styles from './kyc-modal-view.module.scss';

interface IKycModalViewProps {
  closeModal: () => void
}

const KycModaLView = ({ closeModal }: IKycModalViewProps) => {
  const dispatch = useAppDispatch();
  const { setAlertMessage } = useAlert();
  const { t } = useTranslation();
  const { theme } = useTheme();
  const { status } = useSelector(selectKyc);
  const { kycStatus } = useSelector(selectUser);

  const fetchVerificationKYC = async () => {
    try {
      const { payload } = await dispatch(fetchVerifyKycUrl());

      if (typeof payload === 'string' && payload.startsWith('http')) {
        window.open(payload, '_blank');
        closeModal();
      } else if (typeof payload === 'string' && !payload.startsWith('http')) {
        setAlertMessage(payload, 'error');
      }
    } catch (error) {
      console.debug(t('kyc_verification_error'), error);
      setAlertMessage(t('kyc_verification_error'), 'error');
    }
  };

  return (
    <div className={styles.container}>
      <p className={styles.title}>{t('basic_verification_required')}</p>
      <Button loading={status === EStatus.loading} disabled={kycStatus !== EKycStatus.CREATED} onClick={fetchVerificationKYC} background="green">
        { status === EStatus.loading ? <Loader size={16} color="white" /> : t('complete_verification')}
      </Button>
      <p className={styles.info}>
        {t('kyc_mandatory')}
        <br />
        <br />
        {t('kyc_risks_reduction')}
      </p>
      <Divider color={theme === EThemeMode.LIGHT ? '#E8E8E8' : '#3A3A3A'} />
      <p className={styles.info}>
        {t('services not provided to residents of certain territories')}
      </p>

    </div>
  );
};

export default KycModaLView;
