import classNames from 'classnames';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'src/app/store/store';
import { ReactComponent as LogotypeIconSvg } from 'src/shared/assets/images/header/logotype.svg';

import InformBox from 'src/entities/inform-box/inform-box';
import LanguageSelection from 'src/entities/language-selection/language-selection';
import BodyFooter from '../body-footer/body-footer';

import { getSlideText } from '../../lib/helpers/check-password-strength';
import { setSlidesPage } from '../../login/model/slice';
import { selectSlidesPage } from '../../login/model/selectors';
import styles from './auth-body.module.scss';

interface IAuthBodyProps {
    titleUp: string;
    titleDown: string;
    description: string;
    informList: string[];
    informTitle: string;
    activeSlide: 'first'| 'second'| 'third' | 'fourth';
    dynamicItems?: boolean;
}

const AuthBody = memo(({
  titleUp, titleDown, description, informList, informTitle, activeSlide, dynamicItems = false,
}: IAuthBodyProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const slidePage = useSelector(selectSlidesPage);

  const handleSetSlide = (page: number) => {
    dispatch(setSlidesPage(page));
  };

  return (
    <div className={styles.authBody}>
      <div className={styles.logotype}>
        <LogotypeIconSvg />
        <LanguageSelection bgColor="#131313" />
      </div>

      <div>
        <h1 className={styles.title}>{t(titleUp)}<br /> <span>{t(titleDown)}</span></h1>
        <p className={styles.descroption}>
          {t(description)}
        </p>

        <div className={classNames(styles.slider, styles[getSlideText(slidePage)])}>
          <button onClick={() => handleSetSlide(0)} />
          <button onClick={() => handleSetSlide(1)} />
          <button onClick={() => handleSetSlide(2)} />
          <button onClick={() => handleSetSlide(3)} />
        </div>

        <InformBox dynamicItems={dynamicItems} items={informList} title={t(informTitle)} />
      </div>

      <BodyFooter />
    </div>
  );
});

export default AuthBody;
