import { useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { Nullable } from 'src/shared/types/global-types';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'src/app/store/store';
import { selectSubAccountSettings } from 'src/app/store/slices/sub-accounts/selectors';

import Toogler from 'src/shared/ui/toogler/toogler';
import useAlert from 'src/shared/libs/hooks/use-alert';
import InputForm from 'src/shared/ui/input/input-form/input-form';

import { fetchAutoCalculate } from '../../model/thunks';
import './auto-leverages.scss';

const responseMessage = 'LEVERAGES_TASK_IN_PROGRESS';

interface IAutoLeveragesProps {
    subAccountId: Nullable<number>
    openCalculator: boolean
    setAutoCalculator: (state: boolean) => void
}

const AutoLeverages = ({ subAccountId, openCalculator, setAutoCalculator } : IAutoLeveragesProps) => {
  const dispatch = useAppDispatch();
  const { setAlertMessage } = useAlert();
  const { t } = useTranslation();

  const subAccountSettings = useSelector(selectSubAccountSettings);

  const handleOnChangeAutoCalculator = async () => {
    if (!subAccountId) return;

    const { payload } = await dispatch(fetchAutoCalculate(subAccountId));

    if (payload === responseMessage) {
      setAlertMessage(t('wait_for_leverage_calculation'), 'warning');
      return;
    }

    if (payload !== undefined) setAutoCalculator(payload);
  };

  useLayoutEffect(() => {
    if (subAccountSettings) {
      setAutoCalculator(subAccountSettings.auto_calculate_leverage);
    }
  }, [subAccountSettings]);

  return (
    <div className="auto-leverages">
      <InputForm
        comment={t('leverage_auto_adjust')}
      >
        <Toogler
          isActive={openCalculator}
          onChange={handleOnChangeAutoCalculator}
          labelContent={t('auto_calculator')}
        />
      </InputForm>
    </div>
  );
};

export default AutoLeverages;
