import { useChangePageTitle } from 'src/shared/libs/hooks/use-change-page-title';

import Header from './components/header/header';
import Filters from './components/filters/filters';
import ReferralsTable from './components/referrals-table/referrals-table';
import ReferralsFooter from './components/referrals-footer/referrals-footer';
import ReferralsStatistics from './components/referrals-statistics/referrals-statistics';
import styles from './referrals.module.scss';

const pageTitle = 'referrals';

const ReferralsPage = () => {
  useChangePageTitle(pageTitle);

  return (
    <div className={styles.referralsPage}>
      <Header />

      <div className={styles.content}>
        <Filters />
        <div className={styles.container}>
          <div className={styles.table}>
            <ReferralsTable />
            <ReferralsFooter />
          </div>
          <ReferralsStatistics />
        </div>
      </div>
    </div>
  );
};

export default ReferralsPage;
