import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'src/app/store/store';
import { EStatus, Nullable } from 'src/shared/types/global-types';
import { selectHiddenBalance } from 'src/pages/settings/model/selectors';
import { selectBalances } from 'src/app/store/slices/balance/selectors';
import Select from 'src/shared/ui/select/select';
import Button from 'src/shared/ui/button/button/button';
import InputForm from 'src/shared/ui/input/input-form/input-form';
import InputText from 'src/shared/ui/input/input-text/input-text';
import useAlert from 'src/shared/libs/hooks/use-alert';

import { selectLeverages } from '../../model/selectors';
import { addCalculationToQueue } from '../../model/slice';
import { CALCULATION_LEVERAGE, CALCULATION_LEVERAGE_ERROR_MSG } from '../../constants';
import { CalclulationLeverage, CalclulatorLeverageQueue } from '../../model/types';
import { fetchCalculate, SetCalculateParams } from '../../model/thunks';
import './auto-leverages-calculator.scss';

interface IAutoLeveragesCalculatorProps {
    subAccountId: Nullable<number>
    openAutoCalculator: boolean
}

const AutoLeveragesCalculator = ({ subAccountId, openAutoCalculator }: IAutoLeveragesCalculatorProps) => {
  const dispatch = useAppDispatch();
  const { setAlertMessage } = useAlert();
  const { t } = useTranslation();

  const [calculatorValue, setCalculatorValue] = useState('');
  const [calculationLeverage, setCalculationLeverage] = useState<Nullable<CalclulationLeverage>>(null);

  const balanceVisible = useSelector(selectHiddenBalance);
  const { balances } = useSelector(selectBalances);
  const { leveragesCalculationQueue, statusCalculate } = useSelector(selectLeverages);

  const selectCalculationLeverage = calculationLeverage?.name || CALCULATION_LEVERAGE[0].name;

  const checkSubAccountCalculationStatus = () => leveragesCalculationQueue.some((task) => task.sub_account_id === subAccountId);

  const handleSetCalculationLeverage = (item: CalclulationLeverage) => {
    setCalculationLeverage(item);

    if (item.type === 'FROM_DEPOSIT') {
      if (balances && subAccountId) {
        const findBalance = balances.sub_accounts.find((balance) => balance.sub_account_id === subAccountId);
        const value = findBalance ? findBalance.futures : '0';

        setCalculatorValue(value);
      }
    } else if (item.type === 'FROM_WORKING_VOLUME') {
      setCalculatorValue('0');
    } else {
      console.debug('This type is not supported');
    }
  };

  const handleOnCalculate = async () => {
    if (!subAccountId || !calculationLeverage) return;

    if (calculationLeverage.type === 'FROM_WORKING_VOLUME' && calculatorValue.trim() === '') {
      setAlertMessage(t('enter_deposit_amount'), 'warning');
      return;
    }
    if (Number(calculatorValue) < 1) {
      setAlertMessage(t('min_value_1_usdt'), 'warning');
      return;
    }
    if (Number(calculatorValue) > 1_000_000_000) {
      setAlertMessage(t('max_value_1_billion_usdt'), 'warning');
      return;
    }

    const params: SetCalculateParams = {
      params: {
        deposit: Number(calculatorValue),
        type: calculationLeverage.type,
      },
      subAccountId,
    };

    const response = await dispatch(fetchCalculate(params));
    const payload = unwrapResult(response);

    const calculationTask: CalclulatorLeverageQueue = {
      sub_account_id: subAccountId,
      status: 'STARTED',
    };

    if (payload === true) {
      dispatch(addCalculationToQueue(calculationTask));
      setAlertMessage(t(CALCULATION_LEVERAGE_ERROR_MSG.true.msg), 'success');
    }

    if (typeof payload === 'object' && 'detail' in payload) {
      const responseMessage = CALCULATION_LEVERAGE_ERROR_MSG[payload.detail] || CALCULATION_LEVERAGE_ERROR_MSG.default;
      setAlertMessage(t(responseMessage.msg), responseMessage.type);
      dispatch(addCalculationToQueue(calculationTask));
    }
  };

  useEffect(() => {
    if (subAccountId) {
      setCalculationLeverage(CALCULATION_LEVERAGE[0]);
    }
  }, [subAccountId]);

  useEffect(() => {
    if (balances) {
      const findBalance = balances.sub_accounts.find((balance) => balance.sub_account_id === subAccountId);

      if (findBalance) setCalculatorValue(findBalance.futures);
    }
  }, [subAccountId, balances]);

  return openAutoCalculator ? (
    <div className="auto-leverages-calculator">
      <InputForm title={t('leverage_calculation_from')}>
        <Select
          isActiveSearch={false}
          isSelectValue={(
            <span
              className="marge-item"
            >{t(selectCalculationLeverage)}
            </span>
          )}
        >
          { CALCULATION_LEVERAGE.map((item) => (
            <span
              className="marge-item"
              key={item.type}
              role="button"
              tabIndex={0}
              onClick={() => handleSetCalculationLeverage(item)}
              onKeyDown={() => handleSetCalculationLeverage(item)}
            >{t(item.name)}
            </span>
          ))}
        </Select>
      </InputForm>

      <InputForm title={t('amount')}>
        <InputText
          inputType={!balanceVisible ? 'number' : 'password'}
          value={calculatorValue}
          placeholder={t('enter_value')}
          onChange={setCalculatorValue}
        />
      </InputForm>

      <Button
        disabled={statusCalculate === EStatus.loading || checkSubAccountCalculationStatus()}
        loading={statusCalculate === EStatus.loading}
        onClick={handleOnCalculate}
        background={checkSubAccountCalculationStatus() ? 'orange' : 'green'}
      >
        { checkSubAccountCalculationStatus() ? `${t('in_process')}...` : t('calculate_leverage')}
      </Button>
    </div>
  ) : null;
};

export default AutoLeveragesCalculator;
