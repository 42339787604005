import { useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'src/app/store/store';
import { useTranslation } from 'react-i18next';
import { AuthLayout } from 'src/layouts';
import { useChangePageTitle } from 'src/shared/libs/hooks/use-change-page-title';
import AbstractIconsSvg from 'src/shared/assets/images/auth/abstract.svg';
import LanguageSelection from 'src/entities/language-selection/language-selection';
import informList from '../registration/constants/index';
import AuthBody from '../components/auth-body/auth-body';
import AuthTitle from '../components/auth-title/auth-title';
import AuthFooter from '../components/auth-footer/auth-footer';
import ForgotPasswordForm from './components/forgot-password-form/forgot-password-form';
import { selectForgotPassword } from './model/selectors';
import { setStep } from './model/slice';

import styles from './forgot-password.module.scss';

const pageTitle = 'password_recovery';
const steps = ['first', 'second', 'third'];

const ForgotPasswordPage = () => {
  useChangePageTitle(pageTitle);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { step } = useSelector(selectForgotPassword);

  useLayoutEffect(() => {
    dispatch(setStep(0));
  }, []);

  return (
    <AuthLayout page="">
      <div className={styles.container}>
        <section className={styles.bodyWrapper}>
          <AuthBody
            activeSlide="first"
            titleUp="security"
            titleDown="security_first"
            description="simple_rules_for_security"
            informList={informList}
            informTitle="password_creation_tips"
            dynamicItems
          />
        </section>

        <section className={styles.formWrapper}>
          <div className={styles.formContainer}>
            <LanguageSelection bgColor="#131313" />
            <AuthTitle visible step={steps[step]}>{t('recovery')}<br />{t('rec_pass')}</AuthTitle>
            <ForgotPasswordForm />
          </div>

          <AuthFooter />
        </section>

        <img className={styles.abstract} src={AbstractIconsSvg} alt="abstract" />
      </div>
    </AuthLayout>
  );
};

export default ForgotPasswordPage;
