import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Pagination } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'src/app/store/store';
import { HIDDEN_BALANCES } from 'src/shared/constants/constants';
import { selectHiddenBalance } from 'src/pages/settings/model/selectors';
import { setReferralsFilters } from '../../model/slice';
import { selectReferralsBonuses, selectReferralsBonusesFilters, selectReferralsBonusesStatistics } from '../../model/selectors';
import { fetchReferralsBonusesStatistics, ReferralsBonusesParams } from '../../model/thunk';
import styles from './referrals-footer.module.scss';

const ReferralsFooter = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const referralsBonuses = useSelector(selectReferralsBonuses);
  const filters = useSelector(selectReferralsBonusesFilters);
  const balanceVisible = useSelector(selectHiddenBalance);

  const referralsBonusesStatistics = useSelector(selectReferralsBonusesStatistics);
  const isPagination = referralsBonuses?.items && referralsBonuses.items.length > 0;

  const handleSetPage = (page: number) => {
    if (page === filters.page) return;

    dispatch(setReferralsFilters({
      ...filters,
      page,
    }));
  };

  useEffect(() => {
    if (filters.page !== 1) return;

    const params: Partial<ReferralsBonusesParams> = {};

    if (filters.exchange) params.exchange_id = filters.exchange.id;
    if (filters.tradingType) params.trading_type = filters.tradingType;
    if (filters.userId) params.user_id = filters.userId;

    if (filters.dateRange.length > 1 && filters.dateRange[1] !== null) {
      const [from, to] = filters.dateRange;
      params.from_date = from;
      params.to_date = to;
    }
    dispatch(fetchReferralsBonusesStatistics(params));
  }, [filters]);

  return (
    <div className={styles.footer}>
      {isPagination && (
        <Pagination
          value={filters.page}
          total={referralsBonuses?.meta?.total_pages || 1}
          color="gray"
          size="sm"
          radius="md"
          withEdges
          onChange={handleSetPage}
          classNames={{
            root: styles.paginationRoot,
            control: styles.paginationControl,
          }}
        />
      )}

      {referralsBonuses && referralsBonuses?.items.length > 0 && (
        <div className={styles.statistics}>
          <div className={styles.item}>
            {t('users')}:
            <p>{referralsBonusesStatistics?.number_users || 0}</p>
          </div>

          <div className={styles.divider}>|</div>

          <div className={styles.item}>
            {t('cumulative_quantity')}:
            {
              !balanceVisible ? <p>{referralsBonusesStatistics?.cummulative_quantity || 0} <span className={styles.symbol}>$</span></p> : <span className={styles.hidden}>{HIDDEN_BALANCES}</span>
            }
          </div>

          <div className={styles.divider}>|</div>

          <div className={styles.item}>
            {t('commission')}:
            {
              !balanceVisible ? <p>{referralsBonusesStatistics?.commission || 0} <span className={styles.symbol}>$</span></p> : <span className={styles.hidden}>{HIDDEN_BALANCES}</span>
            }
          </div>

          <div className={styles.divider}>|</div>

          <div className={styles.item}>
            {t('widget_profit')}:
            {
              !balanceVisible ? <p>{referralsBonusesStatistics?.bonus || 0} <span className={styles.symbol}>$</span></p> : <span className={styles.hidden}>{HIDDEN_BALANCES}</span>
            }
          </div>
        </div>
      )}
    </div>
  );
};

export default ReferralsFooter;
