import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LEGAL_INFORMATION, USER_AGREEMENT, RESTRICTED_REGIONS } from 'src/shared/constants/app-links';
import styles from './body-footer.module.scss';

const BodyFooter = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.bodyFooter}>
      <nav>
        <ul>
          <li><Link to={USER_AGREEMENT} target="_blank">{t('terms of use of company products and services')}</Link></li>
          <li><Link to={LEGAL_INFORMATION} target="_blank">{t('legal_information')}</Link></li>
          <li><Link to={RESTRICTED_REGIONS} target="_blank">{t('restricted_regions')}</Link></li>
        </ul>
      </nav>

      <p className={styles.registration}>
        LOTSOFT INC. | 155755565
      </p>
    </div>
  );
};

export default BodyFooter;
