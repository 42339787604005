import React from 'react';
import { useTranslation } from 'react-i18next';

interface IVerificationIssuesProps {
    onClick: () => void
}

const VerificationIssues = ({ onClick }: IVerificationIssuesProps) => {
  const { t } = useTranslation();
  return (
    <div>
      {t('email code not valid')}
      <br />
      - {t('email code expired')}
      <br />
      - {t('check email correctness')}
      <br />
      <br />
      {t('ga code not valid')}
      <br />
      - {t('sync time between devices')}
      <br />
      - {t('check account correctness')}
      <br />
      <br />
      {t('still not working contact support')} <button onClick={onClick}>Telegram</button>
    </div>
  );
};

export default VerificationIssues;
