import { memo } from 'react';
import className from 'classnames';
import { Modal } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { Exchange } from 'src/app/store/slices/exchanges/types';
import { EStatus, Nullable } from 'src/shared/types/global-types';
import { ReactComponent as LockIconSvg } from 'src/shared/assets/images/lock.svg';
import Button from 'src/shared/ui/button/button/button';
import InputText from 'src/shared/ui/input/input-text/input-text';
import InputForm from 'src/shared/ui/input/input-form/input-form';
import Hint from '../../help/hint';
import Toogler from '../../toogler/toogler';

import styles from './modal-edit-subaacount.module.scss';

const maxLengthSubAccountName = 16;

interface IModalEditSubAccountProps {
    opened: boolean;
    inputValue: string;
    exchange: Nullable<Exchange>;
    editStatus: EStatus;
    errorMessage: {state: boolean, message: string}
    isFavorite: boolean
    setIsFavorite: (value: boolean) => void
    closeModal: () => void;
    editSubAccount: () => void;
    inputOnChange: (value: string) => void;

}

const ModalEditSubAccount = memo((props: IModalEditSubAccountProps) => {
  const {
    opened, closeModal, exchange, isFavorite, setIsFavorite, editSubAccount, inputValue, inputOnChange, editStatus, errorMessage,
  } = props;
  const { t } = useTranslation();
  const loading = editStatus === EStatus.loading;
  const disabled = inputValue === '' || editStatus === EStatus.loading;
  const bgColor = inputValue === '' ? 'black' : 'green';

  return (
    (
      <Modal
        title={t('edit_subaccount')}
        radius={16}
        size="sm"
        opened={opened}
        onClose={closeModal}
        className="modal-custom-overflow"
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
      >
        <div className={styles.editSubAccount}>
          <InputForm title={t('exchange')}>
            {exchange && (
              <div className={styles.exchangeInput}>
                <img src={exchange.image} alt="exchange" />
                <span className={styles.shortName}>{exchange.name}</span>
                <span className={styles.shortIcon}><LockIconSvg /></span>

              </div>
            )}
          </InputForm>

          <InputForm title={t('account_name')}>
            <InputText
              value={inputValue}
              placeholder="Binance"
              onChange={inputOnChange}
            />
            <span className={className(styles.valueLength, { [styles.maxLength]: inputValue.length > maxLengthSubAccountName })}>{t('max_length_16')}</span>
          </InputForm>

          {inputValue === '' && <Hint error text={t('field cannot be empty')} />}
          {errorMessage.state && <Hint error text={errorMessage.message} />}

          <InputForm>
            <Toogler
              isActive={isFavorite}
              onChange={setIsFavorite}
              labelContent={t('make_primary')}
            />
          </InputForm>

          <div className={styles.modalActions}>
            <Button loading={loading} disabled={disabled} onClick={editSubAccount} max-width="175px" background={bgColor}>{t('save')}</Button>
            <Button onClick={closeModal} maxWidth="100px" background="gray">{t('cancel')}</Button>
          </div>
        </div>
      </Modal>
    )
  );
});

export default ModalEditSubAccount;
