import { useTranslation } from 'react-i18next';
import CopyIcon from 'src/shared/assets/icons/copy-icon/copy-icon';
import { useSelector } from 'react-redux';
import { selectUser } from 'src/app/store/slices/user/selectors';
import { Divider } from '@mantine/core';
import { useTheme } from 'src/shared/libs/hooks/use-theme';
import { EThemeMode } from 'src/app/store/slices/user/types';
import styles from './header.module.scss';

const partnerUrl = `${window.origin}?partner=`;

const Header = () => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const { user } = useSelector(selectUser);

  return (
    <div className={styles.header}>
      <div className={styles.headerWrapper}>
        <h1>{t('referral_program')}</h1>

        <div className={styles.sectionUserIdCopy}>
          <span className={styles.userIdTitle}>UserID</span>
          <div className={styles.userIdWrapper}>
            <div className={styles.userIdValuess}>{user?.user_id || t('no data')}</div>
            <CopyIcon value={`${partnerUrl}${user?.user_id}`} />
          </div>
        </div>
      </div>

      <Divider className={styles.divider} color={theme === EThemeMode.LIGHT ? '#E8E8E8' : '#3A3A3A'} />
    </div>
  );
};

export default Header;
