import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const useChangePageTitle = (newTitleKey: string) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    document.title = t(newTitleKey);

    const handleLanguageChange = () => {
      document.title = t(newTitleKey);
    };

    i18n.on('languageChanged', handleLanguageChange);
    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [newTitleKey, i18n, t]);
};
