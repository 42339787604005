import { Nullable, Token } from 'src/shared/types/global-types';

export const saveTokensLocalStorage = (data: Token): void => {
  try {
    if (data !== null && data !== undefined) {
      localStorage.setItem('accessToken', data.access_token);
    }
  } catch (error) {
    console.debug('Failed save tokens', error);
  }
};

export const getTokensFromLocalStorage = (): Nullable<Token> => {
  const accessToken = localStorage.getItem('accessToken');

  if (accessToken) {
    return {
      access_token: accessToken,
    };
  }

  return null;
};
