export const ACCESS_TYPE = [
  {
    id: '1',
    label: 'unlimited',
    comment: 'not recommended',
  },
  {
    id: '2',
    label: 'only to verified ip addresses',
    comment: '',
  },

] as const;

export const PERMISSIONS = [
  {
    type: 0,
    name: 'reading',
    comment: 'required',
    isActive: false,
  },
  {
    type: 1,
    name: 'spot trading',
    comment: '',
    isActive: true,
  },
  {
    type: 2,
    name: 'futures trading',
    comment: '',
    isActive: true,
  },
] as const;
