const informSlide4 = [
  'restore_account_access_to_confirm_identity',
  'for_support_to_identify_you',
];

export const ERROR_MSG_LIST: Record<string, string> = {
  INVALID_VERIFICATION_CODE: 'invalid_verification_code',
  PHONE_EXISTS: 'phone_exists',
  INVALID_IP: 'invalid_ip',
  STEP_EXPIRED: 'please_start_registration_from_first_step',
  INVALID_STEP: 'please_start_registration_from_first_step',
  TOO_MANY_REQUESTS: 'too many attempts 2',
};

export default informSlide4;
