import { useEffect, useRef, useState } from 'react';
import { Nullable } from 'src/shared/types/global-types';
import { BASE_URL } from 'src/shared/constants/constants';
import { socket } from 'src/shared/api/websocket.instance';
import { getTokensFromLocalStorage } from '../helpers/save-tokens';
import { useAuth } from './use-auth';

const useWebWorker = (url: string, interval: number) => {
  const { isAuth } = useAuth();
  const workerRef = useRef<Nullable<Worker>>(null);
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState<Nullable<string>>(null);

  const startWorker = () => {
    if (workerRef.current) {
      console.debug(
        '%c♻  Start worker',
        `background: #1CB47D;
         padding: 5px 10px;
         border-radius: 8px;
         font: 10px Inter-Regular, Arial;
         color: white;
         `,
      );
    }
  };

  const stopWorker = () => {
    if (workerRef.current) {
      workerRef.current.postMessage('stop');
      workerRef.current.terminate();
      workerRef.current = null;
      socket?.close();
    }
  };

  useEffect(() => {
    const token = getTokensFromLocalStorage();

    if (!isAuth || !token?.access_token) {
      return () => {
        setError('Unauthorized or missing token');
        setData(null);
      };
    }

    const worker: Worker = new Worker(new URL('../../config/worker.ts', import.meta.url));
    workerRef.current = worker;

    startWorker();

    worker.postMessage({
      url,
      interval,
      accessToken: token.access_token,
      baseUrl: BASE_URL,
    });

    worker.onmessage = (event) => {
      const { success, data, error } = event.data;

      if (success) {
        setData(data);
        setError(null);
      } else {
        setError(error || 'Unknown error');
        stopWorker();
      }
    };

    worker.onerror = (err) => {
      setError(`Worker error: ${err.message}`);
      stopWorker();
    };

    return () => {
      stopWorker();
    };
  }, [url, interval, isAuth]);

  return { data, error };
};

export default useWebWorker;
