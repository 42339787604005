import { AuthLayout } from 'src/layouts';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLayoutEffect } from 'react';
import { useAppDispatch } from 'src/app/store/store';
import { useChangePageTitle } from 'src/shared/libs/hooks/use-change-page-title';

import AbstractIconsSvg from 'src/shared/assets/images/auth/abstract.svg';
import LanguageSelection from 'src/entities/language-selection/language-selection';
import AuthBody from '../components/auth-body/auth-body';
import AuthTitle from '../components/auth-title/auth-title';
import AuthFooter from '../components/auth-footer/auth-footer';
import useCheckStep from '../lib/hooks/use-check-step';
import VerificationForm from './verification-form/verification-form';

import { informSlides, infromTitleSilde } from '../lib/helpers/constants';
import { selectRegistration } from '../registration/model/selectors';
import { selectSlidesPage } from '../login/model/selectors';
import { setSlidesPage } from '../login/model/slice';
import styles from './verification.module.scss';

const pageTitle = 'email_verification';

const VerificationPage = () => {
  useChangePageTitle(pageTitle);

  const { email } = useSelector(selectRegistration);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const slidePage = useSelector(selectSlidesPage);

  useCheckStep(email, '/auth/registration');

  useLayoutEffect(() => {
    dispatch(setSlidesPage(2));
  }, []);

  return (
    <AuthLayout page="">
      <div className={styles.container}>
        <section className={styles.bodyWrapper}>
          <AuthBody
            activeSlide="third"
            titleUp={t('use')}
            titleDown={t('secure_email')}
            description={t('recommend_separate_email')}
            informList={informSlides[slidePage]}
            informTitle={infromTitleSilde[slidePage]}
          />
        </section>

        <section className={styles.formWrapper}>
          <div className={styles.formContainer}>
            <LanguageSelection bgColor="#131313" />
            <AuthTitle visible step="second">{t('email_address_verification')}</AuthTitle>
            <p className={styles.text}>{t('confirmation_email_sent')}</p>

            <VerificationForm />
          </div>

          <AuthFooter />
        </section>

        <img className={styles.abstract} src={AbstractIconsSvg} alt="abstract" />
      </div>
    </AuthLayout>
  );
};

export default VerificationPage;
