import { $api } from 'src/shared/api/axios.instance';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { ROUTE_PATH } from 'src/shared/constants/route.path';

export const fetchPositionSummaryShared = createAsyncThunk(
  'tradeList/fetchPositionSummaryShared',
  async (publicLinkId: string, { rejectWithValue }) => {
    try {
      const response = await $api.get(ROUTE_PATH.positionsSummary.publicId, {
        params: {
          public_link_id: publicLinkId,
        },
      });

      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error instanceof Error ? error.message : String(error));
    }
  },
);
