import { Exchange } from 'src/app/store/slices/exchanges/types';
import { SubAccountType } from 'src/app/store/slices/sub-accounts/types';
import { ColumnOrderItem } from 'src/pages/trading/model/types';

export const bonusHistoryColumn: ColumnOrderItem[] = [
  { key: 'created_at', label: 'date and time' },
  { key: 'exchange', label: 'exchange' },
  { key: 'sub_account_id', label: 'account' },
  { key: 'quantity', label: 'amount' },
  { key: 'commission', label: 'commission' },
  { key: 'asset', label: 'asset' },
];

export const bonusHistoryColumns = {
  created_at: {
    show: true,
    name: 'date and time',
  },
  exchange: {
    show: true,
    name: 'exchange',
  },
  sub_account_id: {
    show: true,
    name: 'account',
  },
  quantity: {
    show: true,
    name: 'amount',
  },
  commission: {
    show: true,
    name: 'commission',
  },
  asset: {
    show: true,
    name: 'asset',
  },
};

export const allSubAccounts: SubAccountType = {
  id: 1014599373722360,
  exchange_name: '',
  exchange_id: 0,
  is_favorite: false,
  user_name: 'all',
  is_active_exchange: true,
};

export const allExchanges: Exchange = {
  id: 1812591373722361,
  created_at: '',
  image: '',
  updated_at: '',
  name: 'all',
  is_active: true,
};
