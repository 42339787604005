import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { ApiKey } from 'src/app/store/slices/sub-accounts/types';
import { EStatus, Nullable } from 'src/shared/types/global-types';
import { selectKycVerify } from 'src/app/store/slices/user/selectors';
import {
  selectApiKeys, selectApiKeysGetStatus,
} from 'src/app/store/slices/sub-accounts/selectors';
import { scrollToTop } from 'src/shared/libs/helpers/helper.lib';
import { ReactComponent as SettingsIconSvg } from 'src/shared/assets/images/wrench.svg';
import DefaultIconSvg from 'src/shared/assets/images/lot.png';
import Empty from 'src/entities/empty/empty';
import Passport from 'src/entities/passport/password';
import useExchange from 'src/shared/libs/hooks/use-exchange';
import useSubAccount from 'src/shared/libs/hooks/use-sub-account-name';
import SelectSkeleton from 'src/shared/ui/skeleton/select-skeleton/select-skeleton';
import { useTranslation } from 'react-i18next';
import styles from './api-keys-table-adaptive.module.scss';

interface IApiKeysTableAdaptiveProps {
    selectedApiKey: Nullable<ApiKey>,
    editApiKey: (apiKey: ApiKey) => void
}

const ApiKeysTableAdaptive = ({ editApiKey, selectedApiKey }: IApiKeysTableAdaptiveProps) => {
  const { getSubAccountNameById } = useSubAccount();
  const { getExchangeImageById } = useExchange();
  const { t } = useTranslation();

  const apiKeys = useSelector(selectApiKeys);
  const verifyKYC = useSelector(selectKycVerify);
  const statusGetApiKeys = useSelector(selectApiKeysGetStatus);

  return verifyKYC ? (
    <div className={styles.apiKeysAdaptive}>
      <ul className={styles.table}>
        {statusGetApiKeys === EStatus.success && apiKeys?.map((apiKey) => (
          <li key={apiKey.id}>
            <div className={styles.apiKeyItem}>
              <p className={styles.userName}>{apiKey.user_name}</p>
              <div className={styles.exchange}>
                <img src={getExchangeImageById(apiKey.exchange_id) || DefaultIconSvg} alt="exchange" />
                <p>{getSubAccountNameById(apiKey.sub_account_id)}</p>
              </div>
              <SettingsIconSvg
                className={classNames({ [styles.active]: selectedApiKey?.id === apiKey.id })}
                onClick={() => {
                  editApiKey(apiKey);
                  scrollToTop(document.body.scrollHeight);
                }}
              />
            </div>
          </li>
        ))}
      </ul>

      {statusGetApiKeys === EStatus.success && apiKeys?.length === 0 && (
        <div className={styles.apiKeysStateViewAdaptive}>
          <Empty>
            {t('this will be displayed here')}<br /> {t('your api keys')}
          </Empty>
        </div>
      ) }
      {statusGetApiKeys === EStatus.loading && (
        <div className={styles.skeletonWrapper}>
          { [1, 2].map((skeleton) => <SelectSkeleton key={skeleton} height={53} />)}
        </div>
      ) }
      {statusGetApiKeys === EStatus.rejected && (
        <div className={styles.apiKeysStateViewAdaptive}>
          <Empty horizontal className={styles.rejectedView} error>{t('internal error')}</Empty>
        </div>
      )}
    </div>
  ) : <div className={styles.passportWrapper}><Passport visible={!!verifyKYC} /></div>;
};

export default ApiKeysTableAdaptive;
