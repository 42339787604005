import { Link } from 'react-router-dom';
import { Checkbox } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { PRIVACY_POLICY, USER_AGREEMENT } from 'src/shared/constants/app-links';
import styles from './user-agreement.module.scss';

interface IUserAgreementprops {
  checked: boolean;
  setChecked: (value: boolean) => void;
}

const UserAgreement = ({ checked, setChecked } : IUserAgreementprops) => {
  const { t } = useTranslation();

  return (
    <div className={styles.userAgreement}>
      <Checkbox
        checked={checked}
        onChange={(event) => setChecked(event.currentTarget.checked)}
        color="#00C37C"
        size="md"
        className={styles.checkbox}
      />

      <div>
        {t('creating_account_acceptance')} <Link target="_black" to={USER_AGREEMENT}>{t('terms_of_service')}</Link> {t('and')} <Link target="_black" to={PRIVACY_POLICY}>{t('privacy_policy')}</Link>
      </div>
    </div>
  );
};

export default UserAgreement;
