import { Order, Position } from 'src/pages/trading/model/types';
import { PositionsSummary } from 'src/pages/account/model/types';
import { SubAccountAsset } from 'src/app/store/slices/sub-accounts/types';
import { Language, Nullable, UpdateInstrument } from '../types/global-types';
import { getFromLocalStorage } from '../libs/helpers/helper.lib';
import { DEFAULT_LANGUAGE } from '../constants/constants';

// The updateInstrumentPriceProcess function synchronizes the bid and ask prices
// of an instrument if one is missing.
export const updateInstrumentPriceProcess = (instrumentUpdate: UpdateInstrument): UpdateInstrument => {
  const instrument = { ...instrumentUpdate };

  if (!instrument.price.ask && instrument.price.bid) {
    instrument.price.ask = String(instrument.price.bid);
  } else if (!instrument.price.bid && instrument.price.ask) {
    instrument.price.bid = String(instrument.price.ask);
  }

  return instrument;
};

export const formatPositionProcess = (position: Position): Position => {
  const isPriceTypeString = typeof position.instrument.price === 'string';

  return isPriceTypeString
    ? {
      ...position,
      instrument: {
        ...position.instrument,
        price: {
          id: position.instrument.id,
          ask: String(position.instrument.price),
          bid: String(position.instrument.price),
        },
      },
    }
    : position;
};

export const formatOrderProcess = (order: Order): Order => {
  const isPriceTypeString = typeof order.instrument.price === 'string';

  return isPriceTypeString
    ? {
      ...order,
      instrument: {
        ...order.instrument,
        price: {
          id: order.instrument.id,
          ask: String(order.instrument.price),
          bid: String(order.instrument.price),
        },
      },
    }
    : order;
};

export const formatInstrumentProcess = (instrument: UpdateInstrument): UpdateInstrument => {
  const isPriceTypeString = typeof instrument.price === 'string';

  const instrumentPriceProcess = updateInstrumentPriceProcess(instrument);

  return isPriceTypeString ? {
    ...instrument,
    price: {
      id: instrument.id,
      ask: String(instrument.price),
      bid: String(instrument.price),
    },
  } : instrumentPriceProcess;
};

export const formatPositionSummaryProcess = (positionSummary: PositionsSummary): PositionsSummary => {
  const isPriceTypeString = typeof positionSummary?.instrument?.price === 'string';

  return isPriceTypeString
    ? {
      ...positionSummary,
      instrument: {
        ...positionSummary.instrument,
        price: {
          ask: String(positionSummary.instrument.price),
          bid: String(positionSummary.instrument.price),
        },
      },
    }
    : positionSummary;
};

export const formatSubAccountAssetProcess = (subAccountsAsset: SubAccountAsset): SubAccountAsset => {
  const isPriceTypeString = typeof subAccountsAsset?.instrument?.price === 'string';

  return isPriceTypeString
    ? {
      ...subAccountsAsset,
      instrument: {
        ...subAccountsAsset.instrument,
        price: {
          ask: String(subAccountsAsset.instrument.price),
          bid: String(subAccountsAsset.instrument.price),
        },
      },
    }
    : subAccountsAsset;
};

export const formatLanguageProcess = (): Language => {
  try {
    const storedLanguage = getFromLocalStorage('lang', null) as Nullable<Language>;

    return storedLanguage?.type ? storedLanguage : DEFAULT_LANGUAGE;
  } catch (error) {
    console.debug('Error retrieving or parsing language from localStorage:', error);
    return DEFAULT_LANGUAGE;
  }
};
