import { useTranslation } from 'react-i18next';
import { useChangePageTitle } from 'src/shared/libs/hooks/use-change-page-title';
import Empty from 'src/entities/empty/empty';

import './alerts.scss';

const pageTitle = 'alerts';

const AlertsPage = () => {
  useChangePageTitle(pageTitle);
  const { t } = useTranslation();

  return (
    <div className="alerts-page">
      <Empty>{t('page_in_development')}...</Empty>
    </div>
  );
};

export default AlertsPage;
