import { ColumnOrderItem } from '../model/types';

export const positionsColumn: ColumnOrderItem[] = [
  { key: 'checkbox', label: 'checkbox' },
  { key: 'instrument_symbol', label: 'instrument' },
  { key: 'exchange_name', label: 'exchange' },
  { key: 'leverage', label: 'leverage' },
  { key: 'sub_account_id', label: 'account' },
  { key: 'side', label: 'direct.' },
  { key: 'quantity', label: 'quantity' },
  { key: 'quantity_dollars', label: 'quantity_dollars' },
  { key: 'average_price', label: 'average_price' },
  { key: 'price', label: 'last_price' },
  { key: 'pnlInPercents', label: 'Pnl (%)' },
  { key: 'pnlInCurrency', label: 'Pnl ($)' },
  { key: 'reduce', label: 'reduce' },
  { key: 'close', label: 'close' },
];

export const ordersColumn: ColumnOrderItem[] = [
  { key: 'checkbox', label: 'checkbox' },
  { key: 'instrument_symbol', label: 'instrument' },
  { key: 'exchange_name', label: 'exchange' },
  { key: 'sub_account_id', label: 'account' },
  { key: 'quantity', label: 'quantity' },
  { key: 'quantity_dollars', label: 'quantity_dollars' },
  { key: 'price', label: 'price' },
  { key: 'last_price', label: 'last_price' },
  { key: 'action', label: 'direction' },
  { key: 'type', label: 'type' },
  { key: 'close', label: 'cancel' },
];

export const orderTableColumns = {
  checkbox: {
    show: true,
    name: 'checkbox',
  },
  instrument: {
    show: true,
    name: 'instrument',
  },
  exchange: {
    show: true,
    name: 'exchange',
  },
  subAccountId: {
    show: true,
    name: 'account',
  },
  quantity: {
    show: true,
    name: 'quantity',
  },
  quantity_dollars: {
    show: true,
    name: 'quantity_dollars',
  },
  price: {
    show: true,
    name: 'price',
  },
  lastPrice: {
    show: true,
    name: 'last_price',
  },
  action: {
    show: true,
    name: 'direction',
  },
  type: {
    show: true,
    name: 'type',
  },
  close: {
    show: true,
    name: 'cancel',
  },
};

type TableColumn = {
  show: boolean;
  name: string;
};

export const positionsTableColumns: Record<string, TableColumn> = {
  checkbox: {
    show: true,
    name: 'checkbox',
  },
  instrument: {
    show: true,
    name: 'instrument',
  },
  exchange: {
    show: true,
    name: 'exchange',
  },
  leverage: {
    show: true,
    name: 'leverage',
  },
  subAccountId: {
    show: true,
    name: 'account',
  },
  side: {
    show: true,
    name: 'direction',
  },
  quantity: {
    show: true,
    name: 'quantity',
  },
  quantity_dollars: {
    show: true,
    name: 'quantity_dollars',
  },
  averagePrice: {
    show: true,
    name: 'average_price',
  },
  price: {
    show: true,
    name: 'last_price',
  },
  pnlInPercents: {
    show: true,
    name: 'Pnl (%)',
  },
  pnlInCurrency: {
    show: true,
    name: 'Pnl ($)',
  },
  reduce: {
    show: true,
    name: 'reduce',
  },
  close: {
    show: true,
    name: 'close',
  },
};
