import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Divider, Drawer } from '@mantine/core';
import { useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { useAuth } from 'src/shared/libs/hooks/use-auth';
import { useTheme } from 'src/shared/libs/hooks/use-theme';
import { Language } from 'src/shared/types/global-types';
import { LANGUAGES } from 'src/shared/constants/constants';
import { EThemeMode } from 'src/app/store/slices/user/types';
import { USER_AGREEMENT } from 'src/shared/constants/app-links';
import { useAppDispatch } from 'src/app/store/store';
import { selectLanguage } from 'src/app/store/slices/user/selectors';
import { fetchChangeLanguage } from 'src/app/store/slices/user/thunks';
import { getActivePageByLocation, handleSupportTelegram, saveToLocalStorage } from 'src/shared/libs/helpers/helper.lib';
import { ReactComponent as LogOutSvg } from 'src/shared/assets/images/header/log-out.svg';
import { ReactComponent as DiscordIconSvg } from 'src/shared/assets/images/socials/discord.svg';
import { ReactComponent as TelegramIconSvg } from 'src/shared/assets/images/socials/telegram.svg';

import Button from 'src/shared/ui/button/button/button';
import { NAVIGATION_LINKS } from './constants';
import AsideMenu from '../aside/components/aside-menu/aside-menu';
import AdaptiveBalance from '../adaptive-widgets/adaptive-balance/adaptive-balance';
import styles from './adaptive-menu.module.scss';

interface IAdaptiveMenuProps {
  opened: boolean
  visible: boolean
  close: () => void
  onLogOut: () => void
}

const AdaptiveMenu = ({
  opened, visible, close, onLogOut,
}: IAdaptiveMenuProps) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { isAuth } = useAuth();
  const { theme } = useTheme();
  const { t, i18n } = useTranslation();

  const globalLanguage = useSelector(selectLanguage);
  const [showSelectLanguage, setShowSelectLanguage] = useState(false);

  const language = useMemo(() => {
    if (globalLanguage) {
      return LANGUAGES.find((item) => item.type === globalLanguage);
    }
    return LANGUAGES.find((item) => item.type === i18n.language);
  }, [globalLanguage, i18n.language]);

  const activePage = useMemo(() => getActivePageByLocation(location), [location]);

  const handleToggleLanguage = (selectLanguage: Language) => {
    if (selectLanguage.type === language?.type) {
      setShowSelectLanguage(false);
      return;
    }

    if (isAuth) dispatch(fetchChangeLanguage(selectLanguage.type));
    i18n.changeLanguage(selectLanguage.type);
    saveToLocalStorage('lang', selectLanguage);
    setShowSelectLanguage((prev) => !prev);
  };

  return visible ? (
    <Drawer zIndex={2001} className={styles.adaptiveMenuDrawer} position="left" size="xl" opened={opened} onClose={() => {}}>
      <div className={styles.adaptiveMenuWrapper}>

        <AdaptiveBalance closeMenu={close} className={styles.adaptiveBalance} visible />
        <Divider color={theme === EThemeMode.LIGHT ? '#D9D9D9' : '#3A3A3A'} style={{ margin: '20px 0' }} />

        <div className={styles.adaptiveMenu}>
          <h1>{t('main menu')}</h1>

          <AsideMenu close={close} />
          <Divider color={theme === EThemeMode.LIGHT ? '#D9D9D9' : '#3A3A3A'} style={{ margin: '20px 0' }} />
          <nav>
            <ul>
              {
                NAVIGATION_LINKS.filter((item) => item.isAdaptive).map((item) => (
                  <li key={item.path}>
                    <Link onClick={close} to={item.path} className={classNames(styles.menuItem, { [styles.active]: activePage === item.path.replace('/', '') })}>
                      <span>{t(item.title)}</span>
                    </Link>
                  </li>
                ))
              }
            </ul>
          </nav>

          <Divider color={theme === EThemeMode.LIGHT ? '#D9D9D9' : '#3A3A3A'} style={{ margin: '20px 0' }} />

          <div className={styles.actions}>
            <Button onClick={onLogOut} background="red">{t('log out')} <LogOutSvg /></Button>
            <div className={styles.selectLanguageWrapper}>
              <button onClick={() => setShowSelectLanguage((prev) => !prev)} className={styles.selectLanguage}>{language?.name}</button>
              <ul className={classNames(styles.languageList, { [styles.open]: showSelectLanguage })}>
                {
                  LANGUAGES.map((lang) => (
                    <li key={lang.type}><button onClick={() => handleToggleLanguage(lang)}>{lang.name}</button></li>
                  ))
                }
              </ul>
            </div>
          </div>

          <Divider color={theme === EThemeMode.LIGHT ? '#D9D9D9' : '#3A3A3A'} style={{ margin: '20px 0' }} />

          <div className={styles.info}>
            <Link to={USER_AGREEMENT} target="_blank">{t('terms of use of company products and services')}</Link>

            <p className={styles.registration}>
              LOTSOFT INC. | 155755565
            </p>
          </div>

          <Divider color={theme === EThemeMode.LIGHT ? '#D9D9D9' : '#3A3A3A'} style={{ margin: '20px 0' }} />

          <div className={styles.socials}>
            <p>{t('be the first to know about project updates')}</p>
            <ul>
              <li className={styles.socialTelegram}><button onClick={handleSupportTelegram}><TelegramIconSvg /></button></li>
              <li className={styles.socialDiscord}><a href="https://discord.gg/TUP9BzTvfX" target="_blank"><DiscordIconSvg /></a></li>
            </ul>
          </div>
        </div>
      </div>
    </Drawer>
  ) : null;
};

export default AdaptiveMenu;
