import moment from 'moment';
import className from 'classnames';

import { Drawer } from '@mui/material';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useDisclosure } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'src/app/store/store';
import { LoadingOverlay, Modal } from '@mantine/core';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import {
  AssetsParams, SearchAssetsParamsType, fetchAssets, fetchSearchAssets,
} from 'src/app/store/slices/assets/thunks';
import { selectExchanges } from 'src/app/store/slices/exchanges/selectors';
import { removeSearchAssets } from 'src/app/store/slices/assets/slice';
import { selectAssets, selectSearchAssets } from 'src/app/store/slices/assets/selectors';
import { ReactComponent as FilterIconSvg } from 'src/shared/assets/images/filter.svg';
import DefaultExchangeImage from 'src/shared/assets/images/lot.png';

import { useTheme } from 'src/shared/libs/hooks/use-theme';
import { BalanceType } from 'src/entities/balance-type';
import { HIDDEN_BALANCES } from 'src/shared/constants/constants';
import { manipulateString } from 'src/pages/profile/libs/helpers/manipulate-string';
import { EStatus, Nullable } from 'src/shared/types/global-types';
import { debounce, getDate } from 'src/shared/libs/helpers/helper.lib';
import { selectHiddenBalance } from 'src/pages/settings/model/selectors';
import { useIntersectionObserver } from 'src/shared/libs/hooks/use-observer';
import { transferTypeIcon, transferTypeTranslations } from 'src/pages/accounts/libs/helpers';

import Empty from 'src/entities/empty/empty';
import Button from 'src/shared/ui/button/button/button';
import Select from 'src/shared/ui/select/select';
import useAlert from 'src/shared/libs/hooks/use-alert';
import InputForm from 'src/shared/ui/input/input-form/input-form';
import ButtonClose from 'src/shared/ui/button/button-close/button-close';
import useExchange from 'src/shared/libs/hooks/use-exchange';
import SelectSkeleton from 'src/shared/ui/skeleton/select-skeleton/select-skeleton';
import DataPickerRange from 'src/shared/ui/date/data-range-picker';
import useSubAccount from 'src/shared/libs/hooks/use-sub-account-name';
import TransferHistoryDetailModaContent from 'src/entities/transfer-history-detail-moda-content/transfer-history-detail-moda-content';

import { selectTransfersHistory } from '../model/selectors';
import { ETransferType, TransferHistorySortColumn, TransferHistoryType } from '../model/types';
import {
  TransfersHistoryParams,
  WithdrawalDetailParams,
  fetchTransfersHistory,
  fetchWithdrawalDetail,
  subscribeTransfers,
  unsubscribeTransfers,
} from '../model/thunks';
import { removeTransferHistory, removeWithdrawaDetail } from '../model/slice';
import { ALL_ASSET, TRANSFER_HISTORY_SORT_COLUMNS } from '../constants';
import styles from './transfer-history-adaptive.module.scss';

const TransferHistoryAdaptive = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const { theme } = useTheme();
  const { setAlertMessage } = useAlert();
  const { getSubAccountNameById } = useSubAccount();
  const { exchanges } = useSelector(selectExchanges);
  const { getExchangeImageById, getExchangeNameById, getExchangeIdByExchangeName } = useExchange();

  const { transfersHistory, status } = useSelector(selectTransfersHistory);
  const balanceVisible = useSelector(selectHiddenBalance);
  const assets = useSelector(selectAssets);
  const searchAssets = useSelector(selectSearchAssets);

  const [openedTableFilter, { open: openTableFilter, close: closeTableFilter }] = useDisclosure(false);
  const [openedWithdrawalDetail, { open: openWithdrawalDetail, close: closeWithdrawalDetail }] = useDisclosure(false);

  const [fetchPage, setFetchPage] = useState(1);
  const [assetSearch, setAssetSearch] = useState('');
  const [fetchAssetsPage, setFetchAssetsPage] = useState(1);
  const [currentTransferType, setCurrentTransferType] = useState('ALL_TRANSFERS');
  const [currentTransferSymbol, setCurrentTransferSymbol] = useState('all');
  const [sortField, setSortField] = useState<Nullable<TransferHistorySortColumn>>(null);

  const { currentDateAsString, sevenDaysAgoAsString } = getDate();
  const [dateRange, setDateRange] = useState<any>([sevenDaysAgoAsString, currentDateAsString]);
  const [startDate, endDate] = dateRange;

  useIntersectionObserver(fetchPage, transfersHistory?.meta.total_pages || 1, transfersHistory?.items, styles.transferItem, setFetchPage, 9);
  useIntersectionObserver(fetchAssetsPage, assets?.meta.total_pages || 1, assets?.items, 'observer-asset', setFetchAssetsPage, 19);

  const debouncedFetch = useCallback(
    debounce((searchParams: SearchAssetsParamsType) => {
      dispatch(fetchSearchAssets(searchParams));
    }, 500) as any,
    [dispatch],
  );

  const handleResetFilter = () => {
    const currentDate = moment();
    const startDateCurrent = currentDate.clone().subtract(7, 'days');

    const start = startDateCurrent.startOf('day').format('YYYY-MM-DD HH:mm:ss');
    const end = currentDate.endOf('day').format('YYYY-MM-DD HH:mm:ss');

    const checkStartDate = moment(startDate).format('YYYY-MM-DD');
    const checkEndDate = moment(endDate).format('YYYY-MM-DD');

    if (checkStartDate === moment(start).format('YYYY-MM-DD') && checkEndDate === moment(end).format('YYYY-MM-DD') && currentTransferType === 'ALL_TRANSFERS' && currentTransferSymbol === 'Все') return;

    handleRemoveTransferHistory();

    setCurrentTransferType('ALL_TRANSFERS');
    setCurrentTransferSymbol('Все');
    setFetchPage(1);
    setDateRange([start, end]);
    setSortField(null);
  };

  const transferDirection = (subAccountid: Nullable<number>, address: Nullable<string>, exchangeId: number) => {
    if (subAccountid) return getSubAccountNameById(subAccountid);

    if (address) return manipulateString(address);

    return getExchangeNameById(exchangeId);
  };

  const handleFetchWithdrawalDetail = async (transfer: TransferHistoryType) => {
    if (transfer.transfer_type === ETransferType.EXTERNAL_OUTPUT && transfer.withdrawal_request_id) {
      openWithdrawalDetail();

      const params: WithdrawalDetailParams = {
        withdrawal_request_id: transfer.withdrawal_request_id,
      };

      const { payload } = await dispatch(fetchWithdrawalDetail(params));

      if (typeof payload === 'string') setAlertMessage(payload, 'error');
    }
  };

  const handleCloseWithdrawalDetail = () => {
    closeWithdrawalDetail();
    dispatch(removeWithdrawaDetail());
  };

  const handleRemoveTransferHistory = () => {
    dispatch(removeTransferHistory());
  };

  const handleSetCurrentTransferSymbol = (symbol: string) => {
    if (symbol === currentTransferSymbol) return;

    setFetchPage(1);
    setCurrentTransferSymbol(symbol);
    handleRemoveTransferHistory();
  };

  const handleTransferTypeSelect = (type: string) => {
    handleRemoveTransferHistory();
    setCurrentTransferType(type);
    setFetchPage(1);
  };

  const handleGetTransfersHistory = async () => {
    setFetchPage(1);
    closeTableFilter();
    handleRemoveTransferHistory();
  };

  useEffect(() => {
    if (openedTableFilter) {
      setSortField(TRANSFER_HISTORY_SORT_COLUMNS[0]);
    }
  }, [openedTableFilter]);

  useLayoutEffect(() => {
    const path = location.pathname.split('/');
    const id = path[2];

    if (openedTableFilter) return;

    if (id) {
      const fetchParams: TransfersHistoryParams = {
        from_date: moment(startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        to_date: moment(endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        page: fetchPage,
        limit: 10,
        sub_account_id: Number(id),
      };

      if (startDate && endDate) {
        if (currentTransferSymbol !== 'Все') {
          fetchParams.symbol = currentTransferSymbol;
        }

        if (currentTransferType !== 'ALL_TRANSFERS') {
          fetchParams.transfer_type = currentTransferType;
        }

        dispatch(fetchTransfersHistory(fetchParams));
      }
    } else {
      const fetchParams: TransfersHistoryParams = {
        from_date: moment(startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        to_date: moment(endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        page: fetchPage,
        limit: 10,
      };

      if (sortField) {
        fetchParams.sort_by = sortField.column;
        fetchParams.order_by = sortField.orderBy;
      }

      if (startDate && endDate) {
        if (currentTransferSymbol !== 'Все') {
          fetchParams.symbol = currentTransferSymbol;
        }

        if (currentTransferType !== 'ALL_TRANSFERS') {
          fetchParams.transfer_type = currentTransferType;
        }

        dispatch(fetchTransfersHistory(fetchParams));
      }
    }
  }, [startDate, endDate, fetchPage, currentTransferSymbol, currentTransferType, location, openedTableFilter]);

  useEffect(() => {
    const exchangeId = getExchangeIdByExchangeName('Binance');

    if (!exchangeId) return;

    const params: AssetsParams = {
      asset_trading_type: 'SPOT',
      limit: 20,
      page: fetchAssetsPage,
      exchange_id: exchangeId,
    };

    dispatch(fetchAssets(params));
  }, [fetchAssetsPage, exchanges]);

  useEffect(() => {
    const exchangeId = getExchangeIdByExchangeName('Binance');

    if (!exchangeId) return;

    if (assetSearch.length > 1) {
      const searchAssetsParams: SearchAssetsParamsType = {
        query: assetSearch.toUpperCase(),
        asset_trading_type: 'SPOT',
        exchange_id: exchangeId,
      };

      debouncedFetch.execute(searchAssetsParams);
    }

    if (assetSearch.length === 0) {
      dispatch(removeSearchAssets());
      debouncedFetch.stop();
    }
  }, [assetSearch]);

  useEffect(() => {
    const path = location.pathname.split('/');
    const id = path[2];

    if (id) {
      handleRemoveTransferHistory();
      setFetchPage(1);
    }
  }, [location]);

  useEffect(() => {
    subscribeTransfers();

    return () => {
      handleRemoveTransferHistory();
      unsubscribeTransfers();
    };
  }, []);

  return (
    <section className={styles.transferHistoryAdaptive}>
      <header>
        <h3>{t('transfer_history')}</h3>
        <FilterIconSvg onClick={openTableFilter} className={styles.filterBtn} />
      </header>

      <div className={styles.table}>
        {status === EStatus.loading && !transfersHistory && (
          [1, 2, 3, 4, 5].map((skeleton) => <SelectSkeleton key={skeleton} height={150} />)
        )}

        {transfersHistory?.items?.length === 0 && status === EStatus.success && (
          <Empty maxWidthText="200px">
            {t('no-history-for-selected-period')}
          </Empty>
        )}

        {transfersHistory?.items?.map((transfer) => (
          <div
            key={transfer.id}
            className={styles.transferItem}
            role="button"
            tabIndex={0}
            onClick={() => handleFetchWithdrawalDetail(transfer)}
            onKeyDown={() => handleFetchWithdrawalDetail(transfer)}
          >
            <div className={styles.box}>
              <div className={styles.data}>
                <div className={styles.quantity}>
                  <span>{!balanceVisible ? transfer.quantity : HIDDEN_BALANCES}</span>
                  <span>{transfer.asset.symbol}</span>
                </div>
                <div className={styles.createdAt}>
                  {moment(transfer.created_at).format('YYYY-MM-DD HH:mm')}
                </div>
              </div>
              <div className={styles.transferType}>
                <img src={transferTypeIcon(transfer.transfer_type)} alt="type" />
              </div>
            </div>

            <div className={styles.boxVertical}>
              <div className={styles.horizontalWrapper}>
                <div className={styles.lineTitle}>{t('from_account')}:</div>
                <div className={styles.subAccount}>
                  <img src={getExchangeImageById(transfer.exchange_id) || DefaultExchangeImage} alt="exchange" />
                  <span>{transferDirection(transfer.from_sub_account_id, transfer.from_address, transfer.exchange_id)}</span>
                  <BalanceType type={transfer.from_transfer_trading_type} />
                </div>
              </div>
              <div className={styles.horizontalWrapper}>
                <div className={styles.lineTitle}>{t('to_account')}:</div>
                <div className={styles.subAccount}>
                  <img src={getExchangeImageById(transfer.exchange_id) || DefaultExchangeImage} alt="exchange" />
                  <span>{transferDirection(transfer.to_sub_account_id, transfer.to_address, transfer.exchange_id)}</span>
                  <BalanceType type={transfer.to_transfer_trading_type} />
                </div>
              </div>
            </div>
          </div>
        ))}

        {status === EStatus.rejected && (
          <Empty horizontal error maxWidthText="200px">
            {t('no-history-for-selected-period')}
          </Empty>
        )}

        {status === EStatus.loading && transfersHistory && transfersHistory.items.length > 0 && (
          <div className={styles.loadingItemWrapper}>
            <LoadingOverlay
              className={`loader ${styles.loader}`}
              visible
              zIndex={1000}
              overlayProps={{ radius: 'sm', blur: 2 }}
              loaderProps={{ color: '#00C37C', type: 'dots' }}
            />
          </div>
        )}
      </div>

      <Modal
        radius={16}
        size="xs"
        opened={openedWithdrawalDetail}
        onClose={handleCloseWithdrawalDetail}
        className="modal-custom-overflow hidden-title blue-filter"
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
      >
        <TransferHistoryDetailModaContent closeModal={handleCloseWithdrawalDetail} />
      </Modal>

      <Drawer className={styles.drawer} onClose={closeTableFilter} anchor="bottom" open={openedTableFilter}>
        <div className={styles.filterWrapper}>
          <div className={styles.header}>
            <h3>{t('filters')}</h3>
            <ButtonClose className={styles.closeBtn} onClick={() => closeTableFilter()} />
          </div>

          <div className={styles.content}>
            <DataPickerRange monthsShown={1} whiteMode={theme === 'light'} removeData={handleRemoveTransferHistory} setPage={setFetchPage} setDateRange={setDateRange} dateRange={dateRange} />

            <InputForm title={t('asset')}>
              { assets ? (
                <Select
                  isActiveSearch
                  searchValue={assetSearch}
                  isSearch={setAssetSearch}
                  isSelectValue={(
                    <div className={styles.selectItem}>
                      <span className="short-name">{t(currentTransferSymbol)}</span>
                    </div>
                  )}
                >
                  {
                    searchAssets ? searchAssets.map((asset) => (
                      <div
                        key={asset.id}
                        className={className(styles.selectItem, 'observer-asset')}
                        role="button"
                        tabIndex={0}
                        onClick={() => handleSetCurrentTransferSymbol(asset.symbol)}
                        onKeyDown={() => handleSetCurrentTransferSymbol(asset.symbol)}
                      >
                        <span className="short-name">{asset.symbol}</span>
                      </div>
                    ))
                      : [ALL_ASSET, ...assets.items].map((asset) => (
                        <div
                          key={asset.id}
                          className={className(styles.selectItem, 'observer-asset')}
                          role="button"
                          tabIndex={0}
                          onClick={() => handleSetCurrentTransferSymbol(asset.symbol)}
                          onKeyDown={() => handleSetCurrentTransferSymbol(asset.symbol)}
                        >
                          <span className="short-name">{t(asset.symbol)}</span>
                        </div>
                      ))
                  }
                </Select>
              ) : <SelectSkeleton />}
            </InputForm>

            <InputForm title={t('transfer_type')}>
              {transferTypeTranslations ? (
                <Select
                  className={styles.typesItemsContainer}
                  isActiveSearch={false}
                  isSelectValue={(
                    <div className={styles.selectItem}>
                      { currentTransferType !== 'ALL_TRANSFERS' && <img src={transferTypeIcon(currentTransferType)} alt="type" />}
                      { t(transferTypeTranslations[currentTransferType])}
                    </div>
                  )}
                >
                  {
                    Object.entries(transferTypeTranslations).map(([key, value]) => (
                      <div
                        key={key}
                        className={styles.selectItem}
                        role="button"
                        tabIndex={0}
                        onClick={() => handleTransferTypeSelect(key)}
                        onKeyDown={() => handleTransferTypeSelect(key)}
                      >
                        {key !== 'ALL_TRANSFERS' && <img src={transferTypeIcon(key)} alt="type" />}
                        <span className="short-name">{t(value)}</span>
                      </div>
                    ))
                  }
                </Select>
              ) : <SelectSkeleton /> }
            </InputForm>

            <InputForm title={t('sort')}>
              <Select
                className={styles.sortItemsContainer}
                isActiveSearch={false}
                isSelectValue={(
                  <button className={styles.selectItem}>{t(sortField?.title)}</button>
                )}
              >
                { TRANSFER_HISTORY_SORT_COLUMNS.map((sortItem) => (
                  <button onClick={() => setSortField(sortItem)} className={styles.selectItem} key={sortItem.column}>{t(sortItem.title)}</button>
                ))}
              </Select>
            </InputForm>

            <Button onClick={handleGetTransfersHistory} background="green">{t('apply')}</Button>
            <Button onClick={handleResetFilter} background={theme === 'light' ? 'black' : 'white'}>{t('reset filters')}</Button>
          </div>
        </div>
      </Drawer>
    </section>
  );
};

export default TransferHistoryAdaptive;
