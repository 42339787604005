import { useSelector } from 'react-redux';
import { ModalSteps } from 'src/shared/types/global-types';
import { selectUser } from 'src/app/store/slices/user/selectors';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'src/app/store/store';
import { removeVerifications } from 'src/app/store/slices/verifications/slice';

import Button from 'src/shared/ui/button/button/button';
import CopyIcon from 'src/shared/assets/icons/copy-icon/copy-icon';

import { ReactComponent as InfoSvg } from 'src/shared/assets/images/auth/info.svg';

import styles from './connection-ga-step-three.module.scss';

interface IConnectionGaStepThree extends ModalSteps {
  stopTimer: (stae: boolean) => void
}
export const ConnectionGaStepThree = ({ step, setStep, stopTimer }: IConnectionGaStepThree) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { connectedGALink } = useSelector(selectUser);

  const nextStepVerifyGa = () => {
    localStorage.removeItem('changeGAConnect');
    stopTimer(false);
    dispatch(removeVerifications());
    setStep(4);
  };

  return step === 3 ? (
    <div className={styles.gaModalWrapper}>

      <div className={styles.modalHint}>
        <InfoSvg />
        <p>{t('write_or_save_key')}</p>
      </div>
      <div className={styles.gaKeyWrapper}>
        <p className={styles.gaKey}>{connectedGALink?.secret_key}</p>
        <CopyIcon value={connectedGALink?.secret_key} />
      </div>

      <p className={styles.info}>{t('recovery_support')} <a href="mailto:support@league.broker">support@league.broker</a></p>

      <div className={styles.gaModalActions}>
        <Button onClick={nextStepVerifyGa} background="green">{t('next')}</Button>
      </div>
    </div>
  ) : null;
};
