const validateFormNewApi = (ipLabel: string, ipAddress: string) => {
  const regexIpAdress = /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

  if (!ipLabel) {
    return { title: 'field required', state: true };
  }

  if (!ipAddress) {
    return { title: 'field required', state: true };
  }

  if (!regexIpAdress.test(ipAddress)) {
    return { title: 'invalid ip', state: true };
  }

  return { title: '', state: false };
};

export default validateFormNewApi;
