import { Link } from 'react-router-dom';
import { ReactComponent as FiteIconSvg } from 'src/shared/assets/images/screener/file-text.svg';
import { useTranslation } from 'react-i18next';
import {
  COOKIE_INFO, PRIVACY_POLICY, RISK_WARNINGS, USER_AGREEMENT,
} from 'src/shared/constants/app-links';

import './documents-slide.scss';

const DocumentsSlide = () => {
  const { t } = useTranslation();
  return (
    <div className="slide documents-slide">
      <h4>{t('documents')}</h4>

      <div className="documents-slide_content">
        <Link target="_blank" to={USER_AGREEMENT}><FiteIconSvg /> {t('terms of use')}</Link>
        <Link target="_blank" to={PRIVACY_POLICY}><FiteIconSvg /> {t('privacy policy')}</Link>
        <Link target="_blank" to={RISK_WARNINGS}><FiteIconSvg /> {t('risk warning')}</Link>
        <Link target="_blank" to={COOKIE_INFO}><FiteIconSvg /> {t('cookie policy')} </Link>
      </div>
    </div>
  );
};

export default DocumentsSlide;
