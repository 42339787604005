import { ColumnOrderItem } from 'src/pages/trading/model/types';
import { getFromLocalStorage } from 'src/shared/libs/helpers/helper.lib';

export const transactionHistoryColumnLocalStorage = getFromLocalStorage('transactionHistoryColumnOrder', null);
export const transactionHistoryTableColumnsLocalStorage = getFromLocalStorage('transactionHistoryTableColumns', null);

export const transactionHistoryColumn: ColumnOrderItem[] = [
  { key: 'entered_at', label: 'date and time' },
  { key: 'instrument', label: 'instrument' },
  { key: 'side', label: 'side' },
  { key: 'pnl', label: 'Pnl ($)' },
  { key: 'pnl_percent', label: 'Pnl (%)' },
  { key: 'quantity', label: 'quantity' },
  { key: 'quantity_dollars', label: 'quantity_dollars' },
  { key: 'entry_price', label: 'entry_price' },
  { key: 'exit_price', label: 'exit_price' },
  { key: 'profit', label: 'profit' },
  { key: 'cumulative_quantity', label: 'cumulative_quantity' },
  { key: 'commission', label: 'commission_dollar' },
];

export const transactionHistoryColumns = {
  entered_at: {
    show: true,
    name: 'date and time',
  },
  instrument: {
    show: true,
    name: 'instrument',
  },
  side: {
    show: true,
    name: 'side',
  },
  pnl: {
    show: true,
    name: 'Pnl ($)',
  },
  pnlPercent: {
    show: true,
    name: 'Pnl (%)',
  },
  quantity: {
    show: true,
    name: 'quantity',
  },
  quantity_dollars: {
    show: true,
    name: 'quantity_dollars',
  },
  entry_price: {
    show: true,
    name: 'entry_price',
  },
  exit_price: {
    show: true,
    name: 'exit_price',
  },
  profit: {
    show: true,
    name: 'profit',
  },
  cumulative_quantity: {
    show: true,
    name: 'cumulative_quantity',
  },
  commission: {
    show: true,
    name: 'commission_dollar',
  },
};
