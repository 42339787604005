import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  useEffect, useState, useRef, useMemo,
} from 'react';
import { LoadingOverlay, Tooltip } from '@mantine/core';
import { join } from 'src/shared/libs/helpers/helper.lib';
import { EStatus } from 'src/shared/types/global-types';
import { useAppDispatch } from 'src/app/store/store';
import { selectLanguage } from 'src/app/store/slices/user/selectors';
import { useIntersectionObserver } from 'src/shared/libs/hooks/use-observer';
import { ReactComponent as MailIconSvg } from 'src/shared/assets/images/mail.svg';
import { ReactComponent as BellIconSvg } from 'src/shared/assets/images/header/bell.svg';
import { ReactComponent as ReadIconSvg } from 'src/shared/assets/images/read.svg';
import { ReactComponent as DeleteIconSvg } from 'src/shared/assets/images/delete.svg';
import { ReactComponent as ExitIconSvg } from 'src/shared/assets/images/exit.svg';

import Button from 'src/shared/ui/button/button/button';
import useAlert from 'src/shared/libs/hooks/use-alert';
import Delimiter from 'src/shared/ui/delimiter/delimiter/delimiter';
import useMobile from 'src/shared/libs/hooks/useMobile';
import Notification from './components/notification/notification';

import { Notification as NotificationType } from './model/types';
import { selectNotifications } from './model/selectors';
import {
  NotificationMark, fetchNotifications, fetchNotificationsDelete, fetchNotificationsMarkAsRead,
} from './model/thunks';

import './notifications.scss';

interface INotificationsProps {
  setAreShownNotifications: (value: boolean) => void;
}

const Notifications = ({ setAreShownNotifications }: INotificationsProps) => {
  const dispatch = useAppDispatch();
  const isMobile = useMobile();
  const { t } = useTranslation();
  const { setAlertMessage } = useAlert();

  const isMount = useRef(false);
  const [open, setOpen] = useState(false);
  const [fetchPage, setFetchPage] = useState(1);
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [markAsReadStatus, setMarkAsReadStatus] = useState(false);
  const [showCheckbox, setShowCheckbox] = useState(false);

  const globalLanguage = useSelector(selectLanguage);
  const {
    notifications, status,
  } = useSelector(selectNotifications);

  const localizedNotifications = useMemo(() => {
    if (!notifications) return [];

    return notifications.items.map(({ message, ...rest }) => ({
      ...rest,
      message: message.filter((msg) => msg.lang === globalLanguage),
    }))
      .filter((notification) => notification.message);
  }, [globalLanguage, notifications]);

  const [selectedNotifications, setSelectedNotifications] = useState<NotificationType[]>([]);

  useIntersectionObserver(fetchPage, notifications?.meta.total_pages || 1, notifications?.items, 'observer-notify', setFetchPage, 10);

  const handleShownNotifications = () => {
    setOpen(false);

    setTimeout(() => {
      setAreShownNotifications(false);
    }, 500);
  };

  const handleSelectedNotifications = (notification: NotificationType) => {
    const findNotificsation = selectedNotifications.find((item) => item.id === notification.id);

    if (findNotificsation) {
      setSelectedNotifications((prev) => prev.filter((item) => item.id !== notification.id));
    } else {
      setSelectedNotifications((prev) => prev.concat(notification));
    }
  };

  const handleFetchMarkAsRead = async () => {
    const notificationIds:NotificationMark = {
      notification_ids: selectedNotifications.map((item) => item.id),
    };
    setMarkAsReadStatus(true);

    try {
      const { payload } = await dispatch(fetchNotificationsMarkAsRead(notificationIds));

      if (typeof payload === 'string') setAlertMessage(payload, 'error');
      if (typeof payload === 'object') setAlertMessage(JSON.stringify(payload), 'error');
    } catch (error) {
      const errorMessage: string = typeof error === 'string' ? error : JSON.stringify(error);
      setAlertMessage(errorMessage, 'error');
    } finally {
      setSelectedNotifications([]);
      setMarkAsReadStatus(false);
    }
  };

  const handleFetchMarkAsReadById = async (id: number) => {
    const notificationIds:NotificationMark = {
      notification_ids: [id],
    };

    try {
      const { payload } = await dispatch(fetchNotificationsMarkAsRead(notificationIds));

      if (typeof payload === 'string') setAlertMessage(payload, 'error');
      if (typeof payload === 'object') setAlertMessage(JSON.stringify(payload), 'error');
    } catch (error) {
      const errorMessage: string = typeof error === 'string' ? error : JSON.stringify(error);
      setAlertMessage(errorMessage, 'error');
    } finally {
      setSelectedNotifications([]);
    }
  };

  const handleFetchDeleteNotifications = async () => {
    const notificationIds: number[] = selectedNotifications.map((item) => item.id);
    setDeleteStatus(true);

    try {
      const { payload } = await dispatch(fetchNotificationsDelete(notificationIds));

      if (typeof payload === 'string') setAlertMessage(payload, 'error');
      if (typeof payload === 'object') setAlertMessage(JSON.stringify(payload), 'error');
    } catch (error) {
      const errorMessage: string = typeof error === 'string' ? error : JSON.stringify(error);
      setAlertMessage(errorMessage, 'error');
    } finally {
      setSelectedNotifications([]);
      setDeleteStatus(false);
    }
  };

  const handleFetchDeleteNotification = async (id: number) => {
    const notificationId: number[] = [id];

    try {
      const { payload } = await dispatch(fetchNotificationsDelete(notificationId));

      if (typeof payload === 'string') setAlertMessage(payload, 'error');
      if (typeof payload === 'object') setAlertMessage(JSON.stringify(payload), 'error');
    } catch (error) {
      const errorMessage: string = typeof error === 'string' ? error : JSON.stringify(error);
      setAlertMessage(errorMessage, 'error');
    } finally {
      setSelectedNotifications([]);
    }
  };

  const handleMarksAsAllNotifications = () => {
    if (notifications?.items.length === selectedNotifications.length) {
      setSelectedNotifications([]);
      return;
    }

    if (notifications?.items.length) {
      setSelectedNotifications(notifications.items);
    }
  };

  useEffect(() => {
    let isMounted = true;
    setTimeout(() => {
      if (isMounted) {
        setOpen(true);
      }
    }, 1);

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (fetchPage > 1 && isMount.current) {
      const params = {
        limit: 20,
        page: fetchPage,
      };
      dispatch(fetchNotifications(params));
    }

    isMount.current = true;

    return () => {
      isMount.current = false;
    };
  }, [fetchPage, isMount]);

  useEffect(() => {
    const { body } = document;

    if (isMobile && open) {
      body.style.overflow = 'hidden';
    } else {
      body.style.overflow = '';
    }
  }, [isMobile, open]);

  useEffect(() => {
    setShowCheckbox(!!selectedNotifications.length);
  }, [selectedNotifications]);

  return (
    <div className={join('notifications', open && 'open')}>
      <div className="container">

        <div className="container-body">
          <div className="notifications-header">
            <BellIconSvg className="notifications-bell-icon" />
            <h4>{t('notifications')}</h4>
            <button onClick={handleShownNotifications} className="notifications-exit-btn-wrapper">
              <ExitIconSvg className="notifications-exit-icon" />
            </button>
          </div>

          <div className={join('actions', selectedNotifications.length === 0 && 'actions-disabled')}>
            <Button onClick={handleMarksAsAllNotifications} background="black">
              {notifications?.items.length === selectedNotifications.length ? t('cancel all') : t('select all')}
            </Button>

            <Tooltip zIndex={2001} withArrow label={t('mark as read')} position="bottom" offset={9}>
              <button disabled={markAsReadStatus || selectedNotifications.length === 0} onClick={handleFetchMarkAsRead} className="btn-notify green-bg">
                {
                  markAsReadStatus ? (
                    <LoadingOverlay
                      className="loader btn-loader-size"
                      visible
                      zIndex={1000}
                      overlayProps={{ radius: 'sm', blur: 2 }}
                      loaderProps={{ color: 'white', type: 'oval' }}
                    />
                  ) : <ReadIconSvg />
                }
              </button>
            </Tooltip>

            <Tooltip zIndex={2001} withArrow label={t('delete')} position="bottom" offset={9}>
              <button disabled={deleteStatus || selectedNotifications.length === 0} onClick={handleFetchDeleteNotifications} className="btn-notify read-bg">
                {
                  deleteStatus ? (
                    <LoadingOverlay
                      className="loader btn-loader-size"
                      visible
                      zIndex={1000}
                      overlayProps={{ radius: 'sm', blur: 2 }}
                      loaderProps={{ color: 'white', type: 'oval' }}
                    />
                  ) : <DeleteIconSvg />
                }
              </button>
            </Tooltip>
          </div>

          <Delimiter />
        </div>

        {status === EStatus.success && notifications && notifications.items.length > 0 && (
          <div className="messages">
            {
              localizedNotifications.map((message) => (
                <Notification
                  key={message.id}
                  notification={message}
                  className="observer-notify"
                  onSelected={handleSelectedNotifications}
                  selectedNotifications={selectedNotifications}
                  onDelete={handleFetchDeleteNotification}
                  onMarkAsReadById={handleFetchMarkAsReadById}
                  checkboxVisible={showCheckbox}
                />
              ))
            }
          </div>
        )}

        {status === EStatus.success && notifications && notifications.items.length === 0 && (
          <div className="message-empty">
            <MailIconSvg />
            <p>{t('no messages')}..</p>
          </div>
        )}

        {status === EStatus.rejected && (
          <div className="message-empty">
            <MailIconSvg />
            <p>{t('error receiving notifications')}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Notifications;
