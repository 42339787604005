import moment from 'moment';
import classNames from 'classnames';
import html2canvas from 'html2canvas';
import { useRef } from 'react';
import { Divider } from '@mantine/core';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { EStatus } from 'src/shared/types/global-types';
import { ETransferType } from 'src/widgets/transfer-history/model/types';
import { manipulateString } from 'src/pages/profile/libs/helpers/manipulate-string';
import { transferTypeIcon, translateTypeOfTranslation } from 'src/pages/accounts/libs/helpers';
import { selectTransfersHistory } from 'src/widgets/transfer-history/model/selectors';

import { ReactComponent as ShareIconSvg } from 'src/shared/assets/images/share.svg';
import { ReactComponent as LogoIconSvg } from 'src/shared/assets/images/header/logotype.svg';

import Button from 'src/shared/ui/button/button/button';
import CopyIcon from 'src/shared/assets/icons/copy-icon/copy-icon';
import useExchange from 'src/shared/libs/hooks/use-exchange';
import useSubAccount from 'src/shared/libs/hooks/use-sub-account-name';

import { BalanceType } from '../balance-type';
import { translateStatus } from './helpers';

import Empty from '../empty/empty';
import LoadingPageState from '../loading-page-state/loading-page-state';
import styles from './transfer-history-detail-moda-content.module.scss';

interface ITransferHistoryDetailModaContentProps {
    closeModal: () => void
}

const TransferHistoryDetailModaContent = ({ closeModal }: ITransferHistoryDetailModaContentProps) => {
  const { withdrawalDetail, withdrawalDetailStatus } = useSelector(selectTransfersHistory);
  const { getExchangeImageById, getExchangeNameById } = useExchange();
  const { getSubAccountNameById } = useSubAccount();
  const { t } = useTranslation();

  const componentRef = useRef(null);

  const withdrawalDate = moment(withdrawalDetail?.created_at).format('YYYY-MM-DD');

  const handleScreenshot = () => {
    if (componentRef.current) {
      html2canvas(componentRef.current).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const link = document.createElement('a');

        link.href = imgData;
        link.download = `Withdrawal - ${withdrawalDetail?.status} (${withdrawalDate}).png`;
        link.click();
      });
    }
  };

  if (withdrawalDetailStatus === EStatus.loading) {
    return (
      <div className={styles.loadingWrapper}>
        <LoadingPageState />
      </div>
    );
  }

  if (withdrawalDetailStatus === EStatus.rejected) {
    return (
      <div className={styles.loadingWrapper}>
        <Empty error>
          {t('internal error fetching user data')}
        </Empty>
      </div>
    );
  }

  const returnSubAccountType = () => {
    let type = 'SPOT';
    const exchangeName = getExchangeNameById(withdrawalDetail?.exchange_id);

    if (exchangeName === 'Bybit') type = 'UNIFIED';

    return type;
  };

  return withdrawalDetail ? (
    <div ref={componentRef} className={styles.transactionHistoryDetail}>
      <div className={styles.body}>
        <img className={styles.transferImage} src={transferTypeIcon(ETransferType.EXTERNAL_OUTPUT)} alt="type" />
        <p className={styles.typeTitle}>{translateTypeOfTranslation(ETransferType.EXTERNAL_OUTPUT)}</p>
        <p className={styles.transferValue}>{withdrawalDetail.quantity} USDT</p>
      </div>

      <div className={styles.content}>
        <div className={styles.section}>
          <p className={styles.sectionTitle}>{t('from_account')}</p>
          <div className={styles.sectioDetail}>
            <div className={styles.subAccount}>
              <img src={getExchangeImageById(withdrawalDetail.exchange_id)} alt="exchange" />
              <span>{getSubAccountNameById(withdrawalDetail.sub_account_id)}</span>
              <BalanceType type={returnSubAccountType()} />
            </div>
          </div>
        </div>

        <div className={styles.section}>
          <p className={styles.sectionTitle}>{t('to_account')}</p>
          <div className={styles.sectioDetail}>{manipulateString(withdrawalDetail.address)} <CopyIcon value={withdrawalDetail.address} /></div>
        </div>

        <div className={styles.section}>
          <p className={styles.sectionTitle}>{t('network')}</p>
          <div className={styles.sectioDetail}>
            {withdrawalDetail.network_symbol}
            {withdrawalDetail.network_symbol && <CopyIcon value={withdrawalDetail.network_symbol} />}
          </div>
        </div>
      </div>

      <Divider style={{ margin: '10px 0' }} color="#3A3A3A" />

      <div className={styles.section}>
        <p className={styles.sectionTitle}>TXID</p>
        <div className={styles.sectioDetail}>
          {withdrawalDetail.transaction_id ? manipulateString(withdrawalDetail.transaction_id) : '-'}
          <CopyIcon value={withdrawalDetail?.transaction_id || ''} />
        </div>
      </div>

      <div className={classNames(styles.statusWrapper)}>
        <span className={styles.transferStatus}>{t(translateStatus(withdrawalDetail.status))}</span>
        •
        <time className={styles.transferTime}>{withdrawalDate}</time>

      </div>

      {withdrawalDetail.error_description && (
        <div className={styles.errorMessage}>
          {withdrawalDetail.error_description}
        </div>
      )}

      <div className={styles.actions}>
        <Button onClick={closeModal} background="green-linear">{t('to_home')}</Button>
        <button onClick={handleScreenshot} className={styles.shareBtn}><ShareIconSvg /></button>
      </div>

      <div className={styles.logo}>
        <LogoIconSvg />
      </div>
    </div>
  ) : <div />;
};

export default TransferHistoryDetailModaContent;
