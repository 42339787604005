import { useTranslation } from 'react-i18next';
import { useLayoutEffect, useEffect } from 'react';
import { ModalSteps } from 'src/shared/types/global-types';
import { ReactComponent as HamsterPalmIconSvg } from 'src/shared/assets/images/hamster-palm.svg';
import './connection-ga-step-four.scss';

interface IConnectionGaStepFour extends ModalSteps {
  close: () => void
}

export const ConnectionGaStepFour = ({ step, setStep, close }: IConnectionGaStepFour) => {
  const { t } = useTranslation();
  useLayoutEffect(() => {
    if (step === 4) {
      const modal = document.querySelector('.m-54c44539') as HTMLElement | null;
      if (modal) {
        modal.style.flex = '0 0 600px';
        modal.classList.add('resize-modal');
      }
    }
  }, [step]);

  useEffect(() => () => {
    if (step === 4) {
      setStep(1);
    }
  }, [step]);

  return step === 4 ? (
    <div className="four-ga-modal-wrapper">
      <HamsterPalmIconSvg />

      <div className="four-modal-body">
        <h1>
          {t('congratulations')}
          <br />
          {t('now_you_are_safe')}
        </h1>

        <p className="four-modal-info">
          {t('security_settings_configured')}
        </p>
        <button onClick={close}>{t('profile_security')}</button>
      </div>
    </div>
  ) : null;
};
