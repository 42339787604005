import { useCallback, useState, useEffect } from 'react';
import { useDisclosure } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';
import {
  DragDropContext, Draggable, DropResult, Droppable,
} from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'src/app/store/store';
import { Exchange } from 'src/app/store/slices/exchanges/types';
import { Nullable } from 'src/shared/types/global-types';
import { SubAccountType } from 'src/app/store/slices/sub-accounts/types';
import { selectBalances } from 'src/app/store/slices/balance/selectors';
import { selectExchanges } from 'src/app/store/slices/exchanges/selectors';
import { ModalAddSubAccount } from 'src/shared/ui/modal/modal-add-subaccount';
import { getFromLocalStorage, saveToLocalStorage } from 'src/shared/libs/helpers/helper.lib';
import { CreateSubAccountParams, fetchCreateSubAccount } from 'src/app/store/slices/sub-accounts/thunks';
import { selectCreateSubAccountStatus, selectSubAccounts } from 'src/app/store/slices/sub-accounts/selectors';
import { ReactComponent as PlusIconSvg } from 'src/shared/assets/images/account/plus.svg';

import Button from 'src/shared/ui/button/button/button';
import useAlert from 'src/shared/libs/hooks/use-alert';
import SelectSkeleton from 'src/shared/ui/skeleton/select-skeleton/select-skeleton';
import SubAccount from '../subaccount/subaccount';
import { DEFAULT_ERROR_STATE, ERROR_MSG_LIST, SKELETON_ARRAY } from '../../constants';

import styles from './subaccounts-container.module.scss';

const SubaccountsContainer = () => {
  const dispatch = useAppDispatch();
  const { setAlertMessage } = useAlert();
  const { t } = useTranslation();

  const { balances } = useSelector(selectBalances);
  const { exchanges } = useSelector(selectExchanges);
  const subAccounts = useSelector(selectSubAccounts);
  const createSubAccountStatus = useSelector(selectCreateSubAccountStatus);

  const noExchange = exchanges === null;

  const [opened, { open, close }] = useDisclosure(false);

  const [subAccountName, setSubAccountName] = useState('');
  const [currentExchange, setCurrentExchange] = useState<Nullable<Exchange>>(exchanges ? exchanges[0] : null);
  const [errorMessage, setErrorMessage] = useState(DEFAULT_ERROR_STATE);
  const [orderedSubAccounts, setOrderedSubAccounts] = useState<Nullable<SubAccountType[]>>(null);

  const checkSubAccountNameExist = useCallback((name: string) => {
    if (currentExchange && subAccounts) {
      const subAccountsName = subAccounts.filter((subAccount) => subAccount.exchange_id === currentExchange.id).map((subAccount) => subAccount.user_name);

      return subAccountsName.includes(name.trim());
    }
    return false;
  }, [currentExchange, subAccounts]);

  const handleCloseModal = () => {
    setErrorMessage(DEFAULT_ERROR_STATE);
    setSubAccountName('');
    close();
  };

  const handleOpenModalAddSubAccount = () => {
    if (exchanges) {
      setCurrentExchange(exchanges[0]);
      setSubAccountName('');
    }
    open();
  };

  const handleSelectExchange = (exchange: Exchange) => {
    setCurrentExchange(exchange);
    setErrorMessage(DEFAULT_ERROR_STATE);
  };

  const handleCreateSubAccount = async () => {
    if (!currentExchange || subAccountName === '') return;

    if (checkSubAccountNameExist(subAccountName)) {
      setErrorMessage({ state: true, message: t('name_taken') });
      return;
    }
    if (!checkSubAccountNameExist(subAccountName)) {
      setErrorMessage(DEFAULT_ERROR_STATE);
    }
    if (subAccountName.length > 16) {
      setErrorMessage({ state: true, message: t('max_length_16') });
      return;
    }

    const newSubAccount: CreateSubAccountParams = {
      exchange_id: currentExchange.id,
      user_name: subAccountName,
    };

    const { payload } = await dispatch(fetchCreateSubAccount(newSubAccount));

    if (typeof payload === 'object') {
      close();
    } else if (typeof payload === 'string') {
      const message = ERROR_MSG_LIST[payload] || payload;
      setAlertMessage(message, 'error');

      setErrorMessage({ state: true, message: '' });
    } else {
      setAlertMessage('internal errror', 'error');
    }
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    if (!orderedSubAccounts || !subAccounts) return;

    const { source, destination } = result;
    const updatedSubAccountIds = Array.from(orderedSubAccounts).map((subAccount) => subAccount.id);
    const [removedId] = updatedSubAccountIds.splice(source.index, 1);
    updatedSubAccountIds.splice(destination.index, 0, removedId);

    saveToLocalStorage('subAccountsColumnOrder', updatedSubAccountIds);

    const sortedSubAccounts = subAccounts.slice().sort((a, b) => {
      const indexA = updatedSubAccountIds.indexOf(a.id);
      const indexB = updatedSubAccountIds.indexOf(b.id);
      return indexA - indexB;
    });

    setOrderedSubAccounts(sortedSubAccounts);
  };

  useEffect(() => {
    const localStorageOrder: number[] = getFromLocalStorage('subAccountsColumnOrder', []);

    if (localStorageOrder.length && subAccounts) {
      const orderedSubAccounts = localStorageOrder.map((id: number) => subAccounts.find((subAccount) => subAccount.id === id)).filter(Boolean) as SubAccountType[];

      if (orderedSubAccounts) setOrderedSubAccounts(orderedSubAccounts);
    } else {
      setOrderedSubAccounts(subAccounts);
    }
  }, [subAccounts]);

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <section className={styles.subContainer}>
        <header>
          <h1>{t('accounts')}</h1>
          {orderedSubAccounts && orderedSubAccounts.length > 2
          && (
            <Button
              disabled={noExchange}
              onClick={handleOpenModalAddSubAccount}
              maxWidth="190px"
              background={noExchange ? 'gray' : 'black'}
            >
              {t('open_new_account')}
            </Button>
          )}
        </header>

        <div className={styles.subaccountsWrapper}>
          {!orderedSubAccounts ? (
            <>
              {SKELETON_ARRAY.map((item) => (
                <SelectSkeleton key={item} radius="16px" height={207} />
              ))}
            </>
          ) : (
            <Droppable isDropDisabled droppableId="subAccounts" direction="horizontal">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef} className={styles.wrapper}>
                  {orderedSubAccounts?.map((subAccount, index) => (
                    <Draggable isDragDisabled key={subAccount.id} draggableId={subAccount.user_name} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={styles.subaccountWrapper}
                        >
                          <SubAccount
                            link={`/sub-accounts/${subAccount?.id}`}
                            subAccount={subAccount}
                            balance={balances?.sub_accounts.find((balance) => balance.sub_account_id === subAccount.id)}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {orderedSubAccounts && orderedSubAccounts.length < 3 && (
                    <button
                      disabled={noExchange}
                      onClick={handleOpenModalAddSubAccount}
                      className={styles.addSubAccount}
                    >
                      <span className={styles.plus}><PlusIconSvg />
                      </span><span>{t('open_new_account')}</span>
                    </button>
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          )}
        </div>

        <ModalAddSubAccount
          opened={opened}
          closeModal={handleCloseModal}
          exchanges={exchanges}
          addSubAccount={handleCreateSubAccount}
          selectExchange={handleSelectExchange}
          inputValue={subAccountName}
          inputOnChange={setSubAccountName}
          createStatus={createSubAccountStatus}
          errorMessage={errorMessage}
          currentExchange={currentExchange}
        />
      </section>
    </DragDropContext>
  );
};

export default SubaccountsContainer;
