import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as InfoSvg } from 'src/shared/assets/images/auth/info.svg';
import styles from './restore-password.module.scss';

const RestorePassword = () => {
  const { t } = useTranslation();
  return (
    <Link to="/auth/forgot-password">
      <button type="button" className={styles.restoreButton}>
        <InfoSvg />
        <span>{t('forgot_password')}</span>
      </button>
    </Link>
  );
};

export default RestorePassword;
