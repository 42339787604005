import { Loader } from '@mantine/core';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { EKycStatus } from 'src/app/store/slices/user/types';
import { selectUser } from 'src/app/store/slices/user/selectors';
import { selectKyc } from 'src/app/store/slices/kyc/selectors';
import { join } from 'src/shared/libs/helpers/helper.lib';
import { EStatus } from 'src/shared/types/global-types';
import { ReactComponent as VerifyIconSvg } from 'src/shared/assets/images/user-verify.svg';
import './verification.scss';

interface IVerificationProps {
    openModal: () => void
}

const Verification = ({ openModal } : IVerificationProps) => {
  const { t } = useTranslation();
  const { status } = useSelector(selectKyc);
  const { kycStatus } = useSelector(selectUser);

  const statusClassName = kycStatus === EKycStatus.IN_PROCESS ? 'process'
    : kycStatus === EKycStatus.VERIFIED ? 'verified'
      : kycStatus === EKycStatus.NOT_VERIFIED ? 'no_verified'
        : kycStatus === EKycStatus.CREATED ? 'created'
          : kycStatus === EKycStatus.IN_REVIEW ? 'process'
            : '';

  return (
    <div className="switch-wrapper">
      <div className={join('verification-status', statusClassName)}>
        {kycStatus === EKycStatus.VERIFIED && (
          <span className="status-text verify-kyc-state">
            <VerifyIconSvg />
            {t('verification passed')}
          </span>
        )}
        {kycStatus === EKycStatus.CREATED && (
          <button onClick={openModal}>
            {status === EStatus.loading
              ? <Loader size={16} color="white" />
              : t('verify')}
          </button>
        )}
        {kycStatus === EKycStatus.IN_PROCESS && (
          <button onClick={openModal}> {
            status === EStatus.loading
              ? <Loader size={16} color="white" />
              : `${t('in progress')}...`

          }.
          </button>
        )}
        {kycStatus === EKycStatus.IN_REVIEW && (
          <span className="status-text">{t('under review')}
          </span>
        )}
        {kycStatus === EKycStatus.NOT_VERIFIED && (
          <button onClick={openModal}>
            {status === EStatus.loading ? <Loader size={16} color="white" /> : t('retry_again')}
          </button>
        )}
      </div>

      {/* <div className="switch" /> */}
    </div>
  );
};

export default Verification;
