import { useTranslation } from 'react-i18next';
import { ReactComponent as BookIconSvg } from 'src/shared/assets/images/profile/book.svg';
import { ReactComponent as SmileIconSvg } from 'src/shared/assets/images/profile/smile.svg';
import { ReactComponent as CheckMarkIconSvg } from 'src/shared/assets/images/check-mark.svg';
import DropdownMenuItem from './dropdown-menu-item/dropdown-menu-item';

import './dropdown-menu.scss';

const DropdownMenu = () => {
  const { t } = useTranslation();
  const content = (
    <>
      <div className="list list_possibilities">
        <div className="list-item">
          <CheckMarkIconSvg />
          <p>{t('withdrawals without limits')}</p>
        </div>

        <div className="list-item">
          <CheckMarkIconSvg />
          <p>{t('email change')}</p>
        </div>

        <div className="list-item">
          <CheckMarkIconSvg />
          <p>{t('yubikey connection')}</p>
        </div>

        <div className="list-item">
          <CheckMarkIconSvg />
          <p>{t('identity verification on account recovery')}</p>
        </div>
      </div>

      <span className="requirements">{t('requirements')}</span>

      <div className="list">
        <div className="list-item">
          <BookIconSvg />
          <p>{t('identity document')}</p>
        </div>

        <div className="list-item">
          <SmileIconSvg />
          <p>{t('face photo')}</p>
        </div>
      </div>
    </>
  );

  return (
    <div className="dropdown-menu">
      <DropdownMenuItem verificationLevel={t('basic verification')}>{content}</DropdownMenuItem>
    </div>
  );
};

export default DropdownMenu;
