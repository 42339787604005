import moment from 'moment';
import {
  memo, useCallback, useEffect, useState, useLayoutEffect,
  useRef,
  useMemo,
} from 'react';
import className from 'classnames';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDisclosure } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';
import {
  DragDropContext, Droppable, Draggable, DropResult,
} from 'react-beautiful-dnd';
import { LoadingOverlay, Modal, Tooltip } from '@mantine/core';
import { useAppDispatch } from 'src/app/store/store';
import { EThemeMode } from 'src/app/store/slices/user/types';
import { selectExchanges } from 'src/app/store/slices/exchanges/selectors';
import { removeAssets, removeSearchAssets } from 'src/app/store/slices/assets/slice';
import { selectAssets, selectSearchAssets } from 'src/app/store/slices/assets/selectors';
import {
  AssetsParams, SearchAssetsParamsType, fetchAssets, fetchSearchAssets,
} from 'src/app/store/slices/assets/thunks';

import { ReactComponent as SortIconSvg } from 'src/shared/assets/images/sort.svg';
import { ReactComponent as TipIconSvg } from 'src/shared/assets/images/tip-icon.svg';
import ExchangeIconSvg from 'src/shared/assets/images/lot.png';

import Empty from 'src/entities/empty/empty';
import Button from 'src/shared/ui/button/button/button';
import Select from 'src/shared/ui/select/select';
import useAlert from 'src/shared/libs/hooks/use-alert';
import InputForm from 'src/shared/ui/input/input-form/input-form';
import useExchange from 'src/shared/libs/hooks/use-exchange';
import useSubAccount from 'src/shared/libs/hooks/use-sub-account-name';
import SelectSkeleton from 'src/shared/ui/skeleton/select-skeleton/select-skeleton';
import DataPickerRange from 'src/shared/ui/date/data-range-picker';
import TransferHistoryDetailModaContent from 'src/entities/transfer-history-detail-moda-content/transfer-history-detail-moda-content';

import { EStatus, Nullable } from 'src/shared/types/global-types';
import { BalanceType } from 'src/entities/balance-type';
import { ColumnOrderItem } from 'src/pages/trading/model/types';
import { manipulateString } from 'src/pages/profile/libs/helpers/manipulate-string';
import { useIntersectionObserver } from 'src/shared/libs/hooks/use-observer';
import {
  debounce, getDate, saveToLocalStorage,
} from 'src/shared/libs/helpers/helper.lib';
import { useTheme } from 'src/shared/libs/hooks/use-theme';
import { HIDDEN_BALANCES } from 'src/shared/constants/constants';
import { selectHiddenBalance } from 'src/pages/settings/model/selectors';
import { removeTransferHistory, removeWithdrawaDetail } from './model/slice';
import { selectTransfersHistory } from './model/selectors';
import {
  fetchTransfersHistory, TransfersHistoryParams, subscribeTransfers, unsubscribeTransfers,
  WithdrawalDetailParams,
  fetchWithdrawalDetail,
} from './model/thunks';
import { ETransferType, SortFieldTransferHistory, TransferHistoryType } from './model/types';
import { ALL_ASSET, transferHistoryColumnLocalStorage, TRANSFER_HISTORY_COLUMNS } from './constants';
import { transferTypeIcon, transferTypeTranslations, translateTypeOfTranslation } from '../../pages/accounts/libs/helpers';

import styles from './transfer-history.module.scss';

const TransferHistory = memo(() => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { theme } = useTheme();
  const { setAlertMessage } = useAlert();
  const { getSubAccountNameById } = useSubAccount();

  const tableContainerRef = useRef<HTMLDivElement>(null);

  const [openedWithdrawalDetail, { open: openWithdrawalDetail, close: closeWithdrawalDetail }] = useDisclosure(false);

  const assets = useSelector(selectAssets);
  const searchAssets = useSelector(selectSearchAssets);
  const balanceVisible = useSelector(selectHiddenBalance);
  const { exchanges } = useSelector(selectExchanges);
  const { transfersHistory, status } = useSelector(selectTransfersHistory);
  const { getExchangeImageById, getExchangeNameById, getExchangeIdByExchangeName } = useExchange();

  const [currentSortColumn, setCurrentSortColumn] = useState('');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [sortField, setSortField] = useState<Nullable<SortFieldTransferHistory>>(null);
  const [columnOrder, setColumnOrder] = useState<ColumnOrderItem[]>(transferHistoryColumnLocalStorage || TRANSFER_HISTORY_COLUMNS);

  const [fetchPage, setFetchPage] = useState(1);
  const [assetSearch, setAssetSearch] = useState('');
  const [fetchAssetsPage, setFetchAssetsPage] = useState(1);
  const [currentTransferType, setCurrentTransferType] = useState('ALL_TRANSFERS');
  const [currentTransferSymbol, setCurrentTransferSymbol] = useState('all');

  const sortedTransfersHistory = useMemo(() => {
    if (!transfersHistory) return [];

    return transfersHistory.items.slice().sort((a, b) => {
      if (sortField === 'quantity') {
        const quantityA = parseFloat(a.quantity);
        const quantityB = parseFloat(b.quantity);

        if (quantityA < quantityB) {
          return sortOrder === 'asc' ? -1 : 1;
        }
        if (quantityA > quantityB) {
          return sortOrder === 'asc' ? 1 : -1;
        }
        return 0;
      }

      if (sortField) {
        if (a[sortField] < b[sortField]) {
          return sortOrder === 'asc' ? -1 : 1;
        }
        if (a[sortField] > b[sortField]) {
          return sortOrder === 'asc' ? 1 : -1;
        }
      }
      return 0;
    });
  }, [sortOrder, sortField, transfersHistory]);

  useIntersectionObserver(fetchPage, transfersHistory?.meta.total_pages || 1, sortedTransfersHistory, 'history-item', setFetchPage, 9);
  useIntersectionObserver(fetchAssetsPage, assets?.meta.total_pages || 1, assets?.items, 'observer-asset', setFetchAssetsPage, 19);

  const { currentDateAsString, sevenDaysAgoAsString } = getDate();
  const [dateRange, setDateRange] = useState<any>([sevenDaysAgoAsString, currentDateAsString]);
  const [startDate, endDate] = dateRange;

  const debouncedFetch = useCallback(
    debounce((searchParams: SearchAssetsParamsType) => {
      dispatch(fetchSearchAssets(searchParams));
    }, 500) as any,
    [dispatch],
  );

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const newOrder = [...columnOrder];
    const [movedColumn] = newOrder.splice(result.source.index, 1);
    newOrder.splice(result.destination.index, 0, movedColumn);

    setColumnOrder(newOrder);

    saveToLocalStorage('transferHistoryColumnOrder', newOrder);
  };

  const handleSort = (field: SortFieldTransferHistory) => {
    setCurrentSortColumn(field);

    if (field === sortField) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
  };

  const handleRemoveTransferHistory = () => {
    dispatch(removeTransferHistory());

    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTo(0, 0);
    }
  };

  const handleResetFilter = () => {
    const currentDate = moment();
    const startDateCurrent = currentDate.clone().subtract(7, 'days');

    const start = startDateCurrent.startOf('day').format('YYYY-MM-DD HH:mm:ss');
    const end = currentDate.endOf('day').format('YYYY-MM-DD HH:mm:ss');

    const checkStartDate = moment(startDate).format('YYYY-MM-DD');
    const checkEndDate = moment(endDate).format('YYYY-MM-DD');

    if (checkStartDate === moment(start).format('YYYY-MM-DD') && checkEndDate === moment(end).format('YYYY-MM-DD') && currentTransferType === 'ALL_TRANSFERS' && currentTransferSymbol === 'Все') return;

    handleRemoveTransferHistory();

    setCurrentTransferType('ALL_TRANSFERS');
    setCurrentTransferSymbol('Все');
    setFetchPage(1);
    setDateRange([start, end]);
  };

  const handleTransferTypeSelect = (type: string) => {
    handleRemoveTransferHistory();
    setCurrentTransferType(type);
    setFetchPage(1);
  };

  const transferDirection = (subAccountid: Nullable<number>, address: Nullable<string>, exchangeId: number) => {
    if (subAccountid) return getSubAccountNameById(subAccountid);

    if (address) return manipulateString(address);

    return getExchangeNameById(exchangeId);
  };

  const handleSetCurrentTransferSymbol = (symbol: string) => {
    if (symbol === currentTransferSymbol) return;

    setFetchPage(1);
    setCurrentTransferSymbol(symbol);
    handleRemoveTransferHistory();
  };

  const handleCloseWithdrawalDetail = () => {
    closeWithdrawalDetail();
    dispatch(removeWithdrawaDetail());
  };

  const handleFetchWithdrawalDetail = async (transfer: TransferHistoryType) => {
    if (transfer.transfer_type === ETransferType.EXTERNAL_OUTPUT && transfer.withdrawal_request_id) {
      openWithdrawalDetail();

      const params: WithdrawalDetailParams = {
        withdrawal_request_id: transfer.withdrawal_request_id,
      };

      const { payload } = await dispatch(fetchWithdrawalDetail(params));

      if (typeof payload === 'string') setAlertMessage(payload, 'error');
    }
  };

  useLayoutEffect(() => {
    subscribeTransfers();

    return () => {
      dispatch(removeAssets());
      handleRemoveTransferHistory();
      unsubscribeTransfers();
    };
  }, []);

  useLayoutEffect(() => {
    const path = location.pathname.split('/');
    const id = path[2];

    if (id) {
      const fetchParams: TransfersHistoryParams = {
        from_date: moment(startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        to_date: moment(endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        page: fetchPage,
        limit: 10,
        sub_account_id: Number(id),
      };

      if (startDate && endDate) {
        if (currentTransferSymbol !== 'Все') {
          fetchParams.symbol = currentTransferSymbol;
        }

        if (currentTransferType !== 'ALL_TRANSFERS') {
          fetchParams.transfer_type = currentTransferType;
        }

        dispatch(fetchTransfersHistory(fetchParams));
      }
    } else {
      const fetchParams: TransfersHistoryParams = {
        from_date: moment(startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        to_date: moment(endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        page: fetchPage,
        limit: 10,
      };

      if (startDate && endDate) {
        if (currentTransferSymbol !== 'Все') {
          fetchParams.symbol = currentTransferSymbol;
        }

        if (currentTransferType !== 'ALL_TRANSFERS') {
          fetchParams.transfer_type = currentTransferType;
        }

        dispatch(fetchTransfersHistory(fetchParams));
      }
    }
  }, [startDate, endDate, fetchPage, currentTransferSymbol, currentTransferType, location]);

  useEffect(() => {
    const path = location.pathname.split('/');
    const id = path[2];

    if (id) {
      handleRemoveTransferHistory();
      setFetchPage(1);
    }
  }, [location]);

  useEffect(() => {
    const exchangeId = getExchangeIdByExchangeName('Binance');

    if (!exchangeId) return;

    const params: AssetsParams = {
      asset_trading_type: 'SPOT',
      limit: 20,
      page: fetchAssetsPage,
      exchange_id: exchangeId,
    };

    dispatch(fetchAssets(params));
  }, [fetchAssetsPage, exchanges]);

  useEffect(() => {
    const exchangeId = getExchangeIdByExchangeName('Binance');

    if (!exchangeId) return;

    if (assetSearch.length > 1) {
      const searchAssetsParams: SearchAssetsParamsType = {
        query: assetSearch.toUpperCase(),
        // asset_trading_type: 'SPOT',
        exchange_id: exchangeId,
      };

      debouncedFetch.execute(searchAssetsParams);
    }

    if (assetSearch.length === 0) {
      dispatch(removeSearchAssets());
      debouncedFetch.stop();
    }
  }, [assetSearch]);

  return (
    <section className={styles.transferHistory}>
      <header className={styles.header}>
        <div className={styles.dataWrapper}>
          <DataPickerRange whiteMode={theme === 'light'} removeData={handleRemoveTransferHistory} setPage={setFetchPage} setDateRange={setDateRange} dateRange={dateRange} />
        </div>

        <InputForm maxWidth="200px" title={t('asset')}>
          { assets ? (
            <Select
              isActiveSearch
              searchValue={assetSearch}
              isSearch={setAssetSearch}
              isSelectValue={(
                <div className={styles.selectItem}>
                  <span className="short-name">{t(currentTransferSymbol)}</span>
                </div>
              )}
            >
              {
                searchAssets ? searchAssets.map((asset) => (
                  <div
                    key={asset.id}
                    className={className(styles.selectItem, 'observer-asset')}
                    role="button"
                    tabIndex={0}
                    onClick={() => handleSetCurrentTransferSymbol(asset.symbol)}
                    onKeyDown={() => handleSetCurrentTransferSymbol(asset.symbol)}
                  >
                    <span className="short-name">{asset.symbol}</span>
                  </div>
                ))
                  : [ALL_ASSET, ...assets.items].map((asset) => (
                    <div
                      key={asset.id}
                      className={className(styles.selectItem, 'observer-asset')}
                      role="button"
                      tabIndex={0}
                      onClick={() => handleSetCurrentTransferSymbol(asset.symbol)}
                      onKeyDown={() => handleSetCurrentTransferSymbol(asset.symbol)}
                    >
                      <span className="short-name">{t(asset.symbol)}</span>
                    </div>
                  ))
              }
            </Select>
          ) : <SelectSkeleton />}
        </InputForm>

        <InputForm maxWidth="220px" title={t('transfer_type')}>
          {transferTypeTranslations ? (
            <Select
              isActiveSearch={false}
              isSelectValue={(
                <div className={styles.selectItem}>
                  { currentTransferType !== 'ALL_TRANSFERS' && <img src={transferTypeIcon(currentTransferType)} alt="type" />}
                  { t(transferTypeTranslations[currentTransferType])}
                </div>
              )}
            >
              {
                Object.entries(transferTypeTranslations).map(([key, value]) => (
                  <div
                    key={key}
                    className={styles.selectItem}
                    role="button"
                    tabIndex={0}
                    onClick={() => handleTransferTypeSelect(key)}
                    onKeyDown={() => handleTransferTypeSelect(key)}
                  >
                    {key !== 'ALL_TRANSFERS' && <img src={transferTypeIcon(key)} alt="type" />}
                    <span className="short-name">{t(value)}</span>
                  </div>
                ))
              }
            </Select>
          ) : <SelectSkeleton /> }
        </InputForm>

        <div className={styles.button}>
          <Button onClick={handleResetFilter} maxWidth="" background={theme === EThemeMode.DARK ? 'white' : 'black'}>{t('reset filters')}</Button>
        </div>
      </header>

      <div ref={tableContainerRef} className={styles.historyTableWrapper}>
        <DragDropContext onDragEnd={onDragEnd}>
          <table className={styles.tablePositions}>
            <thead>
              <Droppable droppableId="columns" direction="horizontal">
                {(provided, snapshot) => (
                  <tr
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={{
                      background: snapshot.isDraggingOver ? (theme === 'light' ? '#131313' : '#474747') : (theme === 'light' ? '#131313' : '#474747'),
                    }}
                  >
                    {columnOrder.map((column, index) => (
                      <Draggable key={column.key} draggableId={column.key} index={index}>
                        {(provided, snapshot) => (
                          <th
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              ...provided.draggableProps.style,
                              background: snapshot.isDragging ? 'transparent' : (theme === 'light' ? '#131313' : '#474747'),
                              color: snapshot.isDragging ? 'var(--color-main)' : '#BCBCBC',
                              display: snapshot.isDragging ? 'flex' : '',
                            }}
                          >
                            {column.key === 'created_at' && (
                              <div
                                role="button"
                                tabIndex={0}
                                onKeyDown={() => handleSort(column.key as SortFieldTransferHistory)}
                                onClick={() => handleSort(column.key as SortFieldTransferHistory)}
                                className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                              >
                                {t(column.label)}
                                <SortIconSvg />
                              </div>
                            )}
                            {column.key === 'transfer_type' && (
                              <div
                                role="button"
                                tabIndex={0}
                                onKeyDown={() => handleSort(column.key as SortFieldTransferHistory)}
                                onClick={() => handleSort(column.key as SortFieldTransferHistory)}
                                className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                              >
                                {t(column.label)}
                                <SortIconSvg />
                              </div>
                            )}
                            {column.key === 'from_sub_account_id' && (
                              <div
                                role="button"
                                tabIndex={0}
                                onKeyDown={() => handleSort(column.key as SortFieldTransferHistory)}
                                onClick={() => handleSort(column.key as SortFieldTransferHistory)}
                                className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                              >
                                {t(column.label)}
                                <SortIconSvg />
                              </div>
                            )}
                            {column.key === 'to_sub_account_id' && (
                              <div
                                role="button"
                                tabIndex={0}
                                onKeyDown={() => handleSort(column.key as SortFieldTransferHistory)}
                                onClick={() => handleSort(column.key as SortFieldTransferHistory)}
                                className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                              >
                                {t(column.label)}
                                <SortIconSvg />
                              </div>
                            )}
                            {column.key === 'quantity' && (
                              <div
                                role="button"
                                tabIndex={0}
                                onKeyDown={() => handleSort(column.key as SortFieldTransferHistory)}
                                onClick={() => handleSort(column.key as SortFieldTransferHistory)}
                                className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                              >
                                {t(column.label)}
                                <SortIconSvg />
                              </div>
                            )}
                            {column.key === 'asset_id' && (
                              <div
                                role="button"
                                tabIndex={0}
                                onKeyDown={() => handleSort(column.key as SortFieldTransferHistory)}
                                onClick={() => handleSort(column.key as SortFieldTransferHistory)}
                                className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                              >
                                {t(column.label)}
                                <SortIconSvg />
                              </div>
                            )}
                          </th>
                        )}
                      </Draggable>
                    ))}
                    { provided.placeholder}
                  </tr>
                )}
              </Droppable>
            </thead>
            {sortedTransfersHistory && sortedTransfersHistory.length > 0 && EStatus.success && (
              <tbody>
                {sortedTransfersHistory.map((transfer) => (
                  <tr style={{ cursor: transfer.transfer_type === ETransferType.EXTERNAL_OUTPUT ? 'pointer' : 'unset' }} onClick={() => handleFetchWithdrawalDetail(transfer)} key={transfer.id} className="history-item">
                    {columnOrder.map((column) => (
                      <td key={column.key}>
                        {column.key === 'created_at' && (
                          <div className={styles.createdAt}>
                            {moment(transfer.created_at).format('YYYY-MM-DD HH:mm')}
                          </div>
                        )}
                        {column.key === 'transfer_type' && (
                          <div className={styles.transferType}>
                            <img src={transferTypeIcon(transfer.transfer_type)} alt="type" />
                            {t(translateTypeOfTranslation(transfer.transfer_type) || '')}
                            <Tooltip withArrow label={t('view_details')} position="right" offset={5}>
                              <TipIconSvg style={{ display: (transfer.transfer_type === ETransferType.EXTERNAL_OUTPUT && transfer.withdrawal_request_id) ? 'flex' : 'none' }} />
                            </Tooltip>
                          </div>
                        )}
                        {column.key === 'from_sub_account_id' && (
                          <div className={styles.subAccount}>
                            <img src={getExchangeImageById(transfer.exchange_id) || ExchangeIconSvg} alt="exchange" />
                            <span>{transferDirection(transfer.from_sub_account_id, transfer.from_address, transfer.exchange_id)}</span>
                            <BalanceType type={transfer.from_transfer_trading_type} />
                          </div>
                        )}
                        {column.key === 'to_sub_account_id' && (
                          <div className={styles.subAccount}>
                            <img src={getExchangeImageById(transfer.exchange_id) || ExchangeIconSvg} alt="exchange" />
                            <span>{transferDirection(transfer.to_sub_account_id, transfer.to_address, transfer.exchange_id)}</span>
                            <BalanceType type={transfer.to_transfer_trading_type} />
                          </div>
                        )}
                        {column.key === 'quantity' && (
                          <div className={styles.quantity}>
                            {!balanceVisible ? transfer.quantity : HIDDEN_BALANCES}
                          </div>
                        )}
                        {column.key === 'asset_id' && (
                          <div className={styles.assetId}>
                            {transfer.asset.symbol}
                          </div>
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </DragDropContext>

        {status === EStatus.loading && sortedTransfersHistory && sortedTransfersHistory.length === 0 && (
          <div className={styles.loadingWrapper}>
            <LoadingOverlay
              className="loader"
              visible
              zIndex={1000}
              overlayProps={{ radius: 'sm', blur: 2 }}
              loaderProps={{ color: '#00C37C', type: 'dots' }}
            />
          </div>
        )}
        {sortedTransfersHistory && sortedTransfersHistory.length === 0 && status === EStatus.success && (
          <Empty maxWidthText="200px">
            {t('no-history-for-selected-period')}
          </Empty>
        )}
        {status === EStatus.rejected && (
          <Empty>
            {t('internal error fetch')}
          </Empty>
        )}
        {status === EStatus.loading && sortedTransfersHistory && sortedTransfersHistory.length > 0 && (
          <div className={styles.loadingItemWrapper}>
            <LoadingOverlay
              className={`loader ${styles.loader}`}
              visible
              zIndex={1000}
              overlayProps={{ radius: 'sm', blur: 2 }}
              loaderProps={{ color: '#00C37C', type: 'dots' }}
            />
          </div>
        )}
      </div>

      <Modal
        radius={16}
        size="xs"
        opened={openedWithdrawalDetail}
        onClose={handleCloseWithdrawalDetail}
        className="modal-custom-overflow hidden-title blue-filter"
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
      >
        <TransferHistoryDetailModaContent closeModal={handleCloseWithdrawalDetail} />
      </Modal>
    </section>
  );
});

export default TransferHistory;
