import { batch } from 'react-redux';
import { useEffect } from 'react';
import { useAppDispatch } from 'src/app/store/store';
import { fetchBalance } from 'src/app/store/slices/balance/thunks';
import { connectWebSocket } from 'src/shared/api/websocket.instance';
import { fetchUser, fetchUserKycStatus, fetchUserSettings } from 'src/app/store/slices/user/thunks';
import { fetchTradeSettings } from 'src/pages/settings/model/thunks';
import { fetchSubAccounts } from 'src/app/store/slices/sub-accounts/thunks';
import { fetchExchanges } from 'src/app/store/slices/exchanges/thunks';
import { fetchPositionsPnl } from 'src/pages/trading/model/thunks';
import { fetchNotifications } from 'src/widgets/notifications/model/thunks';

const useFetchUserData = (isAuth: boolean) => {
  const dispatch = useAppDispatch();

  const fetchBalancesAndConnectWebSocket = async () => {
    const { payload } = await dispatch(fetchBalance());

    if (payload) dispatch(connectWebSocket());
  };

  useEffect(() => {
    if (isAuth) {
      batch(() => {
        dispatch(fetchUser());
        dispatch(fetchUserKycStatus());
        dispatch(fetchTradeSettings());
        dispatch(fetchUserSettings());
        dispatch(fetchSubAccounts());
        dispatch(fetchExchanges());
        dispatch(fetchPositionsPnl());
        dispatch(fetchNotifications({ limit: 20, page: 1 }));
        fetchBalancesAndConnectWebSocket();
      });
    }
  }, [isAuth, dispatch]);
};

export default useFetchUserData;
