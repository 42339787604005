import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'src/app/store/store';
import { EStatus, Nullable } from 'src/shared/types/global-types';
import { selectSubAccountSettings } from 'src/app/store/slices/sub-accounts/selectors';

import Select from 'src/shared/ui/select/select';
import Button from 'src/shared/ui/button/button/button';
import InputForm from 'src/shared/ui/input/input-form/input-form';
import useAlert from 'src/shared/libs/hooks/use-alert';

import { MARGIN_MODE } from '../../constants';
import { selectLeverages } from '../../model/selectors';
import { addMarginModeToQueue } from '../../model/slice';
import { ChangeMarginMode, fetchMarginMode } from '../../model/thunks';
import './margin-mode-setting.scss';

interface IMarginModeSetting {
  subAccountId: Nullable<number>
}

const MarginModeSetting = ({ subAccountId }: IMarginModeSetting) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { setAlertMessage } = useAlert();

  const [marginModeAll, setMarginModeAll] = useState(1);

  const { marginModeQueue, statusMarginMode } = useSelector(selectLeverages);
  const subAccountSettings = useSelector(selectSubAccountSettings);

  const checkSubAccountMarginModeStatus = () => marginModeQueue.some((id) => id === subAccountId);

  const handleOnChangeMarginMode = async () => {
    if (!subAccountId || !subAccountSettings) return;

    const params: ChangeMarginMode = {
      subAccountId,
      marginMode: marginModeAll,
    };

    const { payload } = await dispatch(fetchMarginMode(params));

    if (payload === true) {
      setAlertMessage(t('in_process'), 'warning');
      dispatch(addMarginModeToQueue(subAccountId));
    } else if (payload === 'TASK_IN_PROGRESS') {
      setAlertMessage(t('in_process'), 'warning');
      dispatch(addMarginModeToQueue(subAccountId));
    } else {
      setAlertMessage('internal error', 'error');
    }
  };

  const selectMarginMode = MARGIN_MODE.find((item) => item.value === marginModeAll)?.name as string;

  useEffect(() => {
    if (subAccountSettings) {
      setMarginModeAll(subAccountSettings.margin_mode);
    }
  }, [subAccountSettings]);

  return (
    <div className="common-settings">
      <InputForm title={t('margin_mode')}>
        <Select
          isActiveSearch={false}
          isSelectValue={<span className="marge-item">{t(selectMarginMode)}</span>}
        >
          {MARGIN_MODE.map((item) => (
            <span
              className="marge-item"
              key={item.value}
              tabIndex={0}
              role="button"
              onClick={() => setMarginModeAll(item.value)}
              onKeyDown={() => setMarginModeAll(item.value)}
            >{t(item.name)}
            </span>
          )) }
        </Select>
      </InputForm>

      <Button
        disabled={statusMarginMode === EStatus.loading || checkSubAccountMarginModeStatus()}
        loading={statusMarginMode === EStatus.loading}
        onClick={handleOnChangeMarginMode}
        background={checkSubAccountMarginModeStatus() ? 'orange' : 'green'}
      >
        {checkSubAccountMarginModeStatus() ? `${t('in_process')}` : t('apply_to_all_instruments')}
      </Button>
    </div>
  );
};

export default MarginModeSetting;
