import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { handleSupportTelegram } from 'src/shared/libs/helpers/helper.lib';
import HamsterLogout1IconSvg from 'src/shared/assets/images/hamster-logout1.svg';
import HamsterLogout2IconSvg from 'src/shared/assets/images/hamster-logout2.svg';
import Button from 'src/shared/ui/button/button/button';
import styles from './logout-modal-content.module.scss';

interface ILogoutModalContentProps {
  logOut: () => void;
}

const LogoutModalContent = ({ logOut }: ILogoutModalContentProps) => {
  const { t } = useTranslation();
  const [isIcon1Visible, setIsIcon1Visible] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsIcon1Visible(false);

      const timeout = setTimeout(() => {
        setIsIcon1Visible(true);
      }, 200);

      return () => clearTimeout(timeout);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={styles.content}>
      {isIcon1Visible ? <img src={HamsterLogout1IconSvg} alt="hamster" /> : <img src={HamsterLogout2IconSvg} alt="hamster" />}
      <h1>{t('login_another_device')}</h1>
      <p>{t('login_another_device_hint')}</p>
      <Button maxWidth="150px" onClick={logOut} background="green">{t('auth')}</Button>
      <button onClick={handleSupportTelegram} className={styles.supportBtn}>{t('request_support')}</button>
    </div>
  );
};

export default LogoutModalContent;
