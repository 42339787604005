import { useTranslation } from 'react-i18next';

interface IBinanceHintContentProps {
  rebatePercent: string
}
const BinanceHintContent = ({ rebatePercent }: IBinanceHintContentProps) => {
  const { t } = useTranslation();
  return (
    <div>
      {t('custom bonus condition')} {rebatePercent}% {t('bonus from trading fees')}

      <br />
      <br />

      {t('bonus credited to futures wallet')}
    </div>
  );
};

export default BinanceHintContent;
