import { useSelector } from 'react-redux';
import { useLayoutEffect } from 'react';
import { useAppDispatch } from 'src/app/store/store';
import { useTranslation } from 'react-i18next';
import { AuthLayout } from 'src/layouts';
import { useChangePageTitle } from 'src/shared/libs/hooks/use-change-page-title';

import LanguageSelection from 'src/entities/language-selection/language-selection';
import AbstractIconsSvg from 'src/shared/assets/images/auth/abstract.svg';
import AuthBody from '../components/auth-body/auth-body';
import AuthTitle from '../components/auth-title/auth-title';
import AuthFooter from '../components/auth-footer/auth-footer';
import RegistrationForm from './components/registration-form/registration-form';
import { setSlidesPage } from '../login/model/slice';
import { selectSlidesPage } from '../login/model/selectors';
import { informSlides, infromTitleSilde } from '../lib/helpers/constants';

import styles from './registration.module.scss';

const pageTitle = 'system_register';

const LoginPage = () => {
  useChangePageTitle(pageTitle);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const slidePage = useSelector(selectSlidesPage);

  useLayoutEffect(() => {
    dispatch(setSlidesPage(1));
  }, []);

  return (
    <AuthLayout page="">
      <div className={styles.container}>
        <section className={styles.bodyWrapper}>
          <AuthBody
            activeSlide="second"
            titleUp={t('security')}
            titleDown={t('security_first')}
            description={t('simple_rules_for_security')}
            informList={informSlides[slidePage]}
            informTitle={infromTitleSilde[slidePage]}
            dynamicItems
          />
        </section>

        <section className={styles.formWrapper}>
          <div className={styles.formContainer}>
            <LanguageSelection bgColor="#131313" />
            <AuthTitle visible step="first">{t('signup')} <br /> {t('to_personal_account')}</AuthTitle>
            <RegistrationForm />
          </div>

          <AuthFooter />
        </section>

        <img className={styles.abstract} src={AbstractIconsSvg} alt="abstract" />
      </div>
    </AuthLayout>
  );
};

export default LoginPage;
