import { Exchange } from 'src/app/store/slices/exchanges/types';

export const referralsBonusesTableColumn = [
  { key: 'user_id', label: 'User ID', visible: true },
  { key: 'payment_date', label: 'payment_date' },
  { key: 'trading_date', label: 'trading_date' },
  { key: 'exchange', label: 'exchange' },
  { key: 'trading_type', label: 'type' },
  { key: 'cummulative_quantity', label: 'cumulative_quantity', type: 'numberic' },
  { key: 'commission', label: 'commission', type: 'numberic' },
  { key: 'bonus', label: 'bonus_income', type: 'numberic' },
];

export const ALL_EXCHANGES_ITEM: Exchange = {
  created_at: '',
  id: 0,
  image: '',
  is_active: true,
  name: 'all',
  updated_at: '',
};

export const ALL_TRADING_TYPE_ITEM = {
  name: 'all',
  type: null,
};
