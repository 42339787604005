import { AxiosError, AxiosResponse } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { socket } from 'src/shared/api/websocket.instance';
import { $api } from 'src/shared/api/axios.instance';
import { ROUTE_PATH } from 'src/shared/constants/route.path';
import {
  EInputEventType, EOutputEventType, ISubscribeEvent, IUnsubscribeEvent,
} from 'src/shared/types/interfaces';
import { devConsoleLog } from 'src/shared/libs/helpers/helper.lib';
import { store } from 'src/app/store/store';
import { addSubsciptionQueue, removeSubsciptionQueue } from 'src/app/store/slices/subscriptions/slice';
import {
  DustTransforms, DustTransformsHistory, DustTransformsHistoryResponse,
  PositionsSummaryData,
} from './types';

export type DustTransformsConversionParams = {
  sub_account_id: number
  asset_ids: number[]
  transform_to_asset: string
}

export type DustTransfromsHistoryParams = {
  sub_account_id: number;
  from_date: string;
  to_date: string;
  page: number;
  limit: number;
  symbol?: string;
}

export type PositionsSummuryParams = {
  query: {
    limit: number;
    page: number;
  },
  body: {
    sub_account_id?: number;
    from_date: string;
    to_date: string;
    symbol?: string;
    position_trading_type?: 'SPOT' | 'FUTURES';
  }
}

export const subscribePositionsSummury = () => {
  const { dispatch } = store;

  const intervalId = setInterval(() => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      devConsoleLog('Подписка на PositionsSummary');

      const subscribeMessage: ISubscribeEvent = {
        event: EInputEventType.SUBSCRIBE,
        metadata: {
          event_type: EOutputEventType.POSITIONS_SUMMARY,
        },
      };

      socket.send(JSON.stringify(subscribeMessage));
      dispatch(addSubsciptionQueue(subscribeMessage));
      clearInterval(intervalId);
    }
  }, 500);
};

export const unsubscribePositionsSummury = () => {
  const { dispatch } = store;

  if (socket && socket.readyState === WebSocket.OPEN) {
    devConsoleLog('Отписка от PositionsSummary');

    const unsubscribeMessage: IUnsubscribeEvent = {
      event: EInputEventType.UNSUBSCRIBE,
      metadata: {
        event_type: EOutputEventType.POSITIONS_SUMMARY,
      },
    };

    socket.send(JSON.stringify(unsubscribeMessage));
    dispatch(removeSubsciptionQueue(unsubscribeMessage.metadata.event_type));
  }
};

export const fetchPositionsSummury = createAsyncThunk(
  'account/fetchPositionsSummury',
  async (params: PositionsSummuryParams, { rejectWithValue }) => {
    try {
      const response = await $api.post<PositionsSummaryData>(ROUTE_PATH.positionsSummary.list, params.body, {
        params: {
          limit: params.query.limit.toString(),
          page: params.query.page.toString(),
        },
      });

      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error instanceof Error ? error.message : String(error));
    }
  },
);

export const fetchDustTransformsAssets = createAsyncThunk(
  'account/fetchDustTransformsAssets',
  async (subAccountId: number, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<DustTransforms[]> = await $api.get(ROUTE_PATH.subAccounts.dustTransforms.assets, {
        params: {
          sub_account_id: subAccountId,
        },
      });

      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error instanceof Error ? error.message : String(error));
    }
  },
);

export const fetchDustTransformsCountdown = createAsyncThunk(
  'account/fetchDustTransformsCountdown',
  async (subAccountId: number, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<number> = await $api.get<number>(ROUTE_PATH.subAccounts.dustTransforms.countdown, {
        params: {
          sub_account_id: subAccountId,
        },
      });

      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error instanceof Error ? error.message : String(error));
    }
  },
);

export const fetchDustTransformsHistory = createAsyncThunk(
  'account/fetchDustTransformsHistory',
  async (params: DustTransfromsHistoryParams, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<DustTransformsHistoryResponse> = await $api.get(ROUTE_PATH.subAccounts.dustTransforms.history, {
        params,
      });

      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error instanceof Error ? error.message : String(error));
    }
  },
);

export const fetchDustTransformsConversion = createAsyncThunk(
  'account/fetchDustTransformsConversion',
  async (params: DustTransformsConversionParams, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<DustTransformsHistory> = await $api.post(ROUTE_PATH.subAccounts.dustTransforms.transforms, params);

      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error instanceof Error ? error.message : String(error));
    }
  },
);
