import { useState } from 'react';
import { AxiosError } from 'axios';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'src/app/store/store';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';

import Hint from 'src/shared/ui/help/hint';
import Button from 'src/shared/ui/button/button/button';
import useAlert from 'src/shared/libs/hooks/use-alert';
import InputForm from 'src/shared/ui/input/input-form/input-form';
import InputCode from 'src/shared/ui/input/input-code/input-code';
import ForgotPasswordService, { ForgotDataStepTwo } from 'src/services/forgot-password.service';
import InputText from '../../../components/inputs-auth/input-auth';

import { setStep } from '../../model/slice';
import { selectForgotPassword } from '../../model/selectors';
import styles from './forgot-step-two.module.scss';

const schema = yup.object().shape({
  email_verification_code: yup.string().required('field required'),
  verification_code: yup.string().required('field required'),
});

type ForgotPasswordStepTwo = {
  email_verification_code: string
  verification_code: string
}

const ForgotStepTwo = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { setAlertMessage } = useAlert();

  const [loading, setLoading] = useState(false);
  const {
    email, phoneNumber, smsType, methodSms,
  } = useSelector(selectForgotPassword);
  const [codeGA, setCodeGA] = useState('');

  const form = useForm<ForgotPasswordStepTwo>({
    defaultValues: {
      email_verification_code: '',
      verification_code: '',
    },
    resolver: yupResolver(schema),
  });

  const { control, handleSubmit, formState } = form;
  const { errors } = formState;

  const forgotPasswordStepTwo = async (data: ForgotPasswordStepTwo) => {
    setLoading(true);

    let forgotData: ForgotDataStepTwo = {
      email,
      email_verification_code: Number(data.email_verification_code),
      phone_number: phoneNumber,
    };

    if (methodSms.some((type) => type === 'ga')) {
      forgotData.ga_verification_code = codeGA;
    }
    if (methodSms.some((type) => type === 'phone')) {
      forgotData.phone_verification_code = Number(data.verification_code);
    }
    if (methodSms.some((type) => type === 'whatsapp')) {
      forgotData.whatsapp_verification_code = Number(data.verification_code);
    }

    const result = await ForgotPasswordService.stepTwo(forgotData);

    if (result.data) {
      dispatch(setStep(2));
    } else if (result instanceof AxiosError && result.response) {
      const { detail } = result.response.data;
      setLoading(false);

      if (detail === 'INVALID_CAPTCHA') {
        setAlertMessage('captcha_expired', 'error');
      } else if (detail === 'STEP_EXPIRED') {
        setAlertMessage('start_from_first_step', 'error');
      } else if (detail === 'USER_NOT_FOUND') {
        setAlertMessage('account_not_found', 'error');
      } else if (detail === 'INVALID_CODE') {
        setAlertMessage('invalid_verification_code', 'error');
      } else {
        setAlertMessage(JSON.stringify(detail), 'error');
      }
    } else {
      setAlertMessage('internal error', 'error');
      setLoading(false);
    }
  };

  const onSubmit = async (userData: ForgotPasswordStepTwo) => {
    forgotPasswordStepTwo(userData);
  };

  return (
    <form className={styles.loginForm} onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="email_verification_code"
        control={control}
        render={({ field }) => (
          <InputText
            error={!!errors.email_verification_code}
            placeholder={t('code')}
            label={t('email_confirmation_code')}
            {...field}
          />
        )}
      />
      <span className={styles.inputInfo}>{t('confirmation_code_sent_to_email')}: {email}</span>
      {errors.email_verification_code && errors.email_verification_code.message && <Hint error text={t(errors.email_verification_code.message)} />}

      <Controller
        name="verification_code"
        control={control}
        render={({ field }) => (
          <InputText
            error={!!errors.verification_code}
            placeholder={t('code')}
            label={`${t('confirmation_code')} ${smsType === 'phone' ? 'SMS' : 'WhatsApp'}`}
            {...field}
          />
        )}
      />
      <span className={styles.inputInfo}>{t('confirmation_code_will_be_sent')} {smsType === 'phone' ? t('confirmation_code_sent_to_phone') : t('confirmation_code_sent_to_whatsapp')} {`${t('by_number')}:`} {phoneNumber}</span>
      {errors.verification_code && errors.verification_code.message && <Hint error text={t(errors.verification_code.message)} />}

      {
        methodSms.some((type) => type === 'ga') && (
          <div key="ga-input" className="verification-input">
            <InputForm title={t('google authenticator code')}>
              <InputCode error={false} onChange={setCodeGA} />
            </InputForm>
          </div>
        )
      }

      <Button type="submit" loading={loading} background="green">{t('сontinue')}</Button>
    </form>
  );
};

export default ForgotStepTwo;
