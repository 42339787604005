import { createSlice } from '@reduxjs/toolkit';
import { EStatus } from 'src/shared/types/global-types';
import { IReferralsSlice } from './types';
import { fetchReferralsBonuses, fetchReferralsBonusesStatistics, fetchReferralsStatistics } from './thunk';

const initialState: IReferralsSlice = {
  status: EStatus.loading,
  referralsStatisticsStatus: EStatus.loading,
  referralsBonuses: null,
  referralsStatistics: null,
  referralsBonusesStatistics: null,
  filters: {
    dateRange: [],
    exchange: null,
    tradingType: null,
    userId: null,
    page: 1,
    limit: 20,
  },
};

export const referralsSlice = createSlice({
  name: 'referrals',
  initialState,
  reducers: {
    setReferralsFilters: (state, action) => {
      state.filters = action.payload;
    },
    clearReferralsSlice: (state) => {
      state.referralsBonuses = null;
      state.referralsStatistics = null;
      state.referralsBonusesStatistics = null;
      state.filters = {
        dateRange: [],
        exchange: null,
        tradingType: null,
        userId: null,
        page: 1,
        limit: 20,
      };
      state.status = EStatus.loading;
      state.referralsStatisticsStatus = EStatus.loading;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchReferralsBonuses.pending, (state) => {
        state.status = EStatus.loading;
        state.referralsBonuses = null;
      })
      .addCase(fetchReferralsBonuses.fulfilled, (state, action) => {
        state.status = EStatus.success;
        state.referralsBonuses = action.payload;
      })
      .addCase(fetchReferralsBonuses.rejected, (state) => {
        state.referralsBonuses = null;
        state.status = EStatus.rejected;
      })

      .addCase(fetchReferralsStatistics.pending, (state) => {
        state.referralsStatisticsStatus = EStatus.loading;
        state.referralsStatistics = null;
      })
      .addCase(fetchReferralsStatistics.fulfilled, (state, action) => {
        state.referralsStatisticsStatus = EStatus.success;
        state.referralsStatistics = action.payload;
      })
      .addCase(fetchReferralsStatistics.rejected, (state) => {
        state.referralsStatistics = null;
        state.referralsStatisticsStatus = EStatus.rejected;
      })

      .addCase(fetchReferralsBonusesStatistics.pending, (state) => {
        state.referralsBonusesStatistics = null;
      })
      .addCase(fetchReferralsBonusesStatistics.fulfilled, (state, action) => {
        state.referralsBonusesStatistics = action.payload;
      })
      .addCase(fetchReferralsBonusesStatistics.rejected, (state) => {
        state.referralsBonusesStatistics = null;
      });
  },
});

export const { setReferralsFilters, clearReferralsSlice } = referralsSlice.actions;
export const referralsReducer = referralsSlice.reducer;
