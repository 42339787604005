import { EStatus, Meta, Nullable } from 'src/shared/types/global-types';

export enum NotificationTypes {
  NEWS = 'NEWS',
  IMPORTANT = 'IMPORTANT',
  WARNING = 'WARNING'
}

export enum Role {
  ADMINISTRATOR = 'ADMINISTRATOR',
  MODERATOR = 'MODERATOR',
  SYSTEM = 'SYSTEM'
}

export type Message = {
  lang: string
  value: string
}

export type Notification = {
  created_at: string
  from_role: Nullable<Role>,
  id: number,
  message: Message[],
  read: boolean
  type_message: Nullable<NotificationTypes>,
}

export type NotificationReadEvent = {
  id: number,
  read: boolean
}

export type NotificationsResponse = {
  items: Notification[],
  meta: Meta,
}

export interface INotificationsSlice {
  notifications: Nullable<NotificationsResponse>,
  status: EStatus
  statusAllRead: EStatus
  statusMarkAsRead: EStatus
  statusMarkAsDelete: EStatus
}
