import React, { memo } from 'react';

import './input-form.scss';
import { join } from 'src/shared/libs/helpers/helper.lib';

export interface IInputFormProps {
  title?: string;
  titleColor?: string;
  comment?: string;
  children: React.ReactNode;
  maxWidth?: string;
  minWidth?: string;
  action?: Function;
  isAction?: boolean;
  actionTitle?: string;
  className?: string
}

const InputForm = memo(({
  title,
  comment,
  children,
  titleColor,
  maxWidth,
  minWidth,
  action,
  actionTitle,
  isAction,
  className,
}: IInputFormProps) => {
  const handleAction = () => {
    if (!action) return;
    action();
  };

  return (
    <div className={join('input-form', className || '')} style={({ ...maxWidth && { maxWidth }, ...minWidth && { minWidth } })}>
      { title && (
        <div className="input-form-title">
          <p style={titleColor ? { color: titleColor } : {}}>{title}</p>
          {isAction && <button onClick={handleAction}>{actionTitle}</button>}
        </div>
      ) }
      <div className="input-form-content">{children}</div>
      { comment && <p className="comment">{comment}</p> }
    </div>
  );
});

export default InputForm;
