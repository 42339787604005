import className from 'classnames';
import {
  useState, useEffect, useRef, memo,
} from 'react';
import { Dispatch } from '@reduxjs/toolkit';
import { Nullable } from 'src/shared/types/global-types';
import { useTranslation } from 'react-i18next';
import { setSubAccount } from 'src/app/store/slices/sub-accounts/slice';
import { SubAccountType } from 'src/app/store/slices/sub-accounts/types';
import { getFromLocalStorage, saveToLocalStorage } from 'src/shared/libs/helpers/helper.lib';
import { ReactComponent as ArrowSvg } from 'src/shared/assets/images/arrow.svg';
import useExchange from 'src/shared/libs/hooks/use-exchange';
import DefaultIconSvg from 'src/shared/assets/images/lot.png';
import styles from './account-select.module.scss';

interface IAccountSelectProps {
  subAccounts: SubAccountType[];
  onChangeBalanceById: (id: number) => void;
  dispatch: Dispatch;
  selectedSubAccount: Nullable<SubAccountType>;
}
const AccountSelect = memo(({
  subAccounts, onChangeBalanceById, dispatch, selectedSubAccount,
}: IAccountSelectProps) => {
  const { t } = useTranslation();
  const selectedSubAccountId = getFromLocalStorage('favoriteSubAccountId', null);
  const selectRef = useRef<HTMLDivElement>(null);

  const { getExchangeImageById } = useExchange();
  const [showList, setShowList] = useState(false);

  const selectAccount = (subAccount: SubAccountType, index: number) => {
    saveToLocalStorage('favoriteSubAccountId', subAccount.id);
    onChangeBalanceById(subAccount.id);
    dispatch(setSubAccount(subAccount));
    setShowList(false);
  };

  const getImageExchange = (selectedSubAccount: Nullable<SubAccountType>) => {
    if (selectedSubAccount && getExchangeImageById(selectedSubAccount.exchange_id)) {
      return getExchangeImageById(selectedSubAccount.exchange_id);
    }
    return DefaultIconSvg;
  };

  const handleSetShowList = () => {
    if (subAccounts.length < 1) return;

    setShowList((prev) => !prev);
  };

  useEffect(() => {
    const setShowListHandler = (e: MouseEvent) => {
      if (!selectRef.current?.contains(e.target as Node)) {
        setShowList(false);
      }
    };
    window.addEventListener('click', setShowListHandler);

    return () => {
      window.removeEventListener('click', setShowListHandler);
    };
  }, [showList]);

  useEffect(() => {
    if (selectedSubAccountId) return;

    const findFavoriteSubAccount = subAccounts.find((item) => item.is_favorite);

    if (findFavoriteSubAccount) {
      dispatch(setSubAccount(findFavoriteSubAccount));
      onChangeBalanceById(findFavoriteSubAccount.id);
      return;
    }

    if (!selectedSubAccountId && !findFavoriteSubAccount && subAccounts) {
      const [isFavoriteSubAccount] = subAccounts;

      if (isFavoriteSubAccount) {
        dispatch(setSubAccount(isFavoriteSubAccount));
        onChangeBalanceById(isFavoriteSubAccount.id);
      }
    }
  }, [subAccounts, selectedSubAccountId]);

  useEffect(() => {
    if (!selectedSubAccountId) return;

    const findFavoriteSubAccount = subAccounts.find((subAccount) => subAccount.id === selectedSubAccountId);

    if (findFavoriteSubAccount) {
      dispatch(setSubAccount(findFavoriteSubAccount));
      onChangeBalanceById(findFavoriteSubAccount.id);
    }
  }, [subAccounts, selectedSubAccountId]);

  return (
    <div ref={selectRef} className={className(styles.accountSelect, { [styles.active]: showList })}>
      <div
        tabIndex={0}
        role="button"
        onKeyDown={handleSetShowList}
        onClick={handleSetShowList}
        className={styles.selectBlock}
      >
        <img src={getImageExchange(selectedSubAccount)} alt="exchange" className={styles.image} />
        <span className={styles.name}>{selectedSubAccount?.user_name || t('accounts')}</span>
        <ArrowSvg />
      </div>

      {showList && (
        <div className={styles.selectListWrapper}>
          <ul className={styles.selectList}>
            {
              subAccounts.map((account, index) => (
                <li
                  key={account.id}
                  tabIndex={0}
                  role="button"
                  onKeyDown={() => selectAccount(account, index)}
                  onClick={() => selectAccount(account, index)}
                >
                  <img src={getExchangeImageById(account.exchange_id) || DefaultIconSvg} alt="exchange" className={styles.image} />
                  <span className={styles.name}>{account.user_name}</span>
                </li>
              ))
            }
          </ul>
        </div>
      )}

    </div>
  );
});

export default AccountSelect;
