import { USDT_PRECISION } from 'src/shared/constants/constants';
import {
  divideValues, minusValues, multiplyValues, plusValues,
} from 'src/shared/libs/helpers/helper.lib';
import { PositionsSummary, SortFieldPositionsSummary } from '../../model/types';

export const calculatePnl = (transaction: PositionsSummary) => {
  let pnl = '0.0';

  const { side: action, exit_price: exitPrice } = transaction;
  const isActivePosition = typeof exitPrice !== 'undefined';

  if (action === 'LONG') {
    if (isActivePosition) {
      pnl = plusValues(transaction.profit, transaction.commission, USDT_PRECISION);
    } else {
      const calculationResult = minusValues(transaction.instrument.price.bid, transaction.entry_price);
      pnl = multiplyValues(calculationResult, transaction.quantity, USDT_PRECISION);
    }
  }

  if (action === 'SHORT') {
    if (isActivePosition) {
      pnl = plusValues(transaction.profit, transaction.commission, USDT_PRECISION);
    } else {
      const calculationResult = minusValues(transaction.entry_price, transaction.instrument.price.ask);
      pnl = multiplyValues(calculationResult, transaction.quantity, USDT_PRECISION);
    }
  }

  return pnl === '-0.0' ? '0.0' : pnl;
};

export const calculatePnlInPercents = (transaction: PositionsSummary) => {
  let pnl = '0.0';

  const { side: action } = transaction;

  if (action === 'LONG') {
    const divide = divideValues((transaction?.exit_price || transaction.instrument.price.bid), divideValues(transaction.entry_price, '100'));
    pnl = minusValues(divide, '100', 2);
  }

  if (action === 'SHORT') {
    const divide = divideValues((transaction?.exit_price || transaction.instrument.price.ask), divideValues(transaction.entry_price, '100'));
    pnl = minusValues('100', divide, 2);
  }
  return pnl === '-0.00' ? '0.00' : pnl;
};

export const sortPositionsSummury = (a: PositionsSummary, b: PositionsSummary, sortField: SortFieldPositionsSummary, sortOrder: 'asc' | 'desc') => {
  if (sortField === 'entered_at') {
    const dateA = new Date(a.entered_at);
    const dateB = new Date(b.entered_at);

    if (dateA < dateB) {
      return sortOrder === 'asc' ? -1 : 1;
    }
    if (dateA > dateB) {
      return sortOrder === 'asc' ? 1 : -1;
    }
    return 0;
  }

  if (sortField === 'side') {
    const dateA = a.side;
    const dateB = b.side;

    if (dateA < dateB) {
      return sortOrder === 'asc' ? -1 : 1;
    }
    if (dateA > dateB) {
      return sortOrder === 'asc' ? 1 : -1;
    }
    return 0;
  }

  if (sortField === 'instrument') {
    const symbolA = a.instrument.symbol;
    const symbolB = b.instrument.symbol;

    if (symbolA < symbolB) {
      return sortOrder === 'asc' ? -1 : 1;
    }
    if (symbolA > symbolB) {
      return sortOrder === 'asc' ? 1 : -1;
    }
    return 0;
  }

  if (sortField === 'pnl_percent') {
    const pnlA = a.pnl_percent;
    const pnlB = b.pnl_percent;

    if (pnlA < pnlB) {
      return sortOrder === 'asc' ? -1 : 1;
    }
    if (pnlA > pnlB) {
      return sortOrder === 'asc' ? 1 : -1;
    }
    return 0;
  }

  if (sortField === 'pnl') {
    const pnlA = parseFloat(a.pnl);
    const pnlB = parseFloat(b.pnl);

    if (pnlA < pnlB) {
      return sortOrder === 'asc' ? -1 : 1;
    }
    if (pnlA > pnlB) {
      return sortOrder === 'asc' ? 1 : -1;
    }
    return 0;
  }

  if (sortField === 'exit_price') {
    const priceA = a.exit_price !== undefined ? a.exit_price : Number.NEGATIVE_INFINITY;
    const priceB = b.exit_price !== undefined ? b.exit_price : Number.NEGATIVE_INFINITY;

    if (priceA < priceB) {
      return sortOrder === 'asc' ? -1 : 1;
    }
    if (priceA > priceB) {
      return sortOrder === 'asc' ? 1 : -1;
    }
    return 0;
  }

  if (sortField === 'entry_price') {
    const priceA = parseFloat(a.entry_price);
    const priceB = parseFloat(b.entry_price);

    if (priceA < priceB) {
      return sortOrder === 'asc' ? -1 : 1;
    }
    if (priceA > priceB) {
      return sortOrder === 'asc' ? 1 : -1;
    }
    return 0;
  }

  if (sortField === 'commission') {
    const priceA = parseFloat(a.commission);
    const priceB = parseFloat(b.commission);

    if (priceA < priceB) {
      return sortOrder === 'asc' ? -1 : 1;
    }
    if (priceA > priceB) {
      return sortOrder === 'asc' ? 1 : -1;
    }
    return 0;
  }

  if (sortField === 'cumulative_quantity') {
    const priceA = parseFloat(a.cumulative_quantity);
    const priceB = parseFloat(b.cumulative_quantity);

    if (priceA < priceB) {
      return sortOrder === 'asc' ? -1 : 1;
    }
    if (priceA > priceB) {
      return sortOrder === 'asc' ? 1 : -1;
    }
    return 0;
  }

  if (sortField === 'quantity') {
    const priceA = parseFloat(a.quantity);
    const priceB = parseFloat(b.quantity);

    if (priceA < priceB) {
      return sortOrder === 'asc' ? -1 : 1;
    }
    if (priceA > priceB) {
      return sortOrder === 'asc' ? 1 : -1;
    }
    return 0;
  }

  if (sortField === 'quantity_dollars') {
    const priceA = parseFloat(multiplyValues(a.quantity, a.entry_price));
    const priceB = parseFloat(multiplyValues(b.quantity, b.entry_price));

    if (priceA < priceB) {
      return sortOrder === 'asc' ? -1 : 1;
    }
    if (priceA > priceB) {
      return sortOrder === 'asc' ? 1 : -1;
    }
    return 0;
  }

  if (sortField) {
    const aValue = a[sortField];
    const bValue = b[sortField];

    if (aValue !== undefined && bValue !== undefined && aValue !== null && bValue !== null) {
      if (aValue < bValue) {
        return sortOrder === 'asc' ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortOrder === 'asc' ? 1 : -1;
      }
    }
  }
  return 0;
};
