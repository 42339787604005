import { divideValues, minusValues, multiplyValues } from 'src/shared/libs/helpers/helper.lib';
import { Position } from '../../model/types';

export const calculatePnlPosition = (position: Position) => {
  if (position.instrument.price.ask === '0' || position.instrument.price.bid === '0') {
    return '0';
  }

  if (position.side === 'LONG') {
    const calculationResult = minusValues(position.instrument.price.bid, position.average_price);
    const result = multiplyValues(calculationResult, position.quantity, 2);

    if (result === '-0.00') {
      return '0.00';
    }
    return result;
  }

  if (position.side === 'SHORT') {
    const calculationResult = minusValues(position.average_price, position.instrument.price.ask);
    const result = multiplyValues(calculationResult, position.quantity, 2);

    if (result === '-0.00') {
      return '0.00';
    }
    return result;
  }
  return '0';
};

export const calculatePnlInPercentsPosition = (position: Position) => {
  if (position.instrument.price.ask === '0' || position.instrument.price.bid === '0') {
    return '0';
  }

  if (position.side === 'LONG') {
    const divide = divideValues(position.instrument.price.bid, divideValues(position.average_price, '100'));
    return minusValues(divide, '100', 2);
  }

  if (position.side === 'SHORT') {
    const divide = divideValues(position.instrument.price.ask, divideValues(position.average_price, '100'));
    return minusValues('100', divide, 2);
  }
  return '0';
};
