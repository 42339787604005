import { useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'src/app/store/store';

import Button from 'src/shared/ui/button/button/button';
import hamsterGif from 'src/shared/assets/images/hamsterGif2.gif';

import { START_PAGE } from 'src/shared/constants/constants';
import { ReactComponent as LogotypeIconSvg } from 'src/shared/assets/images/header/logotype.svg';
import { useTranslation } from 'react-i18next';
import { setIsWelcomePage } from '../auth/login/model/slice';

import styles from './welcome.module.scss';

interface IСonfettiParticle {
    x: number;
    y: number;
    r: number;
    d: number;
    color: string;
    tilt: number;
    tiltAngleIncremental: number;
    tiltAngle: number;
    draw: () => void;
  }

const possibleColors = [
  'DodgerBlue',
  'OliveDrab',
  'Gold',
  'Pink',
  'SlateBlue',
  'LightBlue',
  'Gold',
  'Violet',
  'PaleGreen',
  'SteelBlue',
  'SandyBrown',
  'Chocolate',
  'Crimson',
];
const maxConfettis = 150;

const WelcomePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const canvasRef = useRef(null);

  let W = window.innerWidth;
  let H = window.innerHeight;
  const particles: IСonfettiParticle[] = [];

  function randomFromTo(from: number, to: number): number {
    return Math.floor(Math.random() * (to - from + 1) + from);
  }

  /* eslint-disable */
  function confettiParticle(this: IСonfettiParticle) {
    this.x = Math.random() * W; // x
    this.y = Math.random() * H - H; // y
    this.r = randomFromTo(11, 33); // radius
    this.d = Math.random() * maxConfettis + 11;
    this.color = possibleColors[Math.floor(Math.random() * possibleColors.length)];
    this.tilt = Math.floor(Math.random() * 33) - 11;
    this.tiltAngleIncremental = Math.random() * 0.07 + 0.05;
    this.tiltAngle = 0;

    this.draw = function () {
      const canvas = canvasRef.current;
      if (canvas) {
        // @ts-ignore
        const context = canvas.getContext('2d');
        if (context) {
          context.beginPath();
          context.lineWidth = this.r / 2;
          context.strokeStyle = this.color;
          context.moveTo(this.x + this.tilt + this.r / 3, this.y);
          context.lineTo(this.x + this.tilt, this.y + this.tilt + this.r / 5);
          context.stroke();
        }
      }
    };
  }
  
/* eslint-disable */
  function Draw() {
    const canvas = canvasRef.current;
    if (canvas) {
       // @ts-ignore
      const context = canvas.getContext('2d');
      if (context) {
        const results = [];

        // Magical recursive functional love
        requestAnimationFrame(Draw);

        context.clearRect(0, 0, W, window.innerHeight);

        for (let i = 0; i < maxConfettis; i++) {
          results.push(particles[i].draw());
        }
        // @ts-ignore
        let particle: confettiParticle = {} as confettiParticle;
        let remainingFlakes = 0;
        for (let i = 0; i < maxConfettis; i++) {
          particle = particles[i];

          particle.tiltAngle += particle.tiltAngleIncremental;
          particle.y += (Math.cos(particle.d) + 3 + particle.r / 2) / 2;
          particle.tilt = Math.sin(particle.tiltAngle - i / 3) * 15;

          if (particle.y <= H) remainingFlakes++;

          // If a confetti has fluttered out of view,
          // bring it back to above the viewport and let if re-fall.
          if (particle.x > W + 30 || particle.x < -30 || particle.y > H) {
            particle.x = Math.random() * W;
            particle.y = -30;
            particle.tilt = Math.floor(Math.random() * 10) - 20;
          }
        }

        return results;
      }
    }
  }

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
  // @ts-ignore
    canvas.width = W;
    // @ts-ignore
    canvas.height = H;

    window.addEventListener('resize', () => {
      W = window.innerWidth;
      H = window.innerHeight;
      // @ts-ignore
      canvas.width = window.innerWidth;
      // @ts-ignore
      canvas.height = window.innerHeight;
    }, false);

    // Push new confetti objects to `particles[]`
    for (let i = 0; i < maxConfettis; i++) {
      // @ts-ignore
      particles.push(new confettiParticle());
    }

    // Initialize
    Draw();
  }, []);

  const action = () => {
    dispatch(setIsWelcomePage(false));
    navigate(`/${START_PAGE}`);
  }

  return (
    <>
      <canvas ref={canvasRef} id="canvas" />

      <div className={styles.welcome}>
        <div className={styles.bodyContainer}>
          <div className={styles.animationWrapper}>
            <img src={hamsterGif} alt="hamster" />
          </div>
          <div className={styles.body}>
            <LogotypeIconSvg />
            <h1>{t('welcome')}</h1>
            <Button onClick={action} maxWidth="240px" background="orange">{t('go_to_account')}</Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default WelcomePage;
