import Button from 'src/shared/ui/button/button/button';
import CopyIcon from 'src/shared/assets/icons/copy-icon/copy-icon';
import QRCodeGenerator from 'src/entities/qr-code/qr-code';

import { ModalSteps } from 'src/shared/types/global-types';
import { useTranslation } from 'react-i18next';
import styles from './connection-ga-step-one.module.scss';

interface IConnectionGaStepOne extends ModalSteps {
    uri: string | undefined;
    secretKey: string | undefined;
    disconectedGA: () => void;
    nextStepAction: () => void;
  }

export const ConnectionGaStepOne = ({
  uri, secretKey, disconectedGA, nextStepAction, step,
}: IConnectionGaStepOne) => {
  const { t } = useTranslation();

  return (step === 1 ? (
    <div className={styles.gaModalWrapper}>
      <p className={styles.gaQrInfo}>
        {t('scan')} <strong>{t('the_qr_code')}</strong> {t('scan_qr_code')}
      </p>
      <QRCodeGenerator qrText={uri || ''} />
      <div className={styles.gaKeyWrapper}>
        <p className={styles.gaKey}>{secretKey}</p>
        <CopyIcon value={secretKey} />
      </div>

      <div className={styles.gaModalActions}>
        <Button onClick={disconectedGA} background="black">{t('cancel')}</Button>
        <Button onClick={nextStepAction} background="green">{t('next')}</Button>
      </div>
    </div>
  ) : null);
};
