/* eslint-disable indent */
// eslint-disable-next-line prefer-object-spread
import ruHeader from 'src/app/locales/ru/header.json';
import ruAside from 'src/app/locales/ru/aside.json';
import ruAlertMessages from 'src/app/locales/ru/alert-mesages.json';
import ruProfile from 'src/app/locales/ru/profile.json';
import ruNotifications from 'src/app/locales/ru/notifications.json';
import ruGlobal from 'src/app/locales/ru/global.json';
import ruSettings from 'src/app/locales/ru/settings.json';
import ruLeverage from 'src/app/locales/ru/leverage.json';
import ruTransfers from 'src/app/locales/ru/transfers.json';
import ruTrading from 'src/app/locales/ru/trading.json';
import ruAccounts from 'src/app/locales/ru/accounts.json';
import ruAuth from 'src/app/locales/ru/auth.json';
import ruDiary from 'src/app/locales/ru/diary.json';
import ruScreener from 'src/app/locales/ru/screener.json';
import ruReferrals from 'src/app/locales/ru/referrals.json';

import enHeader from 'src/app/locales/en/header.json';
import enAside from 'src/app/locales/en/aside.json';
import enAlertMessages from 'src/app/locales/en/alert-mesages.json';
import enProfile from 'src/app/locales/en/profile.json';
import enNotifications from 'src/app/locales/en/notifications.json';
import enGlobal from 'src/app/locales/en/global.json';
import enSettings from 'src/app/locales/en/settings.json';
import enLeverage from 'src/app/locales/en/leverage.json';
import enTransfers from 'src/app/locales/en/transfers.json';
import enTrading from 'src/app/locales/en/trading.json';
import enAccounts from 'src/app/locales/en/accounts.json';
import enAuth from 'src/app/locales/en/auth.json';
import enDiary from 'src/app/locales/en/diary.json';
import enScreener from 'src/app/locales/en/screener.json';
import enReferrals from 'src/app/locales/en/referrals.json';

/* eslint-disable-next-line prefer-object-spread */
export const ruTranslations = Object.assign(
    {},
    ruHeader,
    ruAside,
    ruAlertMessages,
    ruProfile,
    ruNotifications,
    ruGlobal,
    ruSettings,
    ruLeverage,
    ruTransfers,
    ruTrading,
    ruAccounts,
    ruAuth,
    ruDiary,
    ruScreener,
    ruReferrals,
);

/* eslint-disable-next-line prefer-object-spread */
export const enTranslations = Object.assign(
    {},
    enHeader,
    enAside,
    enAlertMessages,
    enProfile,
    enNotifications,
    enGlobal,
    enSettings,
    enLeverage,
    enTransfers,
    enTrading,
    enAccounts,
    enAuth,
    enDiary,
    enScreener,
    enReferrals,
);
