import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLayoutEffect } from 'react';
import { useAppDispatch } from 'src/app/store/store';
import { AuthLayout } from 'src/layouts';
import { useChangePageTitle } from 'src/shared/libs/hooks/use-change-page-title';

import LanguageSelection from 'src/entities/language-selection/language-selection';
import AbstractIconsSvg from 'src/shared/assets/images/auth/abstract.svg';

import AuthBody from '../components/auth-body/auth-body';
import AuthTitle from '../components/auth-title/auth-title';
import AuthFooter from '../components/auth-footer/auth-footer';
import useCheckStep from '../lib/hooks/use-check-step';
import PersonalDataForm from './components/personal-data-form/personal-data-form';

import { setSlidesPage } from '../login/model/slice';
import { selectSlidesPage } from '../login/model/selectors';
import { informSlides, infromTitleSilde } from '../lib/helpers/constants';
import { selectRegistration } from '../registration/model/selectors';
import styles from './personal-data.module.scss';

const pageTitle = 'fill_personal_data';

const PersonalDataPage = () => {
  const { t } = useTranslation();
  const { email } = useSelector(selectRegistration);

  useChangePageTitle(pageTitle);
  useCheckStep(email, '/auth/registration');

  const dispatch = useAppDispatch();
  const slidePage = useSelector(selectSlidesPage);

  useLayoutEffect(() => {
    dispatch(setSlidesPage(3));
  }, []);

  return (
    <AuthLayout page="">
      <div className={styles.container}>
        <section className={styles.bodyWrapper}>
          <AuthBody
            activeSlide="fourth"
            titleUp={t('personal_data')}
            titleDown={t('is_protected')}
            description={t('responsibility_for_personal_data')}
            informList={informSlides[slidePage]}
            informTitle={infromTitleSilde[slidePage]}
          />
        </section>

        <section className={styles.formWrapper}>
          <div className={styles.formContainer}>
            <LanguageSelection bgColor="#131313" />
            <AuthTitle visible step="third">{t('fill_personal_data')}</AuthTitle>
            <PersonalDataForm />
          </div>

          <AuthFooter />
        </section>

        <img className={styles.abstract} src={AbstractIconsSvg} alt="abstract" />
      </div>
    </AuthLayout>
  );
};

export default PersonalDataPage;
