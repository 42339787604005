import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectHiddenBalance } from 'src/pages/settings/model/selectors';
import { HIDDEN_BALANCES } from 'src/shared/constants/constants';
import Button from 'src/shared/ui/button/button/button';
import styles from './bonus-conversion.module.scss';

const BonusConversion = () => {
  const { t } = useTranslation();
  const [autoConvert, setAutoConvert] = useState(true);

  const balanceVisible = useSelector(selectHiddenBalance);

  return (
    <section className={styles.container}>
      <div className={styles.title}>{t('manual bonus conversion')}</div>

      <p className={styles.info}>{t('select coin to convert bonuses')}</p>

      <ul className={styles.assets}>
        <li><button className={styles.asset}>USDT</button></li>
      </ul>

      <div className={styles.quantity}>
        <span>{t('amount in USDT')}</span>
        <button>{t('all')}</button>
      </div>

      <p className={styles.available}>{t('available')} <span>{!balanceVisible ? parseFloat('0').toLocaleString().replace(',', '.') : HIDDEN_BALANCES}</span> USDT</p>
      <p className={styles.bonuses}>{t('bonus to be deducted')} <span>{!balanceVisible ? parseFloat('0').toLocaleString().replace(',', '.') : HIDDEN_BALANCES}</span></p>

      <Button disabled={false} loading={false} background="green">{t('convert')}</Button>
    </section>
  );
};
export default BonusConversion;
