import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from './card-steps.module.scss';

interface ICardStepsProps {
    visible?: boolean
    step: 'none' | 'first' | 'second' | 'third' | string
  }
const CardSteps = ({ step, visible }: ICardStepsProps) => {
  const { t } = useTranslation();
  return (

    <div className={classNames(styles.cardContainer, { [styles.visible]: !visible })}>
      <div className={styles[step]}>{t('step')} №1</div>
      <div className={styles[step]}>{t('step')} №2</div>
      <div className={styles[step]}>{t('step')} №3</div>
    </div>

  );
};

export default CardSteps;
