import React from 'react';
import { useTranslation } from 'react-i18next';

interface IBybitHintContentProps {
  typeLabel: string
  maker: string,
  taker: string,
  rebatePercent: string
}
const BybitHintContent = ({
  taker, maker, typeLabel, rebatePercent,
}: IBybitHintContentProps) => {
  const { t } = useTranslation();
  return (
    <div>
      {typeLabel}:
      <br />
      Maker — {maker}%
      <br />
      Taker — {taker}%

      <br /><br />
      {t('additional reward')} {rebatePercent}% {t('from trading fees')}
    </div>
  );
};

export default BybitHintContent;
