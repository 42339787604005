import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getTokensFromLocalStorage } from 'src/shared/libs/helpers/save-tokens';
import { IAuthSlice, User } from './types';

const tokens = getTokensFromLocalStorage();

const initialState: IAuthSlice = {
  user: null,
  isAuth: !!tokens?.access_token,
  isWelcomePage: false,
  slidesPage: 0,
  sendCodeTypeVisible: false,

};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    removeUser: (state) => {
      state.user = null;
    },
    setAuth: (state, action) => {
      state.isAuth = action.payload;
    },
    setIsWelcomePage: (state, action: PayloadAction<boolean>) => {
      state.isWelcomePage = action.payload;
    },
    setSlidesPage: (state, action: PayloadAction<number>) => {
      state.slidesPage = action.payload;
    },
    setSendCodeTypeVisible: (state, action: PayloadAction<boolean>) => {
      state.sendCodeTypeVisible = action.payload;
    },
  },
});

export const {
  setUser, removeUser, setAuth, setIsWelcomePage, setSlidesPage, setSendCodeTypeVisible,
} = authSlice.actions;

export const authReducer = authSlice.reducer;
