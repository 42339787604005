import qs from 'qs';
import { Tabs } from '@mantine/core';
import {
  useState, useEffect, useRef, useLayoutEffect,
} from 'react';
import { scrollToTop } from 'src/shared/libs/helpers/helper.lib';
import { useAppDispatch } from 'src/app/store/store';
import { TransfersBlock } from 'src/widgets/transfers';
import { useTranslation } from 'react-i18next';
import { setTransfersAsset } from 'src/widgets/transfers/model/slice';
import Withdrawals from '../components/withdrawal/withdrawals';
import Replenishment from '../components/replenishment/replenishment';
import './transfers-adaptive.scss';

const tabsValue = ['replenishment', 'withdrawals', 'transfers'];
const threshold = 100; // Minimum distance to recognise a swipe gesture

const TransfersAdaptive = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [tabValue, setTabValue] = useState(tabsValue[0]);
  const [tabIndex, setTabIndex] = useState(0);

  const touchStartXRef = useRef(0);
  const touchEndXRef = useRef(0);

  const nextPage = (value: number) => {
    let newIndex = (value + 1) % 3;
    setTabIndex(newIndex);
    return newIndex;
  };

  const previousPage = (value: number) => {
    let newIndex = (value - 1 + 3) % 3;
    setTabIndex(newIndex);
    return newIndex;
  };

  const handleGesture = () => {
    const horizontalSwipe = Math.abs(touchEndXRef.current - touchStartXRef.current) > threshold;

    if (horizontalSwipe) {
      if (touchEndXRef.current < touchStartXRef.current) {
        setTabValue(tabsValue[nextPage(tabIndex)]);
      } else {
        setTabValue(tabsValue[previousPage(tabIndex)]);
      }
    }
  };

  const handleSetTabValue = (tabName: string, tabIndex: number) => {
    setTabValue(tabName);
    setTabIndex(tabIndex);
  };

  useEffect(() => {
    const handleTouchStart = (e: TouchEvent) => {
      const touch = e.changedTouches[0];
      touchStartXRef.current = touch.screenX;
    };

    const handleTouchEnd = (e: TouchEvent) => {
      const touch = e.changedTouches[0];
      touchEndXRef.current = touch.screenX;
      handleGesture();
    };

    document.addEventListener('touchstart', handleTouchStart, false);
    document.addEventListener('touchend', handleTouchEnd, false);

    return () => {
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchend', handleTouchEnd);
    };
  }, [tabIndex]);

  useEffect(() => {
    scrollToTop();

    return () => {
      dispatch(setTransfersAsset(null));
    };
  }, []);

  useLayoutEffect(() => {
    const parsedParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    const queryTab = parsedParams?.tab_index;

    if (queryTab && !Number.isNaN(queryTab)) {
      setTabIndex(Number(queryTab));

      if (Number(queryTab) <= tabsValue.length - 1) setTabValue(tabsValue[Number(queryTab)]);
    }
  }, []);

  return (
    <div className="section-content">
      <div className="tabs-wrapper">
        <Tabs activateTabWithKeyboard color="#00AB6D" value={tabValue} defaultValue={tabValue}>
          <Tabs.List className="tabs-nav" grow justify="center">
            <Tabs.Tab onClick={() => handleSetTabValue('replenishment', 0)} value="replenishment">{t('deposit')}</Tabs.Tab>
            <Tabs.Tab onClick={() => handleSetTabValue('withdrawals', 1)} value="withdrawals">{t('withdrawal')}</Tabs.Tab>
            <Tabs.Tab onClick={() => handleSetTabValue('transfers', 2)} value="transfers">{t('transfers')}</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel className="tab-content" value="replenishment">
            <Replenishment />
          </Tabs.Panel>

          <Tabs.Panel className="tab-content" value="withdrawals">
            <Withdrawals />
          </Tabs.Panel>

          <Tabs.Panel value="transfers">
            <TransfersBlock />
          </Tabs.Panel>
        </Tabs>
      </div>
    </div>
  );
};

export default TransfersAdaptive;
