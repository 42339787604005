import className from 'classnames';
import {
  useState, useEffect, useRef,
  useMemo,
  useLayoutEffect,
} from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'src/app/store/store';
import { selectLanguage } from 'src/app/store/slices/user/selectors';
import { fetchChangeLanguage } from 'src/app/store/slices/user/thunks';

import { useAuth } from 'src/shared/libs/hooks/use-auth';
import { Language } from 'src/shared/types/global-types';
import { LANGUAGES } from 'src/shared/constants/constants';
import { saveToLocalStorage } from 'src/shared/libs/helpers/helper.lib';

import styles from './language-selection.module.scss';

interface ILanguageSelectionProps {
  position?: 'static' | 'relative'
  bgColor?: string
}

const LanguageSelection = ({ position, bgColor }: ILanguageSelectionProps) => {
  const { isAuth } = useAuth();
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const globalLanguage = useSelector(selectLanguage);

  const [showSelectLanguage, setShowSelectLanguage] = useState(false);
  const languageContainerRef = useRef<HTMLDivElement>(null);

  const language = useMemo(() => {
    if (globalLanguage) {
      return LANGUAGES.find((item) => item.type === globalLanguage);
    }
    return LANGUAGES.find((item) => item.type === i18n.language);
  }, [globalLanguage, i18n.language]);

  const handleToggleLanguage = (selectLanguage: Language) => {
    if (selectLanguage.type === language?.type) {
      setShowSelectLanguage(false);
      return;
    }

    if (isAuth) dispatch(fetchChangeLanguage(selectLanguage.type));
    i18n.changeLanguage(selectLanguage.type);
    saveToLocalStorage('lang', selectLanguage);
    setShowSelectLanguage((prev) => !prev);
  };

  useLayoutEffect(() => {
    i18n.changeLanguage(language?.type);
  }, [language]);

  /* user events */
  useEffect(() => {
    const setOpenListHandler = (e: MouseEvent) => {
      if (!languageContainerRef.current?.contains(e.target as Node)) {
        setShowSelectLanguage(false);
      }
    };
    window.addEventListener('click', setOpenListHandler);

    return () => {
      window.removeEventListener('click', setOpenListHandler);
    };
  }, [showSelectLanguage]);

  return (
    <div ref={languageContainerRef} className={className(styles.languageContainer, { [styles.position]: position })}>
      <div className={styles.btnContainer}>
        <button
          className={styles.language}
          style={bgColor ? { background: bgColor, color: 'white' } : {}}
          onClick={() => setShowSelectLanguage((prev) => !prev)}
        >
          {language?.type.toLocaleUpperCase()}
        </button>
      </div>

      {showSelectLanguage
       && (
         <ul className={styles.list}>
           {
             LANGUAGES.map((lang) => (
               <li
                 role="button"
                 tabIndex={0}
                 onKeyDown={() => handleToggleLanguage(lang)}
                 onClick={() => handleToggleLanguage(lang)}
                 key={lang.type}
               >{lang.name}
               </li>
             ))
           }
         </ul>
       )}
    </div>
  );
};

export default LanguageSelection;
