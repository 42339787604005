import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { join } from 'src/shared/libs/helpers/helper.lib';
import { SYSTEM_NOTIFICATION } from 'src/shared/constants/constants';
import { ReactComponent as AlerIconSvg } from 'src/shared/assets/images/alertIcon.svg';

import useAlert from 'src/shared/libs/hooks/use-alert';
import ButtonClose from '../../button/button-close/button-close';
import ButtonLink from '../../button/button-link/button-link';
import './alerts.scss';

const Alerts = () => {
  const { messages, closeAlert, closeAlertAutoClose } = useAlert();
  const [show, setShow] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setShow(messages.length > 0); // Show alerts if there are any messages

    const timer = setTimeout(() => {
      if (messages.length > 0) {
        closeAlert(0);
      }
    }, 5000);

    return () => clearTimeout(timer);
  }, [messages]);

  return (
    <div className={join('alerts', show && 'show')}>
      {messages.map((msg, index) => (
        <div key={index} className={join('alert', show && 'show', msg.type)}>
          <AlerIconSvg />
          <div className="alert-content">
            <p>{t(msg.message)} </p>
            {msg.message === SYSTEM_NOTIFICATION && <ButtonLink onClick={() => window.location.reload()}>{t('update')}</ButtonLink>}
          </div>
          <ButtonClose onClick={() => closeAlertAutoClose(index)} />
        </div>
      ))}
    </div>
  );
};

export default Alerts;
