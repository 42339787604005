export const ERROR_MSG_LIST: Record<string, string> = {
  NO_ASSET_WITH_THIS_SYMBOL: 'no_assets',
  BALANCE_QUANTITY_LESS_THAN_PASSED_QUANTITY: 'insufficient_balance_for_transfer',
  USER_DOESNOT_HAVE_PERMISSION_FOR_THIS_ACTION: 'aml_check_in_progress',
  BLOCKCHAIN_CONFIRMATION_IS_NOT_READY: 'transfer_not_permitted',
  TRANSFER_BETWEEN_EQUAL_ACCOUNTS: 'equal_accounts_transfer_error',
  NO_SUB_ACCOUNT_ASSET_LOCKED_QUANTITY: 'no_funds_for_transfer',
  INSUFFICIENT_BALANCE_FOR_TRANSFER: 'insufficient_balance_for_transfer',
  NO_SUB_ACCOUNT_ASSET: 'insufficient_balance',
  DEFAULT: 'internal error',
};

export const EXCHANGE_TRANSFERS_LIMIT: Record<string, string> = {
  Binance: '0.00000001',
  Bybit: '0.0001',
};
