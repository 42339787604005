import classNames from 'classnames';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { setBalance } from 'src/app/store/slices/balance/slice';
import { selectBalances } from 'src/app/store/slices/balance/selectors';
import { useTranslation } from 'react-i18next';
import { store, useAppDispatch } from 'src/app/store/store';
import { DEFAULT_BALANCE, HIDDEN_BALANCES } from 'src/shared/constants/constants';
import { selectSelectedSubAccounts, selectSubAccounts } from 'src/app/store/slices/sub-accounts/selectors';
import { selectPositionsPnl, selectStatusCloseOrders, selectStatusClosePositions } from 'src/pages/trading/model/selectors';

import Button from 'src/shared/ui/button/button/button';
import useAlert from 'src/shared/libs/hooks/use-alert';
import AccountSelect from 'src/widgets/aside/components/account-select/account-select';
import AccountBlock from 'src/widgets/aside/components/account-block/account-block';

import { EStatus } from 'src/shared/types/global-types';
import { PositionCloseDetail } from 'src/pages/trading/model/types';
import { selectHiddenBalance } from 'src/pages/settings/model/selectors';
import { fetchOrderCancelAll, fetchPositionsCloseAll } from 'src/pages/trading/model/thunks';

import styles from './adaptive-balance.module.scss';

interface IAdaptiveBalanceProps {
    visible: boolean
    className?: string
    closeMenu?: () => void
}

const AdaptiveBalance = ({ visible, className, closeMenu } : IAdaptiveBalanceProps) => {
  const dispatch = useAppDispatch();
  const { setAlertMessage } = useAlert();
  const { t } = useTranslation();

  const pnl = useSelector(selectPositionsPnl);
  const subAccounts = useSelector(selectSubAccounts) || [];
  const balanceVisible = useSelector(selectHiddenBalance);
  const selectedSubAccount = useSelector(selectSelectedSubAccounts);
  const statusCloseOrders = useSelector(selectStatusCloseOrders);
  const statusClosePositions = useSelector(selectStatusClosePositions);
  const { balances, selectedBalance } = useSelector(selectBalances);

  const handleSetBalance = useCallback((subAccountId: number) => {
    const interval = setInterval(() => {
      const { balances } = store.getState().balances;

      if (balances && balances.sub_accounts.length > 0) {
        const currentBalance = balances.sub_accounts.find((account) => account.sub_account_id === subAccountId) || DEFAULT_BALANCE;

        dispatch(setBalance(currentBalance));
        clearInterval(interval);
      }
    }, 1000);
  }, [balances]);

  const closeAllPositions = async () => {
    const { payload } = await dispatch(fetchPositionsCloseAll());

    if (Array.isArray(payload) && payload.length === 0) {
      setAlertMessage(t('positions closed'), 'success');
    } else if (Array.isArray(payload) && payload.length > 0) {
      payload.forEach((detail: PositionCloseDetail) => {
        const message = `asset: ${detail.symbol}, detail: ${detail.detail}`;
        setAlertMessage(message, 'error');
      });
    } else if (typeof payload === 'string') {
      setAlertMessage(payload, 'error');
    } else {
      setAlertMessage('internal error', 'error');
    }
  };

  const closeAllOrders = async () => {
    const { payload } = await dispatch(fetchOrderCancelAll());

    if (Array.isArray(payload) && payload.length === 0) {
      setAlertMessage(t('orders canceled'), 'success');
    } else if (Array.isArray(payload) && payload.length > 0) {
      payload.forEach((detail: PositionCloseDetail) => {
        const message = `asset: ${detail.symbol}, detail: ${detail.detail}`;
        setAlertMessage(message, 'error');
      });
    } else if (typeof payload === 'string') {
      setAlertMessage(payload, 'error');
    } else {
      setAlertMessage('internal error', 'error');
    }
  };

  return visible ? (
    <div className={classNames(styles.adaptiveBalance, className)}>
      <div className={styles.container}>
        <div className={styles.balanceWrapper}>
          <AccountSelect
            dispatch={dispatch}
            selectedSubAccount={selectedSubAccount}
            onChangeBalanceById={handleSetBalance}
            subAccounts={subAccounts}
          />

          <AccountBlock
            closeMenu={closeMenu}
            selectedSubAccount={selectedSubAccount}
            balance={selectedBalance}
          />
        </div>

        <div className={styles.actions}>
          <div className={styles.pnl}>
            <p className={styles.title}>{t('pnl of active trades')}:</p>
            <p className={classNames(
              styles.value,
              { [styles.plus]: parseFloat(pnl || '0') > 0 },
              { [styles.minus]: parseFloat(pnl || '0') < 0 },
            )}
            >
              <span>{!balanceVisible ? (pnl || '0.0') : HIDDEN_BALANCES}</span>
              <span>USDT</span>
            </p>
          </div>

          <div className={styles.action}>
            <Button loading={statusClosePositions === EStatus.loading} disabled={statusClosePositions === EStatus.loading} onClick={closeAllPositions} background="green">{t('close all positions')}</Button>
            <Button loading={statusCloseOrders === EStatus.loading} disabled={statusCloseOrders === EStatus.loading} onClick={closeAllOrders} background="black">{t('close all orders')}</Button>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default AdaptiveBalance;
