import moment from 'moment';
import ru from 'date-fns/locale/ru';
import en from 'date-fns/locale/en-US';
import DatePicker from 'react-datepicker';

import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { setUserDateRange, join } from 'src/shared/libs/helpers/helper.lib';
import { DateRange, DateRangeType, Nullable } from 'src/shared/types/global-types';
import { ReactComponent as CalendarIconSvg } from 'src/shared/assets/images/account/calendar.svg';

import 'react-datepicker/dist/react-datepicker.css';
import './data-range-picker.scss';
import { useSelector } from 'react-redux';
import { selectLanguage } from 'src/app/store/slices/user/selectors';

const dateRangeList: DateRangeType[] = [
  {
    range: 'Today',
  },
  {
    range: 'Yesterday',
  },
  {
    range: 'Week',
  },
  {
    range: 'Month',
  },
  {
    range: 'Year',
  },
];

interface IDataPickerRangeProps {
  setDateRange: (value: any) => void
  dateRange: string[]
  setPage: (value: number) => void
  removeData: () => void
  monthsShown?: number
  whiteMode?: boolean
  disableDays?: boolean
  isClearDateRange?: () => void
  className?: string
}
const DataPickerRange = ({
  setDateRange, dateRange, setPage, removeData, whiteMode, monthsShown, disableDays, isClearDateRange, className,
}: IDataPickerRangeProps) => {
  const { t } = useTranslation();
  const globalLanguage = useSelector(selectLanguage);

  const [startDate, endDate] = dateRange;
  const [selectedDateRange, setSelectedDateRange] = useState<Nullable<DateRange>>(null);

  const handleSetDateRage = (update: [Nullable<Date>, Nullable<Date>]) => {
    const [firstData, secondData] = update;

    if (firstData && secondData) {
      setPage(1);
      removeData();
    }
    setSelectedDateRange(null);
    setDateRange(update);
  };

  const handleSetUserDateRange = (dateRage: DateRange) => {
    if (dateRage === selectedDateRange) return;

    setSelectedDateRange(dateRage);
    setUserDateRange(dateRage, setDateRange, setPage, removeData);
  };

  const language = useMemo(() => {
    if (!globalLanguage) return ru;
    if (globalLanguage === 'ru') return ru;
    if (globalLanguage === 'en') return en;

    return ru;
  }, [globalLanguage]);

  return (
    <div className="data-picker-container">
      {!disableDays && (
        <ul>
          {
            dateRangeList.map((date) => (
              <li
                key={date.range}
                role="button"
                tabIndex={0}
                onClick={() => handleSetUserDateRange(date.range)}
                onKeyDown={() => {}}
              >{t(date.range)}
              </li>
            ))
          }
        </ul>
      )}
      <div className={join('data-picker', whiteMode && 'white-mode')}>
        <CalendarIconSvg />
        <DatePicker
          selectsRange
          startDate={startDate ? moment(startDate).toDate() : undefined}
          endDate={endDate ? moment(endDate).toDate() : undefined}
          monthsShown={monthsShown || 2}
          showPreviousMonths
          dateFormat="dd/MM/yyyy"
          locale={language}
          isClearable
          onChange={(update) => {
            const t1 = update[0];
            const t2 = update[1];
            // Если update равен null, это значит, что кнопка очистки была нажата
            if (!t1 && !t2) {
              // Ваш обработчик: сброс фильтров или очистка состояния
              if (isClearDateRange) isClearDateRange();
              handleSetDateRage([null, null]);
            } else {
              handleSetDateRage(update);
            }
          }}
          placeholderText={t('time_frame')}
          className={className}
        />
      </div>
    </div>
  );
};

export default DataPickerRange;
