import { useState } from 'react';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';

import Hint from 'src/shared/ui/help/hint';
import Button from 'src/shared/ui/button/button/button';
import useAlert from 'src/shared/libs/hooks/use-alert';
import InputPassword from 'src/pages/auth/components/inputs-auth/input-password';
import ForgotPasswordService, { ForgotDataStepThree } from 'src/services/forgot-password.service';
import { selectForgotPassword } from '../../model/selectors';
import styles from './forgot-step-three.module.scss';

const schema = yup.object().shape({
  password: yup.string().required('password_required')
    .test('password', 'password_minimum_length', (value) => value.length >= 8)
    .test('lowercase', 'password_lowercase_required', (value) => /[a-z]/.test(value))
    .test('uppercase', 'password_uppercase_required', (value) => /[A-Z]/.test(value))
    .test('digits', 'password_digit_required', (value) => /\d/.test(value))
    .test('specialChars', 'password_special_char_required', (value) => /[!@#$%^&*]/.test(value)),
  reenterPassword: yup.string()
    .required('password_required')
    .oneOf([yup.ref('password')], 'passwords_do_not_match'),
});

type ForgotPassword = {
  password: string,
  reenterPassword: string
}

const ForgotStepThree = () => {
  const { t } = useTranslation();
  const { setAlertMessage } = useAlert();

  const navigate = useNavigate();
  const { email } = useSelector(selectForgotPassword);

  const [loading, setLoading] = useState(false);

  const form = useForm<ForgotPassword>({
    defaultValues: {
      password: '',
      reenterPassword: '',
    },
    resolver: yupResolver(schema),
  });

  const { control, handleSubmit, formState } = form;
  const { errors } = formState;

  const forgotPasswordStepThree = async (data: ForgotPassword) => {
    setLoading(true);

    const forgotData: ForgotDataStepThree = {
      email,
      new_password: data.password,
    };

    const result = await ForgotPasswordService.stepThree(forgotData);

    if (result.data) {
      setAlertMessage('password_changed_successfully', 'success');
      navigate('/auth/login');
    } else if (result instanceof AxiosError && result.response) {
      setLoading(false);
      const { detail } = result.response.data;

      if (detail === 'STEP_EXPIRED') {
        setAlertMessage('start_from_first_step', 'error');
      }
    } else {
      setLoading(false);
      setAlertMessage('internal error', 'error');
    }
  };

  const onSubmit = async (userData: ForgotPassword) => {
    forgotPasswordStepThree(userData);
  };

  return (
    <form className={styles.loginForm} onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="password"
        control={control}
        render={({
          field: {
            ref, value, onChange, ...fieldProps
          },
        }) => (
          <InputPassword
            ref={ref}
            value={value || ''}
            onChange={onChange}
            error={!!errors.password}
            placeholder={t('enter_password')}
            label={t('enter_password')}
            {...fieldProps}
          />
        )}
      />
      {errors.password && errors.password.message && <Hint error text={t(errors.password.message)} />}
      <Controller
        name="reenterPassword"
        control={control}
        render={({
          field: {
            ref, value, onChange, ...fieldProps
          },
        }) => (
          <InputPassword
            ref={ref}
            value={value || ''}
            onChange={onChange}
            error={!!errors.reenterPassword}
            placeholder={t('enter_password')}
            label={t('repeat_password')}
            {...fieldProps}
          />
        )}
      />
      {errors.reenterPassword && errors.reenterPassword.message && <Hint error text={t(errors.reenterPassword.message)} />}

      <Button type="submit" loading={loading} background="green">{t('сontinue')}</Button>
    </form>
  );
};

export default ForgotStepThree;
