import { useSelector } from 'react-redux';
import { selectAllSubAccounts } from 'src/app/store/slices/sub-accounts/selectors';
import { Nullable } from 'src/shared/types/global-types';

const useSubAccount = () => {
  const subAccounts = useSelector(selectAllSubAccounts);

  const getSubAccountNameById = (id: number) => {
    const findSubAccountById = subAccounts?.find((item) => item.id === id);
    return findSubAccountById ? findSubAccountById.user_name : 'Unknown';
  };

  const getSubAccountExchangeId = (subAccountId: Nullable<number>): number | null => {
    if (!subAccountId) return null;

    const findSubAccount = subAccounts?.find((subAccount) => subAccount.id === subAccountId);
    return findSubAccount ? findSubAccount.exchange_id : null;
  };

  const getExchangeName = (subAccountId: Nullable<number>): string | null => {
    if (!subAccountId) return null;

    const findSubAccount = subAccounts?.find((subAccount) => subAccount.id === subAccountId);
    return findSubAccount ? findSubAccount.exchange_name : null;
  };

  return { getSubAccountNameById, getSubAccountExchangeId, getExchangeName };
};

export default useSubAccount;
