import className from 'classnames';
import {
  useEffect, useLayoutEffect, useRef, useState,
} from 'react';
import { Divider, LoadingOverlay, Tabs } from '@mantine/core';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'src/app/store/store';
import { EThemeMode } from 'src/app/store/slices/user/types';
import { useChangePageTitle } from 'src/shared/libs/hooks/use-change-page-title';
import { selectSubAccounts } from 'src/app/store/slices/sub-accounts/selectors';

import { ReactComponent as BinanceIconSvg } from 'src/shared/assets/images/exchange/binance.svg';
import { ReactComponent as ByBitIconSvg } from 'src/shared/assets/images/exchange/bybit.svg';

import Hint from 'src/shared/ui/help/hint';
import Button from 'src/shared/ui/button/button/button';
import Toogler from 'src/shared/ui/toogler/toogler';
import InputForm from 'src/shared/ui/input/input-form/input-form';
import useExchange from 'src/shared/libs/hooks/use-exchange';

import { EStatus } from 'src/shared/types/global-types';
import { useTheme } from 'src/shared/libs/hooks/use-theme';
import useAlert from 'src/shared/libs/hooks/use-alert';
import Select from 'src/shared/ui/select/select';
import { TIME_INTERVALS } from 'src/shared/constants/constants';
import { extractTime, formatToIsoDate } from 'src/shared/libs/helpers/helper.lib';

import useMobile from 'src/shared/libs/hooks/useMobile';
import Empty from 'src/entities/empty/empty';
import { useTranslation } from 'react-i18next';
import { fetchChangeTradeSettings, fetchTradeSettings } from './model/thunks';
import { selectTradeSettings } from './model/selectors';
import { ETradeSettingsType, PartialTradingSettings } from './model/types';
import {
  authChangeLeverage, bnbForFuturesCommission, bnbForSpotCommission, excludedSubAccount, hideAccountBalance, multiAssetsMode,
  setBalancePnlResetTime,
} from './model/slice';

import styles from './settings.module.scss';

const pageTitle = 'trading_settings';
const tabsValue = ['General', 'Binance', 'ByBit'];
const iconStyle = { width: '20px', height: '20px' };
const threshold = 100; // Minimum distance to recognise a swipe gesture

const SettingsPage = () => {
  const { t } = useTranslation();
  useChangePageTitle(pageTitle);

  const isMobile = useMobile();
  const dispatch = useAppDispatch();
  const { setAlertMessage } = useAlert();

  const { theme } = useTheme();
  const { getExchangeImageById } = useExchange();

  const subAccounts = useSelector(selectSubAccounts);
  const { settings, status } = useSelector(selectTradeSettings);

  const [autoChangeStatus, setAutoChangeStatus] = useState(false);
  const [multiAssetsModeStatus, setMultiAssetsModeStatus] = useState(false);
  const [useBnBSpotStatus, setUseBnbSpotStatus] = useState(false);
  const [useBnBFuturesStatus, setUseBnbFuturesStatus] = useState(false);
  const [hideAccountBalanceStatus, setHideAccountBalanceStatus] = useState(false);

  const [closeOrders, setCloseOrders] = useState<number[]>([]);
  const [closePositions, setClosePositions] = useState<number[]>([]);
  const [excludedSubAccountLoading, setExcludedSubAccountLoading] = useState(false);

  const [tabValue, setTabValue] = useState(tabsValue[0]);
  const [tabIndex, setTabIndex] = useState(0);

  const touchStartXRef = useRef(0);
  const touchEndXRef = useRef(0);

  const isMobleStylesProps = isMobile ? {
    justify: 'center',
    grow: true,
  } : {};

  const nextPage = (value: number) => {
    let newIndex = (value + 1) % 3;
    setTabIndex(newIndex);
    return newIndex;
  };

  const previousPage = (value: number) => {
    let newIndex = (value - 1 + 3) % 3;
    setTabIndex(newIndex);
    return newIndex;
  };

  const handleGesture = () => {
    const horizontalSwipe = Math.abs(touchEndXRef.current - touchStartXRef.current) > threshold;

    if (horizontalSwipe) {
      if (touchEndXRef.current < touchStartXRef.current) {
        setTabValue(tabsValue[nextPage(tabIndex)]);
      } else {
        setTabValue(tabsValue[previousPage(tabIndex)]);
      }
    }
  };

  const handleClosePositions = (subAccountId: number) => {
    const index = closePositions.findIndex((item) => item === subAccountId);

    if (index !== -1) {
      const updatedPositions = [...closePositions];
      updatedPositions.splice(index, 1);
      setClosePositions(updatedPositions);
    } else {
      setClosePositions([...closePositions, subAccountId]);
    }
  };

  const handleCloseOrders = (subAccountId: number) => {
    const index = closeOrders.findIndex((item) => item === subAccountId);

    if (index !== -1) {
      const updatedPositions = [...closeOrders];
      updatedPositions.splice(index, 1);
      setCloseOrders(updatedPositions);
    } else {
      setCloseOrders([...closeOrders, subAccountId]);
    }
  };

  const handleReset = () => {
    if (subAccounts) {
      const subAccountIds = subAccounts.map((subAccount) => subAccount.id);

      setCloseOrders(subAccountIds);
      setClosePositions(subAccountIds);
    }
  };

  const handleChangeSettings = async (settingType: ETradeSettingsType, time?: string) => {
    if (!settings) return;

    if (settingType === ETradeSettingsType.AUTO_CHANGE_LEVERAGE) {
      setAutoChangeStatus(true);
      try {
        const params: PartialTradingSettings = {
          auto_change_leverage: !settings.auto_change_leverage,
        };
        const { payload } = await dispatch(fetchChangeTradeSettings(params));

        if (payload === true) {
          dispatch(authChangeLeverage(!settings.auto_change_leverage));
          setAlertMessage('settings_changed', 'success');
        } else if (typeof payload === 'string' && payload === 'TOO_MANY_REQUESTS') {
          setAlertMessage('too many attempts 15', 'warning');
        } else if (typeof payload === 'string') {
          setAlertMessage(payload, 'warning');
        } else {
          setAlertMessage('internal error', 'error');
        }
      } catch (error) {
        console.debug(error);
        setAlertMessage('internal error', 'error');
      } finally {
        setAutoChangeStatus(false);
      }
    }
    if (settingType === ETradeSettingsType.MULTI_ASSETS_MODE) {
      setMultiAssetsModeStatus(true);
      try {
        const params: PartialTradingSettings = {
          multi_assets_mode: !settings.multi_assets_mode,
        };
        const { payload } = await dispatch(fetchChangeTradeSettings(params));

        if (payload === true) {
          dispatch(multiAssetsMode(!settings.multi_assets_mode));
          setAlertMessage('settings_changed', 'success');
        } else if (typeof payload === 'string' && payload === 'TOO_MANY_REQUESTS') {
          setAlertMessage('too many attempts 15', 'warning');
        } else if (typeof payload === 'string') {
          setAlertMessage(payload, 'warning');
        } else {
          setAlertMessage('internal error', 'error');
        }
      } catch (error) {
        console.debug(error);
        setAlertMessage('internal error', 'error');
      } finally {
        setMultiAssetsModeStatus(false);
      }
    }
    if (settingType === ETradeSettingsType.USE_BNB_FOR_SPOT_COMMISSION) {
      setUseBnbSpotStatus(true);
      try {
        const params: PartialTradingSettings = {
          use_bnb_for_spot_commission: !settings.use_bnb_for_spot_commission,
        };

        const { payload } = await dispatch(fetchChangeTradeSettings(params));

        if (payload === true) {
          dispatch(bnbForSpotCommission(!settings.use_bnb_for_spot_commission));
          setAlertMessage('settings_changed', 'success');
        } else if (typeof payload === 'string' && payload === 'TOO_MANY_REQUESTS') {
          setAlertMessage('too many attempts 15', 'warning');
        } else if (typeof payload === 'string') {
          setAlertMessage(payload, 'warning');
        } else {
          setAlertMessage('internal error', 'error');
        }
      } catch (error) {
        console.debug(error);
        setAlertMessage('internal error', 'error');
      } finally {
        setUseBnbSpotStatus(false);
      }
    }
    if (settingType === ETradeSettingsType.USE_BNB_FOR_FUTURES_COMMISSION) {
      setUseBnbFuturesStatus(true);
      try {
        const params: PartialTradingSettings = {
          use_bnb_for_futures_commission: !settings.use_bnb_for_futures_commission,
        };

        const { payload } = await dispatch(fetchChangeTradeSettings(params));

        if (payload === true) {
          dispatch(bnbForFuturesCommission(!settings.use_bnb_for_futures_commission));
          setAlertMessage('settings_changed', 'success');
        } else if (typeof payload === 'string' && payload === 'TOO_MANY_REQUESTS') {
          setAlertMessage('too many attempts 15', 'warning');
        } else if (typeof payload === 'string') {
          setAlertMessage(payload, 'warning');
        } else {
          setAlertMessage('internal error', 'error');
        }
      } catch (error) {
        console.debug(error);
        setAlertMessage('internal error', 'error');
      } finally {
        setUseBnbFuturesStatus(false);
      }
    }
    if (settingType === ETradeSettingsType.HIDE_ACCOUNT_BALANCE) {
      setHideAccountBalanceStatus(true);
      try {
        const params: PartialTradingSettings = {
          hide_account_balance: !settings.hide_account_balance,
        };

        const { payload } = await dispatch(fetchChangeTradeSettings(params));

        if (payload === true) {
          const visible = !settings.hide_account_balance;
          dispatch(hideAccountBalance(visible));
          setAlertMessage('settings_changed', 'success');
        } else if (typeof payload === 'string' && payload === 'TOO_MANY_REQUESTS') {
          setAlertMessage('too many attempts 15', 'warning');
        } else if (typeof payload === 'string') {
          setAlertMessage(payload, 'warning');
        } else {
          setAlertMessage('internal error', 'error');
        }
      } catch (error) {
        console.debug(error);
        setAlertMessage('internal error', 'error');
      } finally {
        setHideAccountBalanceStatus(false);
      }
    }
    if (settingType === ETradeSettingsType.EXCLUDED_SUB_ACCOUNTS) {
      setExcludedSubAccountLoading(true);
      try {
        const params: PartialTradingSettings = {
          excluded_sub_accounts_close_positions: closePositions,
          excluded_sub_accounts_cancel_orders: closeOrders,
        };

        const { payload } = await dispatch(fetchChangeTradeSettings(params));

        if (payload === true) {
          dispatch(excludedSubAccount(params));
          setAlertMessage('settings_changed', 'success');
        } else if (typeof payload === 'string' && payload === 'TOO_MANY_REQUESTS') {
          setAlertMessage('too many attempts 15', 'warning');
        } else if (typeof payload === 'string') {
          setAlertMessage(payload, 'warning');
        } else {
          setAlertMessage('internal error', 'error');
        }
      } catch (error) {
        console.debug(error);
        setAlertMessage('internal error', 'error');
      } finally {
        setExcludedSubAccountLoading(false);
      }
    }
    if (settingType === ETradeSettingsType.BALANCE_PNL_RESET_TIME) {
      try {
        if (!time) return;

        const formattedTime = formatToIsoDate(time);

        const params: PartialTradingSettings = {
          balance_pnl_reset_time: formattedTime,
        };

        const { payload } = await dispatch(fetchChangeTradeSettings(params));

        if (payload === true) {
          setAlertMessage('settings_changed', 'success');
          dispatch(setBalancePnlResetTime(formattedTime));
        } else if (typeof payload === 'string' && payload === 'TOO_MANY_REQUESTS') {
          setAlertMessage('too many attempts 15', 'warning');
        } else if (typeof payload === 'string') {
          setAlertMessage(payload, 'warning');
        } else {
          setAlertMessage('internal error', 'error');
        }
      } catch (error) {
        console.debug(error);
        setAlertMessage('internal error', 'error');
      }
    }
  };

  const handleSetTabValue = (tabName: string, tabIndex: number) => {
    setTabValue(tabName);
    setTabIndex(tabIndex);
  };

  useLayoutEffect(() => {
    dispatch(fetchTradeSettings());
  }, []);

  useEffect(() => {
    if (settings) {
      setClosePositions(settings.excluded_sub_accounts_close_positions);
      setCloseOrders(settings.excluded_sub_accounts_cancel_orders);
    }
  }, [settings]);

  useEffect(() => {
    const handleTouchStart = (e: TouchEvent) => {
      const touch = e.changedTouches[0];
      touchStartXRef.current = touch.screenX;
    };

    const handleTouchEnd = (e: TouchEvent) => {
      const touch = e.changedTouches[0];
      touchEndXRef.current = touch.screenX;
      handleGesture();
    };

    document.addEventListener('touchstart', handleTouchStart, false);
    document.addEventListener('touchend', handleTouchEnd, false);

    return () => {
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchend', handleTouchEnd);
    };
  }, [tabIndex]);

  if (!settings && status === EStatus.loading) {
    return (
      <div className="loading-wrapper">
        <LoadingOverlay
          className="loader"
          visible
          zIndex={1000}
          overlayProps={{ radius: 'sm', blur: 2 }}
          loaderProps={{ color: '#00C37C', type: 'dots' }}
        />
      </div>
    );
  }

  if (!settings && status === EStatus.rejected) {
    return (
      <div className="loading-wrapper">
        {t('internal_error_fetching_trading_settings')}
        <br />
        {t('try_refreshing_page')}
      </div>
    );
  }

  return (
    <div className={styles.settingsPage}>
      <h1 className={styles.title}>{t('trading_settings')}</h1>
      <p className={styles.subtitle}>{t('trading_modes_settings')}</p>

      <Tabs className={styles.tabsContainer} color="#00AB6D" value={tabValue} defaultValue={tabValue}>
        <Tabs.List {...isMobleStylesProps} className={styles.tabNavigation}>
          <Tabs.Tab className={styles.tabItem} onClick={() => handleSetTabValue('General', 0)} value="General">{t('general')}</Tabs.Tab>
          <Tabs.Tab className={styles.tabItem} leftSection={<BinanceIconSvg style={iconStyle} />} onClick={() => handleSetTabValue('Binance', 1)} value="Binance"> Binance</Tabs.Tab>
          <Tabs.Tab className={styles.tabItem} leftSection={<ByBitIconSvg style={iconStyle} />} onClick={() => handleSetTabValue('ByBit', 2)} value="ByBit"> Bybit</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel className={styles.tabContent} value="General">
          <div className={styles.content}>
            <section className={styles.section}>
              <h2>{t('leverage_settings').toUpperCase()}</h2>
              <Divider color={theme === EThemeMode.LIGHT ? '#E8E8E8' : '#3A3A3A'} />

              <div className={styles.block}>
                <Toogler
                  isActive={!!settings?.auto_change_leverage}
                  onChange={() => handleChangeSettings(ETradeSettingsType.AUTO_CHANGE_LEVERAGE)}
                  labelContent={t('auto_leverage_adjustment')}
                  isLoading={autoChangeStatus}
                />

                <Hint className={styles.hintWrapper} mediumText text={t('auto_leverage_description')} />
                <p className={styles.hintInfo}>{t('leverage_change_warning')}</p>
              </div>
            </section>

            <section className={styles.section}>
              <h2>{t('balance_settings')}</h2>
              <Divider color={theme === EThemeMode.LIGHT ? '#E8E8E8' : '#3A3A3A'} />

              <div className={styles.block}>
                <Toogler
                  isActive={!!settings?.hide_account_balance}
                  onChange={() => handleChangeSettings(ETradeSettingsType.HIDE_ACCOUNT_BALANCE)}
                  labelContent={t('hide_account_balance')}
                  isLoading={hideAccountBalanceStatus}
                />
              </div>

            </section>

            <section className={styles.section}>
              <h2>{t('update_pnl')}</h2>
              <Divider color={theme === EThemeMode.LIGHT ? '#E8E8E8' : '#3A3A3A'} />

              <div className={styles.block}>
                <div className={styles.inputSelect}>
                  <Select
                    isActiveSearch={false}
                    isSelectValue={(
                      <div className={styles.selectItem}>
                        <span className="short-name">{extractTime(settings?.balance_pnl_reset_time)} {t('local_time')}</span>
                      </div>
                    )}
                  >
                    {
                      TIME_INTERVALS.map((time) => (
                        <div
                          key={time}
                          className={styles.selectItem}
                          role="button"
                          tabIndex={0}
                          onClick={() => handleChangeSettings(ETradeSettingsType.BALANCE_PNL_RESET_TIME, time)}
                          onKeyDown={() => handleChangeSettings(ETradeSettingsType.BALANCE_PNL_RESET_TIME, time)}
                        >
                          <span className="short-name">{time}</span>
                        </div>
                      ))
                    }
                  </Select>
                </div>

                <Hint className={styles.hintWrapper} mediumText text={t('set_pnl_update_time')} />
              </div>

            </section>

            <section className={styles.section}>
              <h2>{t('close_positions_orders')}</h2>
              <Divider color={theme === EThemeMode.LIGHT ? '#E8E8E8' : '#3A3A3A'} />

              <div className={styles.block}>
                <Hint className={styles.hintWrapper} mediumText text={t('close_positions_and_orders_instruction')} />
              </div>

              <div className={styles.block}>
                <InputForm title={t('close_open_positions_on_accounts')}>
                  <div className={styles.subAccounts}>
                    {
                      subAccounts && subAccounts.map((subAccount) => (
                        <button
                          key={subAccount.id}
                          className={className(styles.subAccount, { [styles.select]: !closePositions.some((item) => item === subAccount.id) })}
                          onClick={() => handleClosePositions(subAccount.id)}
                        >
                          <img src={getExchangeImageById(subAccount.exchange_id)} alt="exchange" />
                          <span>{subAccount.user_name}</span>
                        </button>
                      ))
                    }
                  </div>
                </InputForm>
              </div>

              <div className={styles.block}>
                <InputForm title={t('cancel_limit_orders_on_accounts')}>
                  <div className={styles.subAccounts}>
                    {
                      subAccounts && subAccounts.map((subAccount) => (
                        <button
                          key={subAccount.id}
                          className={className(styles.subAccount, { [styles.select]: !closeOrders.some((item) => item === subAccount.id) })}
                          onClick={() => handleCloseOrders(subAccount.id)}
                        >
                          <img src={getExchangeImageById(subAccount.exchange_id)} alt="exchange" />
                          <span>{subAccount.user_name}</span>
                        </button>
                      ))
                    }
                  </div>
                </InputForm>
              </div>

              <div className={styles.actions}>
                <Button loading={excludedSubAccountLoading} disabled={excludedSubAccountLoading} onClick={() => handleChangeSettings(ETradeSettingsType.EXCLUDED_SUB_ACCOUNTS)} background="green">{t('apply')}</Button>
                <Button disabled={excludedSubAccountLoading} onClick={handleReset} background="gray">{t('exclude_all')}</Button>
              </div>

            </section>
          </div>
        </Tabs.Panel>

        <Tabs.Panel className={styles.tabContent} value="Binance">
          <div className={styles.content}>
            <section className={styles.section}>
              <h2>{t('futures_trading')}</h2>
              <Divider color={theme === EThemeMode.LIGHT ? '#E8E8E8' : '#3A3A3A'} />

              <div className={styles.block}>
                <div className={styles.multiassetMode}>
                  <Toogler
                    isActiveDisabled
                    isActive
                    onChange={() => handleChangeSettings(ETradeSettingsType.MULTI_ASSETS_MODE)}
                    labelContent={t('multi_asset_mode')}
                    isLoading={multiAssetsModeStatus}
                  />

                  <Hint className={styles.hintWrapper} mediumText text={t('multi_asset_mode_description')} />
                  <ul className={styles.multiassetList}>
                    <li>{t('multi_asset_mode_disable_error')}</li>
                    <li>{t('negative_balance_warning')}</li>
                    <li>{t('auto_closing_warning')}</li>
                  </ul>
                </div>
              </div>

              <div className={styles.block}>
                <Toogler
                  isActive={!!settings?.use_bnb_for_futures_commission}
                  onChange={() => handleChangeSettings(ETradeSettingsType.USE_BNB_FOR_FUTURES_COMMISSION)}
                  labelContent={t('use_BNB_for_futures_fee')}
                  isLoading={useBnBFuturesStatus}
                />

                <Hint className={styles.hintWrapper} mediumText text={t('available_only_with_sufficient_BNB_balance')} />

              </div>
            </section>

            <section className={styles.section}>
              <h2>{t('spot_trading')}</h2>
              <Divider color={theme === EThemeMode.LIGHT ? '#E8E8E8' : '#3A3A3A'} />

              <div className={styles.block}>
                <Toogler
                  isActive={!!settings?.use_bnb_for_spot_commission}
                  onChange={() => handleChangeSettings(ETradeSettingsType.USE_BNB_FOR_SPOT_COMMISSION)}
                  labelContent={t('use_bnb_for_spot_fee')}
                  isLoading={useBnBSpotStatus}
                />

                <Hint className={styles.hintWrapper} mediumText text={t('available_with_enough_bnb_spot')} />

              </div>

            </section>
          </div>

        </Tabs.Panel>

        <Tabs.Panel className={styles.tabContent} value="ByBit">
          <div className={styles.emptyWrapper}>
            <Empty>{t('page_in_development')}</Empty>
          </div>
        </Tabs.Panel>
      </Tabs>
    </div>
  );
};

export default SettingsPage;
