import { useTranslation } from 'react-i18next';
import { useChangePageTitle } from 'src/shared/libs/hooks/use-change-page-title';
import Empty from 'src/entities/empty/empty';

import './news.scss';

const pageTitle = 'news';

const NewsPage = () => {
  const { t } = useTranslation();
  useChangePageTitle(pageTitle);

  return (
    <div className="news-page">
      <Empty>{t('page_in_development')}...</Empty>
    </div>
  );
};

export default NewsPage;
