const informSlide2 = [
  'inform_min_8_characters',
  'inform_lowercase_letters',
  'inform_uppercase_letters',
  'inform_numbers',
  'inform_special_characters',
];

export const ERROR_MSG_LIST: Record<string, string> = {
  EMAIL_EXISTS: 'email_taken',
  RECAPTCHA_TOKEN_REQUIRED: 'fill_captcha',
  TOO_MANY_REQUESTS: 'too many attempts 15',
  INVALID_CAPTCHA: 'Кcaptcha_expired',
  INVALID_INVITE_CODE: 'invalid_invite_code',
  INVITE_CODE_USED: 'invite_code_expired',
  INVITE_CODE_REQUIRED: 'invite_code_required',
  DEFAULT: 'internal error  ',

};
export default informSlide2;
