import React from 'react';
import { useSelector } from 'react-redux';
import { selectAlert } from 'src/app/store/slices/alert/selectors';
import NotificationAudio from 'src/shared/audio/notification.mp3';
import { SYSTEM_NOTIFICATION } from 'src/shared/constants/constants';
import useAlert from 'src/shared/libs/hooks/use-alert';

const AudioAlert = () => {
  const { setAlertMessage } = useAlert();
  const { systemAlert } = useSelector(selectAlert);

  const audioRef = React.useRef<HTMLAudioElement>(null);

  React.useEffect(() => {
    if (systemAlert) {
      setAlertMessage(SYSTEM_NOTIFICATION, 'warning', true);

      if (audioRef.current) {
        audioRef.current.play().catch((error) => {
          console.debug('Autoplay was prevented:', error);
        });
      }
    }
  }, [systemAlert]);

  return (
    <audio style={{ display: 'none' }} ref={audioRef} controls>
      <source src={NotificationAudio} type="audio/mpeg" />
      <track kind="captions" src="empty.vtt" label="Russian" />
      Your browser does not support the audio element.
    </audio>
  );
};

export default AudioAlert;
