import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'src/app/store/store';
import { ReactComponent as FilterIconSvg } from 'src/shared/assets/images/filter.svg';
import { ReactComponent as SearchIconSvg } from 'src/shared/assets/images/glass.svg';
import { ReactComponent as SettingsIconSvg } from 'src/shared/assets/images/aside/settings.svg';
import DefaultIconSvg from 'src/shared/assets/images/lot.png';
import { useChangePageTitle } from 'src/shared/libs/hooks/use-change-page-title';
import { Exchange } from 'src/app/store/slices/exchanges/types';
import { SubAccountType } from 'src/app/store/slices/sub-accounts/types';
import { EStatus, Nullable } from 'src/shared/types/global-types';

import useAlert from 'src/shared/libs/hooks/use-alert';

import {
  debounce, isDetailError, sortSubAccountsByFavorite,
} from 'src/shared/libs/helpers/helper.lib';
import { selectSubAccounts } from 'src/app/store/slices/sub-accounts/selectors';

import { Divider, LoadingOverlay } from '@mantine/core';
import { useTheme } from 'src/shared/libs/hooks/use-theme';
import { EThemeMode } from 'src/app/store/slices/user/types';
import SelectSkeleton from 'src/shared/ui/skeleton/select-skeleton/select-skeleton';
import { clearSubAccountSettings } from 'src/app/store/slices/sub-accounts/slice';
import Empty from 'src/entities/empty/empty';
import { useIntersectionObserver } from 'src/shared/libs/hooks/use-observer';
import { Drawer } from '@mui/material';
import { useDisclosure } from '@mantine/hooks';
import ButtonClose from 'src/shared/ui/button/button-close/button-close';
import InputForm from 'src/shared/ui/input/input-form/input-form';
import Select from 'src/shared/ui/select/select';
import Button from 'src/shared/ui/button/button/button';
import InputText from 'src/shared/ui/input/input-text/input-text';
import classNames from 'classnames';
import useExchange from 'src/shared/libs/hooks/use-exchange';
import { unwrapResult } from '@reduxjs/toolkit';
import { fetchSubAccountSettings } from 'src/app/store/slices/sub-accounts/thunks';
import { useTranslation } from 'react-i18next';
import { selectLeverages } from '../model/selectors';
import {
  fetchInstruments, fetchSetInstrumentParams, fetchSetLeverageInstrument, InstrumentsParams, SetLeverageParams,
} from '../model/thunks';
import { clearInstruments } from '../model/slice';
import {
  Instrument, InstrumentParams,
} from '../model/types';
import { CALCULATION_LEVERAGE_ERROR_MSG, LEVERAGE, MARGIN_MODE } from '../constants';
import { Calculator } from '../components/calculator';
import AutoLeverages from '../components/auto-leverages/auto-leverages';
import InstrumentItem from './components/leverage-item/instrument-item';
import MarginModeSetting from '../components/margin-mode-setting/margin-mode-setting';
import AutoChangesLeverages from '../components/auto-changes-leverages/auto-changes-leverages';
import AutoLeveragesCalculator from '../components/auto-leverages-calculator/auto-leverages-calculator';
import styles from './leverage-adaptive.module.scss';

const pageTitle = 'leverages';
const skeletonItems = Array.from({ length: 15 }, (_, index) => index);

const LeveragesAdaptive = () => {
  const { t } = useTranslation();
  useChangePageTitle(pageTitle);

  const dispatch = useAppDispatch();
  const { theme } = useTheme();
  const { setAlertMessage } = useAlert();
  const { getExchangeImageById, availableExchanges: exchanges } = useExchange();

  const [openedCalculator, { open: openCalculator, close: closeCalculator }] = useDisclosure(false);
  const [openedFilters, { open: openFilters, close: closeFilters }] = useDisclosure(false);
  const [openedSettings, { open: openSettings, close: closeSettings }] = useDisclosure(false);

  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [exchangeId, setExchangeId] = useState(0);
  const [currentExchange, setCurrentExchange] = useState<Nullable<Exchange>>(null);
  const [selectedExchange, setSelectedExchange] = useState<Nullable<Exchange>>(null);
  const [currentSubAccount, setCurrentSubAccount] = useState<Nullable<SubAccountType>>(null);
  const [selectedSubAccount, setSelectedSubAccount] = useState<Nullable<SubAccountType>>(null);
  const [currentInstrument, setCurrentInstrument] = useState<Nullable<Instrument>>(null);

  const [marginMode, setMarginMode] = useState(2);
  const [choosenBracket, setChoosenBracket] = useState<number[]>([]);

  // settings state
  const [autoCalculator, setAutoCalculator] = useState(false);
  const [selectedLeverageValue, setSelectedLeverageValue] = useState(0);

  const subAccounts = useSelector(selectSubAccounts);

  const {
    status: statusInstruments, instruments, statusParams, statusSetLeverage,
  } = useSelector(selectLeverages);
  const totalPage = instruments ? instruments.meta.total_pages : 1;
  const marginModeOfSelectedInstrument = MARGIN_MODE.find((item) => item.value === marginMode);
  useIntersectionObserver(page, totalPage, instruments?.items, 'instrument-item', setPage);

  const debouncedFetch = useCallback(
    debounce((queryParams: InstrumentsParams) => {
      dispatch(clearInstruments());
      dispatch(fetchInstruments(queryParams));
    }, 1000) as any,
    [dispatch],
  );

  const handleCloseCalculator = () => {
    closeCalculator();
    setCurrentInstrument(null);
  };

  const handleOpenCalculator = (instrument: Instrument) => {
    if (instrument.margin_mode) {
      setMarginMode(instrument.margin_mode);
    } else {
      setMarginMode(1);
    }

    setCurrentInstrument(instrument);
    openCalculator();
  };

  const handleSaveCalculcator = async () => {
    if (currentSubAccount && currentInstrument) {
      const params: InstrumentParams = {
        instrument_id: currentInstrument?.instrument.id,
        choosen_bracket: choosenBracket,
      };

      if (marginMode) {
        params.margin_mode = marginMode;
      }

      const { payload } = await dispatch(fetchSetInstrumentParams({ params, subAccountId: currentSubAccount.id }));

      if (payload === true) {
        setAlertMessage('settings_saved_successfully', 'success');
        closeCalculator();
      } else if (payload === 'string') {
        closeCalculator();
        setAlertMessage(payload, 'warning');
      } else {
        closeCalculator();
        setAlertMessage('negative_response', 'error');
      }
    }
  };

  const handleSetCurrentSubAccount = (subAccount: SubAccountType) => {
    if (subAccount.id === selectedSubAccount?.id) return;

    setSearchValue('');
    setPage(1);
    setSelectedSubAccount(subAccount);
  };

  const handleSetCurrentExchange = (exchange: Exchange) => {
    if (exchange.id === selectedExchange?.id) return;

    const firstSubAccount = subAccounts?.find((subAccount) => subAccount.exchange_id === exchange.id);

    setPage(1);
    setSelectedExchange(exchange);

    if (firstSubAccount) setSelectedSubAccount(firstSubAccount);
  };

  const handleSetFetchInstrumentsParams = () => {
    if (selectedExchange) setExchangeId(selectedExchange.id);

    setCurrentSubAccount(selectedSubAccount);
    setCurrentExchange(selectedExchange);

    dispatch(clearInstruments());
    closeFilters();
  };

  const handleSetLeverage = async () => {
    if (!currentSubAccount) return;

    try {
      const params: SetLeverageParams = {
        choosen_bracket: selectedLeverageValue,
        sub_account_id: currentSubAccount.id,
        excluded_instrument_ids: [],
      };

      const response = await dispatch(fetchSetLeverageInstrument(params));
      const payload = unwrapResult(response);

      if (payload === true) {
        setAlertMessage('leverage_calculation_in_process', 'warning');
      } else if (isDetailError(payload)) {
        const message = CALCULATION_LEVERAGE_ERROR_MSG[payload.detail] || CALCULATION_LEVERAGE_ERROR_MSG.default;
        setAlertMessage(message.msg, message.type);
      } else {
        const responseMessage = typeof payload === 'string' ? payload : JSON.stringify(payload);
        setAlertMessage(responseMessage, 'error');
      }
    } catch (error) {
      const message = typeof error === 'string' ? CALCULATION_LEVERAGE_ERROR_MSG[error] : CALCULATION_LEVERAGE_ERROR_MSG.default;
      setAlertMessage(message.msg, message.type);
    }
  };

  const handleCloseFilters = () => {
    closeFilters();
    setSelectedSubAccount(null);
    setSelectedExchange(null);
  };

  const handleOpenFilters = () => {
    setSelectedSubAccount(currentSubAccount);
    setSelectedExchange(currentExchange);
    openFilters();
  };

  useEffect(() => {
    if (openedFilters) return;

    if (currentSubAccount) {
      let queryParams: InstrumentsParams = {
        limit: 20,
        page,
        subAccountId: currentSubAccount.id,
      };

      if (searchValue.length > 1) {
        setPage(1);
        queryParams.symbol = searchValue.toUpperCase();
        debouncedFetch.execute(queryParams);
        return;
      }

      if (searchValue.length === 0) {
        debouncedFetch.stop();
        dispatch(fetchInstruments(queryParams));
      }
    }
  }, [page, searchValue, currentSubAccount, openedFilters]);

  useEffect(() => {
    if (!subAccounts || !exchanges) return;

    const favoriteSubAccount = subAccounts.find((subAccount) => subAccount.is_favorite);
    const exchangeByFavoriteSubAccount = exchanges.find((exchange) => exchange.id === favoriteSubAccount?.exchange_id);

    if (favoriteSubAccount) {
      setCurrentSubAccount(favoriteSubAccount);
    } else {
      const [favoriteSubAccount] = subAccounts.slice().sort(sortSubAccountsByFavorite);
      const [currentExchange] = exchanges;

      if (favoriteSubAccount) {
        setCurrentSubAccount(favoriteSubAccount);
      }

      if (currentExchange) {
        setCurrentExchange(currentExchange);
        setExchangeId(currentExchange.id);
      }
    }

    if (exchangeByFavoriteSubAccount) {
      setCurrentExchange(exchangeByFavoriteSubAccount);
      setExchangeId(exchangeByFavoriteSubAccount.id);
    }
  }, [subAccounts, exchanges]);

  useEffect(() => {
    if (currentSubAccount) {
      dispatch(fetchSubAccountSettings(currentSubAccount.id));
    }
  }, [currentSubAccount]);

  useEffect(() => () => {
    dispatch(clearInstruments());
    dispatch(clearSubAccountSettings());
  }, []);

  return (
    <div className={styles.leverageAdaptive}>
      <header>
        <h1>{t('leverage_settings')}</h1>
        <div className={styles.actions}>
          <FilterIconSvg onClick={handleOpenFilters} />
          <SearchIconSvg onClick={() => setShowSearchInput((prev) => !prev)} className={styles.actionBtn} />
          <SettingsIconSvg onClick={openSettings} className={styles.actionBtn} />
        </div>
      </header>

      <InputText
        className={classNames(styles.search, { [styles.show]: showSearchInput })}
        value={searchValue}
        placeholder="Tether (USDT)"
        onChange={setSearchValue}
      />

      <Divider className={styles.divider} color={theme === EThemeMode.LIGHT ? '#E8E8E8' : '#3A3A3A'} />

      <ul className={styles.leveragesTable}>
        {instruments && instruments.items.map((instrument) => (
          <li className="instrument-item" key={instrument.instrument.id}>
            <InstrumentItem openCalculator={handleOpenCalculator} instrument={instrument} instrumentExchangeId={exchangeId} />
          </li>
        ))}
        {statusInstruments === EStatus.loading && !instruments && skeletonItems.map((skeleton) => <SelectSkeleton key={skeleton} height={53} />)}
      </ul>

      {statusInstruments === EStatus.rejected && (
        <div className={styles.emptyWrapper}>
          <Empty className="empty-position" error>{t('no_available_instruments')}</Empty>
        </div>
      )}

      {statusInstruments === EStatus.loading && instruments && (
        <LoadingOverlay
          className="loader"
          visible
          zIndex={1000}
          overlayProps={{ radius: 'sm', blur: 2 }}
          loaderProps={{ color: '#00C37C', type: 'dots' }}
        />
      )}

      <Drawer className={styles.drawer} onClose={handleCloseCalculator} anchor="bottom" open={openedCalculator}>
        <div className={styles.filterWrapper}>
          <div className={styles.header}>
            <h3>{t('credit_leverage')}</h3>
            <ButtonClose className={styles.closeBtn} onClick={handleCloseCalculator} />
          </div>

          <div className={styles.content}>
            {currentInstrument && <Calculator instrument={currentInstrument} setChoosenBracket={setChoosenBracket} />}

            <InputForm title={t('margin_mode')}>
              <Select isActiveSearch={false} isSelectValue={<span className={styles.selectItem}>{t(marginModeOfSelectedInstrument?.name)}</span>}>
                { MARGIN_MODE.map((item) => (
                  <span
                    className={styles.selectItem}
                    key={item.value}
                    role="button"
                    tabIndex={0}
                    onClick={() => setMarginMode(item.value)}
                    onKeyDown={() => setMarginMode(item.value)}
                  >{t(item.name)}
                  </span>
                )) }
              </Select>
            </InputForm>

            <div className={styles.calculatorActions}>
              <Button
                disabled={statusParams === EStatus.loading}
                loading={statusParams === EStatus.loading}
                onClick={handleSaveCalculcator}
                background="green"
              >{t('save')}
              </Button>
              <Button disabled={statusParams === EStatus.loading} onClick={handleCloseCalculator} background="black">{t('close')}</Button>
            </div>
          </div>
        </div>
      </Drawer>

      <Drawer className={styles.drawer} onClose={handleCloseFilters} anchor="bottom" open={openedFilters}>
        <div className={styles.filterWrapper}>
          <div className={styles.header}>
            <h3>{t('filters')}</h3>
            <ButtonClose className={styles.closeBtn} onClick={handleCloseFilters} />
          </div>

          <div className={styles.contentFilter}>
            <InputForm title={t('exchange')}>
              {exchanges && selectedExchange ? (
                <Select
                  isActiveSearch={false}
                  isSelectValue={(
                    <div key={selectedExchange.name} className={styles.selectItem}>
                      <img src={selectedExchange.image} alt="exchange" />
                      <span className="short-name">{selectedExchange.name}</span>
                    </div>
                  )}
                >
                  {
                    exchanges.map((exchange) => (
                      <div
                        key={exchange.name}
                        className={styles.selectItem}
                        role="button"
                        tabIndex={0}
                        onClick={() => handleSetCurrentExchange(exchange)}
                        onKeyDown={() => {}}
                      >
                        <img src={exchange.image} alt="exchange" />
                        <span className="short-name">{exchange.name}</span>
                      </div>
                    ))
                  }
                </Select>
              ) : <SelectSkeleton /> }
            </InputForm>

            <InputForm title={t('account')}>
              {subAccounts && selectedSubAccount ? (
                <Select
                  position="bottom"
                  isActiveSearch={false}
                  isSelectValue={(
                    <div className={styles.selectItem}>
                      <img src={getExchangeImageById(selectedSubAccount.exchange_id) || DefaultIconSvg} alt="exhange" />
                      <span className="short-name">{selectedSubAccount.user_name}</span>
                    </div>
                  )}
                >
                  { subAccounts.filter((subAccount) => subAccount.exchange_id === selectedExchange?.id).sort(sortSubAccountsByFavorite).map((account) => (
                    <div
                      key={account.id}
                      className={styles.selectItem}
                      role="button"
                      tabIndex={0}
                      onClick={() => handleSetCurrentSubAccount(account)}
                      onKeyDown={() => {}}
                    >
                      <img src={getExchangeImageById(account.exchange_id) || DefaultIconSvg} alt="exhange" />
                      <span className="short-name">{account.user_name}</span>
                    </div>
                  ))}
                </Select>
              ) : <SelectSkeleton /> }
            </InputForm>
            <div className={styles.calculatorActions}>
              <Button onClick={handleSetFetchInstrumentsParams} background="green">{t('save')}</Button>
              <Button disabled={statusParams === EStatus.loading} onClick={closeFilters} background="black">{t('close')}</Button>
            </div>
          </div>
        </div>
      </Drawer>

      <Drawer className={styles.drawer} onClose={closeSettings} anchor="bottom" open={openedSettings}>
        <div className={styles.filterWrapper}>
          <div className={styles.header}>
            <h3>{t('general_settings')}</h3>
            <ButtonClose className={styles.closeBtn} onClick={closeSettings} />
          </div>

          <div className={styles.contentSettings}>
            <div className={styles.leverageSettings}>
              <h1 className={styles.title}>{t('general_settings')}</h1>

              <p>{t('for_all_instruments')} {instruments?.meta.total_items}</p>
              <ul className={styles.leverageItemWrapper}>
                {
                  LEVERAGE.map((leverage) => (
                    <li
                      key={leverage}
                      className={classNames(styles.leverageItem, { [styles.selectedItem]: selectedLeverageValue === leverage })}
                      role="button"
                      tabIndex={0}
                      onClick={() => setSelectedLeverageValue(leverage)}
                      onKeyDown={() => setSelectedLeverageValue(leverage)}
                    >
                      {leverage}
                    </li>
                  ))
                }
              </ul>

              <Button loading={statusSetLeverage === EStatus.loading} disabled={statusSetLeverage === EStatus.loading} onClick={handleSetLeverage} background="green">{t('apply_to_all_instruments')}</Button>
            </div>
            <AutoLeverages openCalculator={autoCalculator} setAutoCalculator={setAutoCalculator} subAccountId={currentSubAccount?.id} />
            <AutoLeveragesCalculator openAutoCalculator={!autoCalculator} subAccountId={currentSubAccount?.id} />
            <AutoChangesLeverages />
            <MarginModeSetting subAccountId={currentSubAccount?.id} />
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default LeveragesAdaptive;
