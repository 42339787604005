import i18next from 'i18next';
import { TRADING_TYPES } from 'src/shared/constants/constants';
import { TradingType } from 'src/shared/types/global-types';
import { FilterOption, PositionsSummary } from '../../model/types';

export const getDealText = (count: number): string => {
  const lastDigit = count % 10;
  const lastTwoDigits = count % 100;

  if (lastTwoDigits >= 11 && lastTwoDigits <= 14) {
    return `${count} ${i18next.t('trades_selected')}`;
  }
  if (lastDigit === 1) {
    return `${count} ${i18next.t('one_trade_selected')}`;
  } if (lastDigit >= 2 && lastDigit <= 4) {
    return `${count} ${i18next.t('selected_trades')}`;
  }
  return `${count} ${i18next.t('trades_selected')}`;
};

export const getTradingTypeName = (type: TradingType): string | undefined => {
  const result = TRADING_TYPES.find((item) => item.type === type);
  return result?.name;
};

export const getLabelOfValue = (data: FilterOption[], value: [number | null, number | null]) => {
  const result = data.find((item) => item.value === value);
  return result?.label;
};

export const formatTime = (seconds: number): string => {
  const days = Math.floor(seconds / 86400);
  const hours = Math.floor((seconds % 86400) / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;

  const parts = [];

  if (days > 0) {
    parts.push(`${days}${i18next.t('d')}`);
  }
  if (hours > 0) {
    parts.push(`${hours}${i18next.t('h')}`);
  }
  if (minutes > 0) {
    parts.push(`${minutes}${i18next.t('m')}`);
  }

  // Всегда добавляем секунды, даже если они равны 0
  parts.push(`${secs}${i18next.t('s')}`);

  return parts.join(', ');
};

export const onlyIntegers = (event: React.KeyboardEvent<HTMLInputElement>) => {
  if (event.key === '.' || event.key === ',') {
    event.preventDefault();
  }
};

export const geTickerParamstFromLocalStorage = () => {
  try {
    const storedValue = sessionStorage.getItem('tickerParams');
    if (storedValue) {
      return JSON.parse(storedValue);
    }
  } catch (error) {
    console.debug(`Failed to get tickerParams from localStorage: ${error}`);
  }

  return null;
};

export const getComparableValue = (column: string, item: PositionsSummary) => {
  switch (column) {
  case 'instrument':
    return item.instrument.symbol;
  case 'sub_account_id':
    return item.sub_account_id;
  case 'exchange_id':
    return item.exchange_id;
  case 'side':
    return item.side;
  case 'entry_price':
  case 'exit_price':
  case 'pnl':
  case 'quantity_usdt':
  case 'quantity':
  case 'trade_max_quantity':
  case 'profit':
  case 'funding_fee':
  case 'cumulative_quantity':
  case 'commission':
  case 'max_loss':
  case 'max_loss_percent':
  case 'max_profit':
  case 'max_profit_percent':
    return parseFloat(item[column] || '0');
  case 'leverage':
    return item.leverage || 0;
  case 'duration':
    return item.duration;
  case 'entered_at':
    return item.entered_at instanceof Date ? item.entered_at.getTime() : new Date(item.entered_at).getTime() || 0;
  case 'exited_at':
    return item.exited_at instanceof Date ? item.exited_at.getTime() : (item.exited_at ? new Date(item.exited_at).getTime() : null);
  case 'pnl_percent':
    return item.pnl_percent;
  default:
    return null;
  }
};
