import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setHandleConnectedWebSocket } from 'src/app/store/slices/alert/slice';
import { setAuth } from 'src/pages/auth/login/model/slice';
import { socket } from 'src/shared/api/websocket.instance';

export const useStorageListener = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const logoutEvent = () => {
      if (localStorage.length === 0) {
        dispatch(setAuth(false));
        dispatch(setHandleConnectedWebSocket(false));

        try {
          socket?.close();
        } catch (error) {
          console.debug('Ошибка при закрытии WebSocket:', error);
        }
      }
    };

    window.addEventListener('storage', logoutEvent);

    return () => {
      window.removeEventListener('storage', logoutEvent);
    };
  }, []);
};
