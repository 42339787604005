import { $api } from 'src/shared/api/axios.instance';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ROUTE_PATH } from 'src/shared/constants/route.path';
import { AxiosError } from 'axios';
import { TradingType } from 'src/shared/types/global-types';
import { ReferralBonusStatistics, ReferralsBonusesData, ReferralsStatistics } from './types';

export type ReferralsBonusesParams = {
  from_date?: string
  to_date?: string
  trading_type?: TradingType
  exchange_id?: number
  page: number
  limit: number
  user_id?: number
}

export type ReferralBonusStatisticsParams = {

}

export const fetchReferralsBonuses = createAsyncThunk(
  'referrals/fetchReferralsBonuses',
  async (params: ReferralsBonusesParams, { rejectWithValue }) => {
    try {
      const response = await $api.get<ReferralsBonusesData>(ROUTE_PATH.referrals.bonuses, { params });

      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error instanceof Error ? error.message : String(error));
    }
  },
);

export const fetchReferralsStatistics = createAsyncThunk(
  'referrals/fetchReferralsStatistics',
  async (_, { rejectWithValue }) => {
    try {
      const response = await $api.get<ReferralsStatistics>(ROUTE_PATH.referrals.statistics);

      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error instanceof Error ? error.message : String(error));
    }
  },
);

export const fetchReferralsBonusesStatistics = createAsyncThunk(
  'referrals/fetchReferralsBonusesStatistics',
  async (params: Partial<ReferralsBonusesParams>, { rejectWithValue }) => {
    try {
      const response = await $api.get<ReferralBonusStatistics>(ROUTE_PATH.referrals.bonusesStatistics, { params });

      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error instanceof Error ? error.message : String(error));
    }
  },
);
