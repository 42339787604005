import { RootState } from 'src/app/store/store';

export const selectAssetsSlice = (state: RootState) => state.assets;
export const selectInstruments = (state: RootState) => state.assets.instruments;
export const selectAssets = (state: RootState) => state.assets.assets;
export const selectAssetsItems = (state: RootState) => state.assets.assets?.items;
export const selectSearchAssets = (state: RootState) => state.assets.searchAssets;
export const selectNetworks = (state: RootState) => state.assets.networks;
export const selectAssetsFutures = (state: RootState) => state.assets.assetsFutures;
export const selectWalletAttributes = (state: RootState) => state.assets.walletAttributes;
export const selectInstrumentsSymbols = (state: RootState) => state.assets.instrumentsSymbols;
export const selectInstrumentsSymbolsSearch = (state: RootState) => state.assets.instrumentsSymbolsSearch;
