import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { EStatus, UpdateInstrument } from 'src/shared/types/global-types';
import { sortAssets, sortSubAccountsByFavorite } from 'src/shared/libs/helpers/helper.lib';
import {
  fetchSubAccountsAssets, fetchSubAccounts, fetchCreateSubAccount, fetchEditSubAccount, fetchApiKey, fetchCreateApiKey, fetchEditApiKey, fetchRemoveApiKey, fetchSubAccountSettings,
  fetchSubAccountsAssetsTransfers,
  CollateralStatus,
} from './thunks';
import {
  ISubAccountsSlice, SubAccountType, SubAccountAssetUpdate, SubAccountAsset, SubAccountAssetRemove,
} from './types';

const initialState: ISubAccountsSlice = {
  subAccounts: null,
  allSubAccounts: null,
  selectedSubAccount: null,
  subAccountsAssets: null,
  subAccountsAssetsTransfers: null,
  subAccountSettings: null,
  favoriteSubAccount: null,
  apiKeys: null,
  status: EStatus.success,
  apiKeysGetStatus: EStatus.success,
  apiKeysEditStatus: EStatus.success,
  apiKeysCreateStatus: EStatus.success,
  apiKeysDeleteStatus: EStatus.success,
  editSubAccountStatus: EStatus.success,
  createSubAccountStatus: EStatus.success,
  statusSubAccountsAssets: EStatus.success,
  subAccountSettingsStatus: EStatus.success,
  statusSubAccountsTransfers: EStatus.loading,
};

export const subAccountsSlice = createSlice({
  name: 'subAccounts',
  initialState,
  reducers: {
    addSubAccountAsset: (state, action: PayloadAction<SubAccountAsset>) => {
      if (state.subAccountsAssets) {
        state.subAccountsAssets.unshift(action.payload);
      }

      if (state.subAccountsAssetsTransfers) {
        state.subAccountsAssetsTransfers.unshift(action.payload);
      }
    },
    setSubAccount: (state, action: PayloadAction<SubAccountType>) => {
      state.selectedSubAccount = action.payload;
    },
    setFavoriteSubAccount: (state, action: PayloadAction<string>) => {
      state.favoriteSubAccount = action.payload;
    },
    setCollateralStatus: (state, action: PayloadAction<CollateralStatus>) => {
      const asset = action.payload;
      const findSubAccountAssetIndex = state.subAccountsAssets?.findIndex((subAccountAsset) => subAccountAsset.asset_id === asset.asset_id && subAccountAsset.sub_account_id === asset.sub_account_id);

      if (findSubAccountAssetIndex && state.subAccountsAssets) {
        state.subAccountsAssets[findSubAccountAssetIndex] = {
          ...state.subAccountsAssets[findSubAccountAssetIndex],
          collateralizable: asset.collateralizable,
        };
      }
    },
    updateSubAccountAsset: (state, action: PayloadAction<SubAccountAssetUpdate>) => {
      const updateAsset = action.payload;

      if (state.subAccountsAssets) {
        const index = state.subAccountsAssets.findIndex((asset) => asset.id === updateAsset.id);

        //  check the object we receive via webSocket, if the object contains - locked then update the data locked and quantity,
        //  if the object does not contain locked then update only quantity.
        if (index !== -1) {
          if ('locked' in updateAsset && typeof updateAsset.locked === 'string' && updateAsset.locked !== '') {
            state.subAccountsAssets[index] = {
              ...state.subAccountsAssets[index],
              quantity: updateAsset.quantity,
              locked: updateAsset.locked,
            };
          } else {
            state.subAccountsAssets[index] = {
              ...state.subAccountsAssets[index],
              quantity: updateAsset.quantity,
            };
          }
        }
      }

      if (state.subAccountsAssetsTransfers) {
        const index = state.subAccountsAssetsTransfers.findIndex((asset) => asset.id === updateAsset.id);

        //  check the object we receive via webSocket, if the object contains - locked then update the data locked and quantity,
        //  if the object does not contain locked then update only quantity.
        if (index !== -1) {
          if ('locked' in updateAsset && typeof updateAsset.locked === 'string' && updateAsset.locked !== '') {
            state.subAccountsAssetsTransfers[index] = {
              ...state.subAccountsAssetsTransfers[index],
              quantity: updateAsset.quantity,
              locked: updateAsset.locked,
            };
          } else {
            state.subAccountsAssetsTransfers[index] = {
              ...state.subAccountsAssetsTransfers[index],
              quantity: updateAsset.quantity,
            };
          }
        }
      }
    },
    updateSubAccountAssetInstrument: (state, action: PayloadAction<UpdateInstrument>) => {
      const updatedInstrument = action.payload;

      if (state.subAccountsAssets) {
        const index = state.subAccountsAssets.findIndex((subAccountsAssets) => subAccountsAssets?.instrument?.id === Number(updatedInstrument.id));

        if (index !== -1) {
          state.subAccountsAssets[index] = {
            ...state.subAccountsAssets[index],
            instrument: {
              ...state.subAccountsAssets[index].instrument,
              price: updatedInstrument.price,
            },
          };
        }
      }

      if (state.subAccountsAssetsTransfers) {
        const index = state.subAccountsAssetsTransfers.findIndex((subAccountsAssets) => subAccountsAssets?.instrument?.id === Number(updatedInstrument.id));

        if (index !== -1) {
          state.subAccountsAssetsTransfers[index] = {
            ...state.subAccountsAssetsTransfers[index],
            instrument: {
              ...state.subAccountsAssetsTransfers[index].instrument,
              price: updatedInstrument.price,
            },
          };
        }
      }
    },
    removeSubAccountAsset: (state, action: PayloadAction<SubAccountAssetRemove>) => {
      if (state.subAccountsAssets) {
        const idToRemove = action.payload.id;

        state.subAccountsAssets = state.subAccountsAssets.filter((asset) => asset.id !== idToRemove);
      }

      if (state.subAccountsAssetsTransfers) {
        const idToRemove = action.payload.id;

        state.subAccountsAssetsTransfers = state.subAccountsAssetsTransfers.filter((asset) => asset.id !== idToRemove);
      }
    },
    removeApiKey: (state, action: PayloadAction<number>) => {
      if (state.apiKeys) {
        state.apiKeys = state.apiKeys.filter((apiKey) => apiKey.id !== action.payload);
      }
    },
    removeSubAccountsAssets: (state) => {
      state.subAccountsAssets = null;
    },
    removeSubAccountsAssetsTransfers: (state) => {
      state.subAccountsAssetsTransfers = null;
    },
    clearApiKeys: (state) => {
      state.apiKeys = null;
    },
    clearSubAccountSettings: (state) => {
      state.subAccountSettings = null;
    },
    clearSubAccountsSlice: (state) => {
      state.subAccounts = null;
      state.allSubAccounts = null;
      state.selectedSubAccount = null;
      state.subAccountsAssets = null;
      state.subAccountsAssetsTransfers = null;
      state.subAccountSettings = null;
      state.favoriteSubAccount = null;
      state.apiKeys = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSubAccounts.pending, (state) => {
        state.status = EStatus.loading;
        state.subAccounts = null;
      })
      .addCase(fetchSubAccounts.fulfilled, (state, action) => {
        state.status = EStatus.success;
        state.subAccounts = action.payload.filter((subAccount) => subAccount.is_active_exchange).sort(sortSubAccountsByFavorite);
        state.allSubAccounts = action.payload;

        const subAccount = action.payload.find((account) => account.is_favorite);
        const favoriteUserName = subAccount ? subAccount.user_name : null;

        if (favoriteUserName) {
          state.favoriteSubAccount = favoriteUserName;
        }
      })
      .addCase(fetchSubAccounts.rejected, (state) => {
        state.status = EStatus.rejected;
        state.subAccounts = null;
      })

      .addCase(fetchSubAccountsAssets.pending, (state) => {
        state.subAccountsAssets = null;
        state.statusSubAccountsAssets = EStatus.loading;
      })
      .addCase(fetchSubAccountsAssets.fulfilled, (state, action) => {
        state.statusSubAccountsAssets = EStatus.success;

        if (action.payload.length === 0) {
          state.subAccountsAssets = [];
          return;
        }

        const findAsset = action.payload.find((asset) => asset.instrument !== null);

        const priceType = typeof findAsset?.instrument.price;

        if (priceType === 'string') {
          const formattedAssetsInVersionStringPrice = action.payload.map((item: any) => {
            if (item.instrument !== null) {
              return {
                ...item,
                instrument: {
                  ...item.instrument,
                  price: {
                    id: item.instrument.id,
                    ask: String(item.instrument.price),
                    bid: String(item.instrument.price),
                  },
                },
              };
            }
            return item; // If instrument === null, just return the element unchanged
          });

          state.subAccountsAssets = formattedAssetsInVersionStringPrice.slice().sort(sortAssets);
        }

        if (priceType === 'object' || priceType === 'undefined') {
          state.subAccountsAssets = action.payload.slice().sort(sortAssets);
        }
      })
      .addCase(fetchSubAccountsAssets.rejected, (state) => {
        state.subAccountsAssets = null;
        state.statusSubAccountsAssets = EStatus.rejected;
      })

      .addCase(fetchSubAccountsAssetsTransfers.pending, (state) => {
        state.subAccountsAssetsTransfers = null;
        state.statusSubAccountsTransfers = EStatus.loading;
      })
      .addCase(fetchSubAccountsAssetsTransfers.fulfilled, (state, action) => {
        state.statusSubAccountsTransfers = EStatus.success;

        if (action.payload.length === 0) {
          state.subAccountsAssetsTransfers = [];
          return;
        }

        const findAsset = action.payload.find((asset) => asset.instrument !== null);

        const priceType = typeof findAsset?.instrument.price;

        if (priceType === 'string') {
          const formattedAssetsInVersionStringPrice = action.payload.map((item: any) => {
            if (item.instrument !== null) {
              return {
                ...item,
                instrument: {
                  ...item.instrument,
                  price: {
                    id: item.instrument.id,
                    ask: String(item.instrument.price),
                    bid: String(item.instrument.price),
                  },
                },
              };
            }
            return item; // If instrument === null, just return the element unchanged
          });

          state.subAccountsAssetsTransfers = formattedAssetsInVersionStringPrice.slice().sort(sortAssets);
        }

        if (priceType === 'object' || priceType === 'undefined') {
          state.subAccountsAssetsTransfers = action.payload.slice().sort(sortAssets);
        }
      })
      .addCase(fetchSubAccountsAssetsTransfers.rejected, (state) => {
        state.subAccountsAssetsTransfers = null;
        state.statusSubAccountsTransfers = EStatus.success;
      })

      .addCase(fetchCreateSubAccount.pending, (state) => {
        state.createSubAccountStatus = EStatus.loading;
      })
      .addCase(fetchCreateSubAccount.fulfilled, (state, action) => {
        state.createSubAccountStatus = EStatus.success;

        if (state.subAccounts) {
          state.subAccounts.push(action.payload);
        } else {
          state.subAccounts = [action.payload];
        }

        if (state.allSubAccounts) {
          state.allSubAccounts.push(action.payload);
        } else {
          state.allSubAccounts = [action.payload];
        }
      })
      .addCase(fetchCreateSubAccount.rejected, (state) => {
        state.createSubAccountStatus = EStatus.rejected;
      })

      .addCase(fetchEditSubAccount.pending, (state) => {
        state.editSubAccountStatus = EStatus.loading;
      })
      .addCase(fetchEditSubAccount.fulfilled, (state, action) => {
        state.editSubAccountStatus = EStatus.success;

        if (state.subAccounts) {
          const editedSubAccountIndex = state.subAccounts.findIndex(
            (subAccount) => subAccount.id === action.payload.id,
          );

          if (editedSubAccountIndex !== -1) {
            const updatedSubAccounts = state.subAccounts.map((subAccount, index) => {
              if (index === editedSubAccountIndex) {
                // Set is_favorite to true for the edited subAccount
                return {
                  ...subAccount,
                  ...action.payload,
                  is_favorite: action.payload.is_favorite,
                };
              }
              // Set is_favorite to false for other subAccounts
              return {
                ...subAccount,
                is_favorite: false,
              };
            }).sort(sortSubAccountsByFavorite);

            state.subAccounts = updatedSubAccounts;
          }
        }
      })
      .addCase(fetchEditSubAccount.rejected, (state) => {
        state.editSubAccountStatus = EStatus.rejected;
      })

      .addCase(fetchApiKey.pending, (state) => {
        state.apiKeysGetStatus = EStatus.loading;
      })
      .addCase(fetchApiKey.fulfilled, (state, action) => {
        state.apiKeysGetStatus = EStatus.success;
        state.apiKeys = action.payload;
      })
      .addCase(fetchApiKey.rejected, (state) => {
        state.apiKeysGetStatus = EStatus.rejected;
      })

      .addCase(fetchCreateApiKey.pending, (state) => {
        state.apiKeysCreateStatus = EStatus.loading;
      })
      .addCase(fetchCreateApiKey.fulfilled, (state, action) => {
        state.apiKeysCreateStatus = EStatus.success;

        if (Array.isArray(state.apiKeys)) {
          state.apiKeys.push(action.payload);
        } else {
          state.apiKeys = [action.payload];
        }
      })
      .addCase(fetchCreateApiKey.rejected, (state) => {
        state.apiKeysCreateStatus = EStatus.rejected;
      })

      .addCase(fetchEditApiKey.pending, (state) => {
        state.apiKeysEditStatus = EStatus.loading;
      })
      .addCase(fetchEditApiKey.fulfilled, (state, action) => {
        state.apiKeysEditStatus = EStatus.success;
        const secretId = action.payload.id;

        if (state.apiKeys) {
          const findIndexSecret = state.apiKeys.findIndex((apiKey) => apiKey.id === secretId);

          if (findIndexSecret !== -1) {
            state.apiKeys[findIndexSecret] = action.payload;
          }
        }
      })
      .addCase(fetchEditApiKey.rejected, (state) => {
        state.apiKeysEditStatus = EStatus.rejected;
      })

      .addCase(fetchRemoveApiKey.pending, (state) => {
        state.apiKeysDeleteStatus = EStatus.loading;
      })
      .addCase(fetchRemoveApiKey.fulfilled, (state) => {
        state.apiKeysDeleteStatus = EStatus.success;
      })
      .addCase(fetchRemoveApiKey.rejected, (state) => {
        state.apiKeysDeleteStatus = EStatus.rejected;
      })

      .addCase(fetchSubAccountSettings.pending, (state) => {
        state.subAccountSettingsStatus = EStatus.loading;
      })
      .addCase(fetchSubAccountSettings.fulfilled, (state, action) => {
        state.subAccountSettingsStatus = EStatus.success;
        state.subAccountSettings = action.payload;
      })
      .addCase(fetchSubAccountSettings.rejected, (state) => {
        state.subAccountSettingsStatus = EStatus.rejected;
      });
  },
});

export const {
  addSubAccountAsset,
  setSubAccount,
  setFavoriteSubAccount,
  setCollateralStatus,
  updateSubAccountAsset,
  updateSubAccountAssetInstrument,
  removeSubAccountsAssetsTransfers,
  removeSubAccountAsset,
  removeApiKey,
  removeSubAccountsAssets,
  clearApiKeys,
  clearSubAccountSettings,
  clearSubAccountsSlice,
} = subAccountsSlice.actions;

export const subAccountsReducer = subAccountsSlice.reducer;
