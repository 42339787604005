import { useTranslation } from 'react-i18next';
import { EStatus, Nullable } from 'src/shared/types/global-types';
import useExchange from 'src/shared/libs/hooks/use-exchange';
import { useTheme } from 'src/shared/libs/hooks/use-theme';
import { Divider, LoadingOverlay } from '@mantine/core';
import { EThemeMode } from 'src/app/store/slices/user/types';
import moment from 'moment';
import BalanceType from 'src/entities/balance-type/balance-type';
import Empty from 'src/entities/empty/empty';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Typewriter } from 'react-simple-typewriter';
import { selectHiddenBalance } from 'src/pages/settings/model/selectors';
import { HIDDEN_BALANCES } from 'src/shared/constants/constants';
import { ReferralBonus } from '../../model/types';
import { selectReferralStatus } from '../../model/selectors';
import styles from './referrals-table-card.module.scss';

interface IReferralsTableCardProps {
    visible: boolean;
    data: Nullable<ReferralBonus[]>

}
const ReferralsTableCard = ({ visible, data }: IReferralsTableCardProps) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const { getExchangeImageById } = useExchange();
  const status = useSelector(selectReferralStatus);
  const balanceVisible = useSelector(selectHiddenBalance);

  const isSuccess = useMemo(() => Boolean(status === EStatus.success), [status]);
  const isLoading = useMemo(() => Boolean(status === EStatus.loading), [status]);
  const isRejected = useMemo(() => Boolean(status === EStatus.rejected), [status]);

  if (!visible) return null;

  return (
    <div className={styles.cardWrapper}>
      <h2 className={styles.title}>{t('summary')}</h2>

      <Divider className={styles.divider} color={theme === EThemeMode.LIGHT ? '#E8E8E8' : '#263B30'} />

      <ul className={styles.container}>
        {data?.map((referralBonus) => (
          <li key={referralBonus.id} className={styles.card}>
            <div className={styles.header}>
              <p className={styles.bonuses}>
                {!balanceVisible ? `${referralBonus.bonus} $` : HIDDEN_BALANCES}
                <img src={getExchangeImageById(referralBonus.exchange_id)} alt="exchange" />
              </p>
              <time dateTime={moment(referralBonus.created_at).toString()}>{moment(referralBonus.created_at).format('DD.MM.YYYY')}</time>
            </div>

            <div className={styles.column}>
              <span>{t('trading_date')}</span>
              <time dateTime={moment(referralBonus.paid_at).toString()}>{moment(referralBonus.paid_at).format('DD.MM.YYYY')}</time>
            </div>

            <div className={styles.column}>
              <span>UserID</span>
              <p>{referralBonus.user_id}</p>
            </div>

            <div className={styles.column}>
              <span>{t('cumulative_quantity')}</span>
              <p>{!balanceVisible ? `${referralBonus.cummulative_quantity} $` : HIDDEN_BALANCES}</p>
            </div>

            <div className={styles.column}>
              <span>{t('commission')}</span>
              <p>{!balanceVisible ? `${referralBonus.commission} $` : HIDDEN_BALANCES}</p>
            </div>

            <div className={styles.column}>
              <span>{t('type')}</span>
              <div className={styles.typeWrapper}>
                <BalanceType type={referralBonus.trading_type} />
                <p>{t(referralBonus.trading_type)}</p>
              </div>

            </div>
          </li>
        ))}
      </ul>

      {isSuccess && data?.length === 0 && (
        <div className={styles.emptyTable}>
          <Empty maxWidthText="200px">
            {t('no-history-for-selected-period')}
          </Empty>
        </div>
      )}
      {isLoading && (
        <div className={styles.emptyTable}>
          <LoadingOverlay
            className="loader"
            style={{ height: '100%' }}
            visible
            zIndex={1000}
            overlayProps={{ radius: 'sm', blur: 2 }}
            loaderProps={{ color: '#00C37C', type: 'dots' }}
          />
        </div>
      )}
      {isRejected && (
        <div className={styles.emptyTable}>
          <Empty horizontal error>
            <Typewriter
              words={[t('internal_error_message')]}
              loop={1}
              // cursor
              cursorStyle="_"
              typeSpeed={20}
              delaySpeed={1500}
              deleteSpeed={20}
            />
          </Empty>
        </div>
      )}
    </div>
  );
};

export default ReferralsTableCard;
