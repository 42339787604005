import qs from 'qs';
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { setCookie } from '../helpers/helper.lib';

const useHandlePartnerId = (isAuth: boolean) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    try {
      const parsedParams = qs.parse(location.search, { ignoreQueryPrefix: true });
      const queryPartnerId = parsedParams.partner;

      const isPublic = location.pathname === '/trades';

      if (queryPartnerId && typeof queryPartnerId === 'string') {
        setCookie('partner', queryPartnerId);
        if (!isAuth && !isPublic) {
          navigate('/auth/registration');
        }
      }
    } catch (error) {
      console.debug('Error in useHandlePartnerId', error);
    }
  }, [isAuth, location, navigate]);
};

export default useHandlePartnerId;
