import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingOverlay } from '@mantine/core';
import { SendTypeCode } from 'src/shared/types/global-types';
import { checkFullPhone } from 'src/shared/libs/helpers/helper.lib';
import { COUNTER_SEND_SMS, TIMER_SEND_SMS } from 'src/shared/constants/constants';
import { ReactComponent as ArrowSvg } from 'src/shared/assets/images/arrow.svg';
import useAlert from 'src/shared/libs/hooks/use-alert';
import ButtonLink from 'src/shared/ui/button/button-link/button-link';
import { selectRegistration } from '../../registration/model/selectors';

import styles from './input-auth.module.scss';

interface IInputActionSelectProps {
    verifyPhone?: (phone_number: string) => Promise<boolean>
    changeInput?: (toogle: boolean) => void
    startTimer?: (timer: boolean) => void
    timer?: boolean
    actionTitle?: string
    sendSmsType: SendTypeCode[]
    setSelectSmsType: (type: string) => void
}

const InputActionSelect = ({
  verifyPhone, actionTitle, timer, startTimer, changeInput, sendSmsType, setSelectSmsType,
}: IInputActionSelectProps) => {
  const { t } = useTranslation();
  const { setAlertMessage } = useAlert();
  const { regionCode } = useSelector(selectRegistration);

  const [sendLoading, setSendLoading] = useState(false);

  const [showList, setShowList] = useState(false);
  const [quanityRequestsSms, setQuanityRequestsSms] = useState(1);
  const [isActiveSendButton, setIsActiveSendButton] = useState(true);
  const [remainingTime, setRemainingTime] = useState(TIMER_SEND_SMS); // 2 minutes in seconds
  const [option, setOption] = useState(sendSmsType[0].name);
  const minutes = String(Math.floor(remainingTime / 60)).padStart(2, '0');
  const seconds = String(remainingTime % 60).padStart(2, '0');

  const localStorageValue = localStorage.getItem('tell');
  const fullPhone = regionCode && localStorageValue ? regionCode + localStorageValue : '';

  const sendCodeSms = async () => {
    if (!verifyPhone || !startTimer || !changeInput) return;

    setSendLoading(true);
    if (quanityRequestsSms < COUNTER_SEND_SMS) {
      const result = await verifyPhone(fullPhone);
      setSendLoading(false);

      if (result) {
        setRemainingTime(TIMER_SEND_SMS);
        startTimer(true);
        changeInput(true);
        // setQuanityRequestsSms((prev) => prev + 1);
        // setAlertMessage(`Код отправлен. Осталось попыток - ${COUNTER_SEND_SMS - quanityRequestsSms}`, 'success');
        // setAlertMessage('Код отправлен', 'success');
      }
    }
    if (quanityRequestsSms === COUNTER_SEND_SMS) {
      const result = await verifyPhone(fullPhone);
      setSendLoading(false);
      if (result) {
        setRemainingTime(TIMER_SEND_SMS);
        startTimer(true);
        changeInput(true);
        // setAlertMessage('Код отправлен', 'success');
      }

      setIsActiveSendButton(false);
    }
  };

  const handleSelectOption = (type: SendTypeCode) => {
    setOption(type.name);
    setSelectSmsType(type.type);
    setShowList(false);
  };

  const handleOnClick = () => {
    const check = !checkFullPhone(fullPhone);

    if (check) {
      setAlertMessage('fill_phone_field', 'error');
    } else {
      sendCodeSms();
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!startTimer || !changeInput) return;

      if (remainingTime > 0) {
        setRemainingTime((prevTime) => prevTime - 1);
      } else {
        startTimer(false);
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [remainingTime]);

  return (
    <div className={styles.select}>
      <div className={styles.label}>{t('confirmation_method')}</div>
      <div className={classNames(styles.selectContainer, { [styles.active]: showList })}>
        <button type="button" onClick={() => setShowList((prev) => !prev)} className={styles.selectForm}>
          <ArrowSvg />
        </button>
        <div className={styles.selectGroup}>
          <span
            className={styles.selectTitle}
            tabIndex={0}
            role="button"
            onKeyDown={() => setShowList((prev) => !prev)}
            onClick={() => setShowList((prev) => !prev)}
          >{option}
          </span>

          {sendLoading && (
            <LoadingOverlay
              className="loader loader-mini"
              visible
              style={{ height: '100%' }}
              zIndex={1000}
              overlayProps={{ radius: 'sm', blur: 2 }}
              loaderProps={{ color: '#00C37C', type: 'oval' }}
            />
          )}

          {timer ? <time>{`${minutes}:${seconds}`}</time> : (
            !sendLoading && (
              <ButtonLink onClick={handleOnClick} disabled={!isActiveSendButton}>
                {actionTitle}
              </ButtonLink>
            )
          )}

        </div>
      </div>

      {showList && (
        <div className={styles.list}>
          <ul>
            { sendSmsType.length > 0 && sendSmsType.map((item) => (
              <li key={item.type}>
                <button
                  type="button"
                  onClick={() => handleSelectOption(item)}
                >
                  {item.name}
                </button>
              </li>
            )) }
          </ul>
        </div>
      )}
    </div>
  );
};

export default InputActionSelect;
