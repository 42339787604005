import { Loader } from '@mantine/core';
import { join } from 'src/shared/libs/helpers/helper.lib';
import { useTranslation } from 'react-i18next';
import { memo, useEffect } from 'react';
import { ReactComponent as DownloadIconSvg } from 'src/shared/assets/images/download.svg';
import './button.scss';

interface IButtonProps {
  children: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean
  type?: 'button' | 'submit' | 'reset';
  loading?: boolean
  maxWidth?: string;
  minWidth?: string;
  background: 'green' | 'gray' | 'orange' | 'black' | 'white' | 'whiteGray' | 'green-linear' | 'red';
  downloading?: boolean
  progress?: number
  className?: string
}

const Button = memo((props: IButtonProps) => {
  const {
    children,
    onClick,
    disabled = false,
    type = 'button',
    loading,
    maxWidth,
    background,
    minWidth,
    downloading,
    progress,
    className,
  } = props;
  const { t } = useTranslation();
  const HiddenProgressBar = progress === 100 ? 'none' : 'flex';

  useEffect(() => {
    const btn = document.querySelector('.progress-bar') as HTMLElement;

    if (btn) {
      btn.style.width = `${progress}%`;
    }
  }, [progress]);

  const maxWidthStyle = maxWidth ? { maxWidth } : {};
  const minWidthStyle = minWidth ? { minWidth } : {};
  return (
    <div style={{ ...maxWidthStyle, ...minWidthStyle }} className={join('btn', className)}>
      <button
        tabIndex={0}
        disabled={disabled}
        type={type}
        onClick={(event) => {
          if (onClick) {
            onClick(event);
          }
        }}
        className={join(background, downloading ? 'download' : '')}
      >
        {loading ? <Loader size={18} color="white" /> : downloading ? <div className="btn-download"><DownloadIconSvg />{t('loading')}...</div> : children}
        {downloading && <div style={{ display: HiddenProgressBar }} className="progress-bar" />}
      </button>
    </div>
  );
});

export default Button;
