import Button from 'src/shared/ui/button/button/button';
import Hint from 'src/shared/ui/help/hint';
import { useTranslation } from 'react-i18next';
import styles from './kyc-policy-modal-content.module.scss';

interface IKycPolicyModalContentProps {
    verify: () => void
    onClose: () => void
    btnStatus: boolean
}

const KycPolicyModalContent = ({ verify, onClose, btnStatus }: IKycPolicyModalContentProps) => {
  const { t } = useTranslation();
  return (
    <div className={styles.policyWrapper}>

      <Hint mediumText text={t('services not provided to residents of certain territories')} />

      <div className={styles.actions}>
        <Button loading={btnStatus} disabled={btnStatus} onClick={verify} background="green">{t('confirm')}</Button>
        <Button disabled={btnStatus} onClick={onClose} background="black">{t('decline')}</Button>
      </div>
    </div>
  );
};

export default KycPolicyModalContent;
