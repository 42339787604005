import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { VERIFICATION_FAQ, VERIFICATION_QUESTIONS_FAQ } from 'src/shared/constants/app-links';

import DropdownMenu from './dropdown-menu/dropdown-menu';

import './verification-slide.scss';

const VerificationSlide = () => {
  const { t } = useTranslation();
  return (
    <div className="slide verification-slide">
      <p className="description">{t('personal data verification')}
      </p>

      <DropdownMenu />

      <div className="faq-questions">
        <Link target="_blank" to={VERIFICATION_FAQ}>{t('why verification is needed')}</Link>
        <Link target="_blank" to={VERIFICATION_QUESTIONS_FAQ}>{t('faq verification')}
        </Link>
      </div>
    </div>
  );
};

export default VerificationSlide;
