import { useSelector } from 'react-redux';
import { useDisclosure } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';

import Button from 'src/shared/ui/button/button/button';
import useAlert from 'src/shared/libs/hooks/use-alert';
import useExchange from 'src/shared/libs/hooks/use-exchange';
import TransferHistoryAdaptive from 'src/widgets/transfer-history/adaptive/transfer-history-adaptive';

import { Exchange } from 'src/app/store/slices/exchanges/types';
import { useAppDispatch } from 'src/app/store/store';
import { selectBalances } from 'src/app/store/slices/balance/selectors';
import { SubAccountType } from 'src/app/store/slices/sub-accounts/types';
import { selectExchanges } from 'src/app/store/slices/exchanges/selectors';
import { setFavoriteSubAccount } from 'src/app/store/slices/sub-accounts/slice';
import { EditSubAccount, fetchEditSubAccount } from 'src/app/store/slices/sub-accounts/thunks';
import { selectEditSubAccountStatus, selectSubAccounts } from 'src/app/store/slices/sub-accounts/selectors';

import { HIDDEN_BALANCES } from 'src/shared/constants/constants';
import { ModalEditSubAccount } from 'src/shared/ui/modal/modal-edit-subaccount';
import { ETradingType, Nullable } from 'src/shared/types/global-types';
import { plusValues, saveToLocalStorage, scrollToTop } from 'src/shared/libs/helpers/helper.lib';
import { ReactComponent as ArrowLeftCircleIconSvg } from 'src/shared/assets/images/arrow-left-circle.svg';
import { ReactComponent as ReplenishmentIconSvg } from 'src/shared/assets/images/account/replenishment.svg';
import { ReactComponent as WithdrawalIconSvg } from 'src/shared/assets/images/account/withdrawal.svg';
import { ReactComponent as TransfersIconSvg } from 'src/shared/assets/images/account/transfers.svg';

import { Balance } from 'src/widgets/balance';
import { DEFAULT_ERROR_STATE } from 'src/pages/accounts/constants';
import { selectHiddenBalance } from 'src/pages/settings/model/selectors';
import { BalanceType as BalanceTypeWidget } from 'src/entities/balance-type';
import { Balance as BalanceType } from 'src/app/store/slices/balance/types';
import { ActiveLink } from '../model/types';
import AssetsTableAdaptive from '../components/account-container/components/assets-table/assets-table-adaptive/assets-table-adaptive';
import AssetsTableBybitAdaptive from '../components/account-container/components/assets-table-bybit/assets-table-bybit-adaptive/assets-table-bybit-adaptive';
import styles from './account-adaptive.module.scss';

const AccountPageAdaptive = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { getExchangeImageById, getExchangeNameById } = useExchange();
  const { setAlertMessage } = useAlert();

  const [opened, { open, close }] = useDisclosure(false);
  const [page, accountId, selectTab] = pathname.substring(1).split('/');

  const { balances } = useSelector(selectBalances);
  const { exchanges } = useSelector(selectExchanges);
  const editStatus = useSelector(selectEditSubAccountStatus);
  const subAccounts = useSelector(selectSubAccounts);
  const balanceVisible = useSelector(selectHiddenBalance);

  const [isBybit, setIsBybit] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const [subAccountName, setSubAccountName] = useState('');
  const [exchange, setExchange] = useState<Nullable<Exchange>>(null);
  const [activeLink, setActiveLink] = useState<ActiveLink>('sub_account');
  const [subAccount, setSubAccount] = useState<Nullable<SubAccountType>>(null);
  const [errorMessage, setNameErrorMessage] = useState(DEFAULT_ERROR_STATE);

  const balance = useMemo(() => balances?.sub_accounts.find((item) => item.sub_account_id === Number(accountId)), [balances]);

  const navigateReplenishment = `/transfers?replenishment_sub_account_id=${subAccount?.id}&tab_index=0`;
  const navigateWithdrawal = `/transfers?withdrawal_sub_account_id=${subAccount?.id}&tab_index=1`;
  const navigateTransfer = `/transfers?transfer_sub_account_id=${subAccount?.id}&exchange_id=${subAccount?.exchange_id}&tab_index=2`;

  const handleCloseModal = useCallback(() => {
    setNameErrorMessage(DEFAULT_ERROR_STATE);
    close();
  }, [DEFAULT_ERROR_STATE]);

  const handleOpenModal = useCallback(() => {
    if (exchanges && subAccount) {
      setSubAccountName(subAccount.user_name);
    }
    open();
  }, [exchanges, subAccount]);

  const checkSubAccountNameExist = useCallback((name: string) => {
    if (exchange && subAccounts && subAccount) {
      const subAccountsName = subAccounts.filter((item) => item.exchange_id === exchange.id && item.user_name !== subAccount.user_name).map((subAccount) => subAccount.user_name);

      return subAccountsName.includes(name.trim());
    }
    return false;
  }, [exchange, subAccounts, subAccount]);

  const editSubAccount = useCallback(async () => {
    if (!subAccount || !subAccountName || !exchange) return;

    if (checkSubAccountNameExist(subAccountName)) {
      setNameErrorMessage({ state: true, message: t('name_taken') });
      return;
    }
    if (!checkSubAccountNameExist(subAccountName)) {
      setNameErrorMessage(DEFAULT_ERROR_STATE);
    }
    if (subAccountName.length > 16) {
      setNameErrorMessage({ state: true, message: t('max_length_16') });
      return;
    }

    const editSubAccount: EditSubAccount = {
      exchange_id: exchange.id,
      user_name: subAccountName,
      is_favorite: isFavorite,
    };

    if (isFavorite) {
      dispatch(setFavoriteSubAccount(editSubAccount.user_name));

      const selectedSubAccountId = subAccounts?.find((item) => item.id === subAccount.id);
      if (selectedSubAccountId) saveToLocalStorage('favoriteSubAccountId', selectedSubAccountId.id);
    }

    const response = await dispatch(fetchEditSubAccount({ editSubAccount, subAccountId: subAccount.id }));

    if (response.payload) {
      close();
    } else {
      setAlertMessage('internal error', 'error');
    }
  }, [subAccount, exchange, subAccountName, isFavorite]);

  const goToSubAccountsPage = () => {
    navigate('/sub-accounts');
    scrollToTop();
  };

  const handleNavigation = (path: string) => {
    if (path.includes('replenishment')) navigate(path);
    if (path.includes('withdrawal')) navigate(path);
    if (path.includes('transfers-block')) navigate(navigateTransfer);
  };

  useEffect(() => {
    if (subAccounts && exchanges) {
      const currentSubAccount = subAccounts?.find((item) => item.id === Number(accountId));

      if (currentSubAccount) {
        setSubAccount(currentSubAccount);
        setIsFavorite(currentSubAccount.is_favorite);
        const exchange = exchanges.find((item) => item.id === currentSubAccount.exchange_id);

        if (exchange) setExchange(exchange);
      }
    }
  }, [subAccounts, exchanges, accountId]);

  useEffect(() => {
    if (selectTab) {
      const queryLink = selectTab as ActiveLink;
      setActiveLink(queryLink);
    } else {
      setActiveLink('sub_account');
    }
  }, [pathname, selectTab]);

  useEffect(() => {
    const exchangeBybit = getExchangeNameById(exchange?.id) === 'Bybit';
    setIsBybit(exchangeBybit);
  }, [exchange]);

  return (
    <>
      <div className={styles.subAccountPageAdaptive}>
        <div className={styles.body}>
          <Balance subAccountId={subAccount?.id} />
        </div>

        <button onClick={goToSubAccountsPage} className={styles.navigation}>
          <ArrowLeftCircleIconSvg className={styles.navBtn} />
          <p>{t('back_to_accounts_list')}</p>
        </button>

        <div className={styles.content}>
          <div className={styles.header}>
            <div className={styles.subAccount}>
              {subAccount && <img src={getExchangeImageById(subAccount.exchange_id)} alt="exchange" />}
              {isBybit && <BalanceTypeWidget type={ETradingType.unified} />}
              <h2>{subAccount?.user_name}</h2>
            </div>
            <Button disabled={false} onClick={handleOpenModal} maxWidth="190px" background="black">{t('edit_subaccount')}</Button>
          </div>

          <div className={styles.balancesInfo}>
            <div className={styles.balanceWrapper}>
              <div className={styles.balanceAllTitle}>{t('total_balance')}: {isBybit && <BalanceTypeWidget type={ETradingType.unified} />}</div>
              <span className={styles.totalBalanceSum}>{
                balance ? !balanceVisible
                  ? plusValues(String(balance.spot), String(balance.futures))
                  : HIDDEN_BALANCES : '0.0'
              }<span className={styles.symbol}>USDT</span>
              </span>
            </div>

            <div className={styles.block}>
              <div className={styles.balanceWrapper}>
                <div className={styles.balanceTitle}>
                  {isBybit ? `${t('margin_assets')}:` : `${t('futures')}:` }
                  <BalanceTypeWidget type={ETradingType.futures} />
                </div>
                <span>{balance ? !balanceVisible
                  ? balance.futures : HIDDEN_BALANCES
                  : '0.0'} USDT
                </span>
              </div>

              <div className={styles.balanceWrapper}>
                <div className={styles.balanceTitle}>
                  {isBybit ? `${t('free_assets')}:` : `${t('spot')}:` }
                  <BalanceTypeWidget type={ETradingType.spot} />
                </div>
                <span>{balance ? !balanceVisible
                  ? balance.spot : HIDDEN_BALANCES
                  : '0.0'} USDT
                </span>
              </div>
            </div>

            <div className={styles.actions}>
              <div className={styles.actionsBlock}>
                <Button onClick={() => handleNavigation(navigateReplenishment)} background="black"><ReplenishmentIconSvg className={styles.replenishmentIcon} />{t('deposit')}</Button>
                <Button onClick={() => handleNavigation('transfers-block')} background="black"><TransfersIconSvg className={styles.transfersIcon} />{t('transfer')}</Button>
              </div>
              <Button onClick={() => handleNavigation(navigateWithdrawal)} background="red"><WithdrawalIconSvg />{t('withdrawal')}</Button>
            </div>
          </div>

          {exchange?.name === 'Bybit' && <AssetsTableBybitAdaptive subAccount={subAccount} /> }
          {exchange?.name !== 'Bybit' && <AssetsTableAdaptive subAccount={subAccount} /> }

          <TransferHistoryAdaptive />
        </div>
      </div>

      <ModalEditSubAccount
        opened={opened}
        closeModal={handleCloseModal}
        exchange={exchange}
        editStatus={editStatus}
        editSubAccount={editSubAccount}
        inputValue={subAccountName}
        inputOnChange={setSubAccountName}
        errorMessage={errorMessage}
        isFavorite={isFavorite}
        setIsFavorite={setIsFavorite}
      />
    </>
  );
};

export default AccountPageAdaptive;
