import {
  useEffect, useState, useRef, useLayoutEffect,
} from 'react';
import { LoadingOverlay } from '@mantine/core';
import className from 'classnames';
import ButtonLink from 'src/shared/ui/button/button-link/button-link';
import InputText, { IInputTextProps } from 'src/shared/ui/input/input-text/input-text';
import { TIMER_SEND_SMS } from 'src/shared/constants/constants';
import { ReactComponent as KeyIconSvg } from 'src/shared/assets/images/lock.svg';
import {
  getFromLocalStorage, removeItemFromLocalStorage, saveToLocalStorage, timeElapsedInSeconds,
} from 'src/shared/libs/helpers/helper.lib';

import './input-text-with-icon.scss';
import { useTranslation } from 'react-i18next';

interface IInputTextWithIconProps extends IInputTextProps {
  IconContent: React.FunctionComponent<React.SVGProps<SVGSVGElement>> | string;
  buttonText: string;
  value: string;
  onChange: (value: string) => void;
  onEdit?: () => void;
  onSave?: () => void;
  isDisabled?: boolean;
  disabled?: boolean
  label?: string
  loading?: boolean
  positionLoading?: 'center' | 'right' | 'left'
  focus?: boolean
  lockedIcon?: boolean
  inputType?: string
  startTimer?: boolean
  stopTimer?: (status: boolean) => void
  timerId?: string
}

const InputTextWithIcon = ({
  IconContent,
  buttonText,
  placeholder,
  value,
  onChange,
  onEdit,
  onSave,
  isDisabled = true,
  label,
  loading = false,
  disabled,
  positionLoading,
  focus,
  lockedIcon,
  inputType,
  startTimer,
  stopTimer,
  timerId,
}: IInputTextWithIconProps) => {
  const timer = useRef<number>(TIMER_SEND_SMS);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const { t } = useTranslation();

  const [remainingTime, setRemainingTime] = useState(TIMER_SEND_SMS);
  const [timerVisible, setTimerVisible] = useState(false);

  const minutes = String(Math.floor(remainingTime / 60)).padStart(2, '0');
  const seconds = String(remainingTime % 60).padStart(2, '0');

  const isLocalStorageDataExists = (fieldName: string): boolean => {
    const data = localStorage.getItem(fieldName);
    return data !== null;
  };

  useEffect(() => {
    // set the timer time and display it
    if (startTimer) {
      timer.current = TIMER_SEND_SMS;
      setRemainingTime(TIMER_SEND_SMS);
      setTimerVisible(true);
    }
  }, [startTimer]);

  useEffect(() => {
    if (timerId) {
      // Get the remaining time from localStorage
      const remainingTimeFromLocalStorage = getFromLocalStorage(timerId, null);

      // Check if timerId is set and either startTimer is true or there is remaining time in localStorage
      if (timerId && (startTimer || remainingTimeFromLocalStorage)) {
        // If there is no remaining time in localStorage, save the current timestamp
        if (!remainingTimeFromLocalStorage) {
          saveToLocalStorage(timerId, Date());
        }

        // Make the timer visible
        setTimerVisible(true);
        // Set up an interval to update the timer every second
        intervalRef.current = setInterval(() => {
          // If there is remaining time in the timer
          if (timer.current && timer.current > 0) {
            // Decrement the timer and update the remaining time
            timer.current -= 1;
            setRemainingTime(timer.current);
          } else {
            // If the timer has reached zero, hide the timer, remove from localStorage, and clear the interval
            if (stopTimer) {
              stopTimer(false);
            }
            setTimerVisible(false);
            removeItemFromLocalStorage(timerId);
            clearInterval(intervalRef.current as NodeJS.Timeout);
          }
        }, 1000);
      }
    }

    // Cleanup function to clear the interval when the component is unmounted or dependencies change
    return () => {
      clearInterval(intervalRef.current as NodeJS.Timeout);
    };
  }, [startTimer, timerId]);

  useEffect(() => {
    const checkActiveTimer = isLocalStorageDataExists(timerId || '');

    if (!startTimer && !checkActiveTimer) {
      clearInterval(intervalRef.current as NodeJS.Timeout);
      setTimerVisible(false);
    }
  }, [startTimer]);

  useLayoutEffect(() => {
    if (timerId) {
      // Get the remaining time from localStorage
      const remainingTimeFromLocalStorage = getFromLocalStorage(timerId, null);

      // Check if there is remaining time in localStorage
      if (remainingTimeFromLocalStorage) {
        // Calculate elapsed time in seconds
        const seconds = timeElapsedInSeconds(remainingTimeFromLocalStorage);

        // Check if the timer has expired
        const endTimer = (seconds - TIMER_SEND_SMS) < 0;

        if (!endTimer) {
          // If the timer has expired, update state and clear localStorage
          timer.current = 0;
          setRemainingTime(0);
          setTimerVisible(false);
          removeItemFromLocalStorage(timerId);
        } else {
          // If the timer has not expired, update state considering the remaining time
          setRemainingTime(TIMER_SEND_SMS - seconds);
          timer.current = TIMER_SEND_SMS - seconds;
        }
      }
    }
  }, [timerId]);

  return (
    <div className="input-text-wrapper">
      { label && <span className="input-text-label">{label}</span>}

      <div className={`input-text-with-icon ${!isDisabled ? 'active-svg' : ''}`}>

        <div className="input-icon"> {
          typeof IconContent === 'string'
            ? <p>{IconContent}</p> : <IconContent />
        }
        </div>

        <InputText
          value={value}
          onChange={onChange}
          isDisabled={isDisabled}
          placeholder={placeholder}
          focus={focus}
          inputType={inputType}
        />
        <div className="input-text-actions-wrapper">
          { loading === false && isDisabled && !timerVisible
            ? <ButtonLink isActionDisabled={lockedIcon} disabled={disabled} onClick={onEdit}>{buttonText}</ButtonLink>
            : null}

          {loading === false && !isDisabled
            ? <ButtonLink disabled={disabled} onClick={onSave}>{t('save')}</ButtonLink>
            : null}

          { loading && (
            <div className={className(positionLoading === 'center' && 'loading-center')}>
              <LoadingOverlay
                className="loader"
                visible
                style={{ height: '100%' }}
                zIndex={1000}
                overlayProps={{ radius: 'sm', blur: 2 }}
                loaderProps={{ color: '#00C37C', type: 'oval' }}
              />
            </div>
          )}
          {isDisabled && timerVisible && <time>{`${minutes}:${seconds}`}</time>}

          {lockedIcon && <KeyIconSvg />}
        </div>
      </div>
    </div>

  );
};
export default InputTextWithIcon;
