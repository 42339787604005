import { useTranslation } from 'react-i18next';
import { handleSupportTelegram } from 'src/shared/libs/helpers/helper.lib';
import { ReactComponent as DiscordSvg } from 'src/shared/assets/images/socials/discord.svg';
import { ReactComponent as TelegramSvg } from 'src/shared/assets/images/socials/telegram.svg';
import BodyFooter from '../body-footer/body-footer';
import styles from './auth-footer.module.scss';

const AuthFooter = () => {
  const { t } = useTranslation();
  return (
    <footer className={styles.authFooter}>
      <BodyFooter />
      <p className={styles.info}>{t('get_project_updates_first')}</p>
      <div className={styles.social}>
        <ul>
          <li className={styles.telegram}><TelegramSvg onClick={handleSupportTelegram} /></li>
          <li className={styles.discord}><a href="https://discord.gg/TUP9BzTvfX" target="_blank"><DiscordSvg /></a></li>
        </ul>
      </div>
    </footer>
  );
};

export default AuthFooter;
